import React, {useState, useEffect} from "react";
import { SlideFade } from "@chakra-ui/react"
import Welcome from "../components/Welcome/Welcome"
import { Container, Row } from "../styles";
import { usePerfilPage } from "../store"
import { useUserStore } from "store/reducers/user"
import { updateOnboardingStep } from "services/userRequests";
const FirstStep = () => {

    const [fade, setFade] = useState(false);
    useEffect(() => {
        setFade(true)
    }, [])
    return (
        <SlideFade unmountOnExit={true} in={fade} offsetY='50px' >
            
        <Container>
            <Row>
                <Welcome />
            </Row>
            
        </Container>
        </SlideFade>
    )
}

export default FirstStep;