import React from "react";
import { Button, Icon } from "@chakra-ui/react";
import PropTypes from "prop-types";

const IconButton = ({ icon: IconComponent }) => {
    return (
        <Button borderRadius={"3px"} p="0.5px" bg={"transparent"} color="white">
            <Icon as={IconComponent} boxSize="18px"/>

        </Button>
    )
}

IconButton.prototype = {
    icon: PropTypes.elementType.isRequired,
}
export default IconButton;