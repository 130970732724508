import React from 'react';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import { useUserStore } from 'store/reducers/user';
import { Order } from 'models/Order';
import { useQuery } from '@tanstack/react-query';
import { getAbandonedCart } from 'controllers/Orders';
import LoadingPage from 'components/Loading/LoadingPage';
import CheckTable from './CheckTableAbandonedCart';
import AbandonedCartDetails from './AbandonedCartDetails';

export default function AbandonedCart() {
  const token = useUserStore((state) => state.auth.token);
  const [page, setPage] = React.useState<number>(1);
  const [filters, setFilters] = React.useState({})
  const [details, setDetails] = React.useState<Order>(null);

  const { data: ordersData, isLoading, refetch } = useQuery(
    ["abandonedCart", page, token, filters],
    () => getAbandonedCart(page, token, filters),
    {
      onError: (error) => {
        console.log(error);
      }
    }
  );

  const showDetails = (order: Order) => {
    setDetails(order);
  }

  return (
    <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
      {details ? (
        <>
          <Button onClick={() => setDetails(null)}>
            Voltar
          </Button>
          <AbandonedCartDetails order={details} />
        </>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <CheckTable
            tableData={ordersData}
            tableName="Carrinhos Abandonados"
            setFilters={setFilters}
            showDetails={showDetails}
            page={page}
            setPage={setPage}
          />
        </SimpleGrid>
      )}

      <LoadingPage isOpen={isLoading || ordersData === undefined} />
    </Box>
  );
}
