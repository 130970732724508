import React from 'react';
import { Alert, AlertIcon, AlertStatus } from '@chakra-ui/react';

type AlertModalProps = {
  alert: AlertModel
  setAlert: Function
}

export type AlertModel = {
  text: string
  type: AlertStatus
}

export function AlertModal({ alert, setAlert }: AlertModalProps) {
  React.useEffect(() => {
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }, [])

  return (
    <Alert status={alert.type} variant='solid'>
      <AlertIcon />
      {alert.text}
    </Alert>
  );
}
