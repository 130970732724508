import api from '../../services/api';
import { PaymentTypeResponse } from '../../models/Order';
import { notifyError } from 'utils/notification';

export async function getPaymentTypes(token: string): Promise<PaymentTypeResponse> {
  try {
    const response = await api.get<PaymentTypeResponse>(
      `${process.env.REACT_APP_API_URL}/payment-methods`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer os métodos de pagamento.");
  }
}
