import { MdPix } from 'react-icons/md';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { BsDash, BsFillCreditCardFill } from 'react-icons/bs';

export function getOrderStatus(status: string) {
  switch (status) {
    case "failed":
      return "Falha";
    case "pending":
      return "Pendente";
    case "completed":
      return "Pago";
    case "reversed":
      return "Revertido";
    case "cancelled":
      return "Cancelado";
    case "chargeback":
      return "Chargeback";
    default:
      return "-";
  }
}

export function getOrderColor(status: string) {
  switch (status) {
    case "failed":
      return "red";
    case "pending":
      return "orange";
    case "completed":
      return "green";
    case "reversed":
      return "yellow";
    case "cancelled":
      return "purple";
    case "chargeback":
      return "blue";
    default:
      return "#000";
  }
}

export function getPaymentType(status: string) {
  switch (status) {
    case "debit":
      return "Débito";
    case "credit":
      return "Cartão de Crédito";
    case "pix":
      return "Pix";
    case "invoice":
      return "Boleto";
    default:
      return "...";
  }
}

export function getPaymentTypeIcon(status: string) {
  switch (status) {
    case "debit":
      return BsFillCreditCardFill;
    case "credit":
      return BsFillCreditCardFill;
    case "pix":
      return MdPix
    case "invoice":
      return FaFileInvoiceDollar
    default:
      return BsDash;
  }
}
