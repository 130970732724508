// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Box,
  Button,
  Input,
} from "@chakra-ui/react";
import MiniStatistics from 'components/card/MiniStatistics';
// Custom components
import Card from "components/card/Card";
import React, { useState } from "react";
import { useUserStore } from "store/reducers/user";
import MoneyInput from './MoneyInput';
import {CardContainer, CardRow} from "./styles";
import { formatCurrency } from '../currency';
import { notifyAlert, notifyError } from "utils/notification";
export default function Default(props: {
  buttonClick?: () => Promise<void>;
  name: string;
  growth?: string | number;
  value: string | number;
  description: string;
  buttonText: string;
  disableButton: boolean;
  onClickButton: (userId: number, amount: number) => void;
}) {
  const {
    buttonClick,
    disableButton,
    name,
    buttonText,
    onClickButton,
    value,
    description,
  } = props;
  const { auth } = useUserStore();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const [page, setPage] = useState(true);

  const toogleButton = () => {
    setPage(!page);
  };

  const DisplayValues = () => {
    return (
      <React.Fragment>
        <CardContainer>

       <MiniStatistics

              name="Sua comissão"
              value={value}
            />

        <CardRow>
          <Button
            disabled={disableButton}
            bg={"#4b0b61"}
            onClick={() => toogleButton()}
            color={"white"}
            >
            {buttonText}
          </Button>
        </CardRow>
            </CardContainer>
      </React.Fragment>
    );
  };

  const EditValues = (props: { value: Number }) => {
    const [inputValue, setInputValue] = useState("");
    const runCallback = async () => {
      console.log({
        input: Number(inputValue),
        props: Number(props.value),
        isNan: Number.isNaN(Number(inputValue)),
      });
      if (Number.isNaN(Number(inputValue))) {
        notifyError("Valor incorreto!");
        setInputValue("");
      } else if (Number(inputValue) > Number(props.value)*100) {
        notifyError("O valor não pode ser maior que o disponível");
        setInputValue("");
      } else {
        await onClickButton(auth.user.id, Number(inputValue));
        setPage(!page);
      }
    };
    return (
      <React.Fragment>
        <Box>
          <Stat>
            <StatLabel
              lineHeight="100%"
              fontSize={{
                base: "sm",
              }}
            >
              {name}
            </StatLabel>
          </Stat>
        </Box>
        <Box>
          <MoneyInput
            value={inputValue}
            setValue={setInputValue}
            required={true}
            label={"Qual valor você deseja?"}
            id={"1"}
          />
        </Box>

        <Box>
          <Button
            disabled={disableButton}
            bg={"#4b0b61"}
            onClick={() => runCallback()}
            color={"white"}
          >
            Solicitar
          </Button>
          <Button
            onClick={() => setPage(!page)}
            color={"purple"}
          >
            Voltar
          </Button>
        </Box>
      </React.Fragment>
    );
  };

  return (
    // <Card py='15px'>
    
      <Flex
        flexDirection={"column"}
        // align={{ base: "center", md: "flex-start", xl: "flex-start" }}
        // justify={{ base: "center", md: "flex-start",xl: "space-around" }}
      >
        {page ? <DisplayValues /> : <EditValues value={Number(value)} />}
      </Flex>
    
  );
}
function parseNumber(inputValue: string) {
  throw new Error("Function not implemented.");
}
