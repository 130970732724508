import React from 'react';
import { Box, FormLabel, Select } from '@chakra-ui/react';

type SelectInputOptions = {
  value: string
  label: string
}

type SelectInputProps = {
  register: Function
  id: string
  label: string
  options: SelectInputOptions[]
  required?: boolean
  placeholder?: string
  disabled?: boolean
}

export default function SelectInput ({ register, id, label, options, required = true, placeholder = "Selecione uma opção", disabled = false }: SelectInputProps) {
  return (
    <Box marginBottom="10px">
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <Select id={id} {...register(id, { required: required })} disabled={disabled}>
        <option value="">{placeholder}</option>
        {
          options?.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))
        }
      </Select>
    </Box>
  );
};
