import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;
`

export const Row = styled.div`
    width: 100%;

`

export const SwitchRow = styled.div`
display: flex;
flex-direction: row;
gap: 10px;
`

export const Item = styled.div`
   

`