import React from 'react';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import CheckTable from './CheckTableProvaSocial';
import { useUserStore } from 'store/reducers/user';
import { AlertModal, AlertModel } from 'components/modal/AlertModal';
import { SocialProof as SocialProofModel, SocialProofResponse } from 'models/SocialProof';
import { deleteSocialProof, getSocialProofs } from 'controllers/SocialProofs';
import LoadingPage from 'components/Loading/LoadingPage';
import SocialProofForm from './SocialProofForm';
import { DeleteModal } from 'components/modal/DeleteModal';

export default function SocialProof() {
  const token = useUserStore((state) => state.auth.token);

  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [socialProofData, setSocialProofData] = React.useState<SocialProofResponse>({ data: [] });
  const [selectedSocialProof, setSelectedSocialProof] = React.useState<SocialProofModel>(null);
  const [confirmDeletion, setConfirmDeletion] = React.useState<number>(null);
  const [page, setPage] = React.useState<number>(1);
  const [alert, setAlert] = React.useState<AlertModel>(null);

  const switchShow = () => {
    setSelectedSocialProof(null);
    setShow(!show);
  }

  const handleGetSocialProofs = async () => {
    try {
      setLoading(true);
      const response = await getSocialProofs(page, token);
      setSocialProofData(response);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }

  const selectSocialProof = (socialProof: SocialProofModel) => {
    setSelectedSocialProof(socialProof);
    setShow(true);
  }

  const requestDeleteSocialProof = (socialProof: SocialProofModel) => {
    setConfirmDeletion(socialProof.id);
  }

  const handleDeleteSocialProof = async (socialProofId: number) => {
    setLoading(true);
    try {
      await deleteSocialProof(socialProofId, token);
      const updatedSocialProofData = socialProofData.data.filter(socialProof => socialProof.id != socialProofId)

      setSocialProofData({
        ...socialProofData,
        data: [
          ...updatedSocialProofData
        ]
      });
    } catch (error) {
      console.log(error);
    } finally {
      setConfirmDeletion(null);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    handleGetSocialProofs();
  }, [page]);

	return (
    <>
      <LoadingPage isOpen={loading} />
      <Button onClick={switchShow}>{show ? 'Voltar' : 'Nova Prova Social'}</Button>

      {alert && (
        <Box mt="30px">
          <AlertModal alert={alert} setAlert={setAlert}/>
        </Box>
      )}

      {show ? (
        <SocialProofForm
          switchShow={switchShow}
          show={show}
          handleGetSocialProofs={handleGetSocialProofs}
          selectedSocialProof={selectedSocialProof}
        />
      ) : (
        <Box pt={{ base: '130px', md: '60px', xl: '40px' }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
            <CheckTable
              tableData={socialProofData}
              tableName="Prova Social"
              selectSocialProof={selectSocialProof}
              requestDeleteSocialProof={requestDeleteSocialProof}
              page={page}
              setPage={setPage}
            />
          </SimpleGrid>
        </Box>
      )}

      <DeleteModal
        title="Remover Prova Social?"
        isOpen={confirmDeletion != null}
        onClose={() => setConfirmDeletion(null)}
        handleDelete={() => handleDeleteSocialProof(confirmDeletion)}
      />
    </>
	);
}
