import React from "react";
import { Box, Container, Heading, Text, useColorMode } from '@chakra-ui/react';
import Brand from 'components/sidebar/components/Brand';
import { NavLink } from 'react-router-dom';

function Terms() {
  const { colorMode } = useColorMode()

  return (
    <Box py="10" bg={colorMode == "light" && '#e2e8f0'}>
      <Container maxW="container.md" >
        <Box mb="8">
          <NavLink to='/auth/sign-in'>
            <Brand />
          </NavLink>
        </Box>
        <Heading as="h1" size="xl" mb="4" textAlign="center">
          Termos e Condições de Uso
        </Heading>
        <Text mb="4">
          Bem vindo(a)! Obrigado por utilizar a nosso Serviço!
          <br /><br />
          A DubPay (CNPJ: 48.948.447/0001-13) é uma plataforma que cria mecanismos para ajudar a rotina comercial das empresas que vendem pela internet, contribuindo para uma melhor prestação de serviços para consumidores e assegurando transparência nos pagamentos eletrônicos.
          <br /><br />
          Nosso compromisso é assegurar que os clientes possam usufruir dos seguintes serviços:
          <br /><br />
          a. Transferência de valores da conta digital para sua conta pessoal de forma simples;<br />
          b. Geração de Boleto e Pix;<br />
          c. Vendas no cartão de crédito;<br />
          d. Checkout transparente DubPay;<br />
          e. Intermediação de Pagamentos;<br />
          f. Intermediação com Fornecedores Nacionais e Internacionais.
          <br /><br />
          Ao realizar o cadastro como parte do processo de uso dos serviços da DubPay o lojista aceita vincular-se às condições deste termo sem prejuízo da aplicação da legislação vigente.
          <br /><br />
          Portanto, leia com atenção e, se tiver alguma dúvida, estaremos sempre à disposição para ajudá-lo através do e-mail contato@dubPay.com.br, no horário comercial de 08:00 às 17:00.
        </Text>
        <Heading as="h3" size="md" mb="4">
          1. SERVIÇOS DA DUBPAY
        </Heading>
        <Text mb="4">
          1.1. Os serviços prestados pela DUBPAY consistem na captura, processamento, roteamento, liquidação e gestão de pagamentos, possibilitando ainda que pessoas físicas e/ou jurídicas, através de um único CONTRATO, ofereçam os principais MEIOS DE PAGAMENTOS aos seus COMPRADORES.
          <br /><br />
          1.2. A DUBPAY se reserva no direito de suspender ou cancelar, a qualquer momento, o seu acesso à aplicação em caso de suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita, ou pelo não cumprimento de quaisquer condições previstas nestes Termos de Uso, na Política de Privacidade ou na legislação aplicável.
          <br /><br />
          1.3. Durante todo o período de apuração das supostas fraudes ou atos ilícitos os valores em conta ficam BLOQUEADOS até que sejam esclarecidas todas as situações investigadas;
          <br /><br />
          Nesses casos, o usuário/cliente não fará jus a indenização de qualquer natureza, sendo conferido a DUBPAY a possibilidade tomar outras medidas necessárias para perseguir e resguardar seus interesses.
          <br /><br />
          1.4. Nossos serviços seguem os princípios gerais do Código de Defesa do Consumidor (Lei 8.078/90), em especial obedece a Política Nacional das Relações de Consumo, cujo objetivo seria o atendimento das necessidades dos consumidores, o respeito à sua dignidade, segurança e a proteção de seus interesses econômicos, bem como a transparência e harmonia das relações de consumo.
          <br /><br />
          1.5. Caso a DUBPAY constate que houve alguma falha na prestação do serviço/produto ou descumprimento de politica/termo do lojista, fica autorizado o bloqueio dos valores correspondentes aos itens reclamados por até 180 (cento e oitenta) dias até que seja decidido sobre os reais responsáveis.
          <br /><br />
          1.5.1. Após notificar via e-mail o estabelecimento para esclarecimentos da situação, este terá o prazo de 2 (dois) dias úteis para se manifestar, sob pena do valor ser estornado para o consumidor, sem nenhuma penalidade para a DUBPAY.
          <br /><br />
          1.5.2. Após os 180 (cento e oitenta) dias mencionados no item 1.5, e apuradas as responsabilidades, a DUBPAY tem autonomia para ressarcimento do consumidor final caso seja solicitado.
          <br /><br />
          1.5.3. A DUBPAY, sendo a co-responsável pelos pagamentos processados pelos usuários (vendedores) da plataforma, reserva-se o direito de cancelar a totalidade das vendas sob suspeita de fraude a fim de prevenir futuros prejuízos causados pelos clientes vendedores aos clientes pagadores (clientes compradores ou portadores do cartão).
          <br /><br />
          1.5.4 Caso o índice de chargeback da loja/conta dos usuários (vendedores) esteja acima de 1% das transações totais, a DUBPAY reserva-se no direito de suspender, bloquear, descredenciar a conta do usuário (vendedor) assim como seus acessos e cancelar/estornar as transações previamente afim de evitar o aumento do índice de chargeback.
        </Text>
        <Heading as="h3" size="md" mb="4">
          2. ACESSO A DUBPAY
        </Heading>
        <Text mb="4">
          2.1. Para fazer uso dos serviços oferecidos pela DUBPAY, o CLIENTE deve efetuar um cadastro, preenchendo o formulário com as informações pessoais (como por exemplo: Nome, E-mail, Cidade, Estado, Qual o tempo de operação, Qual a média de faturamento mensal e qual o segmento principal do negócio), e outros dados conforme descrito em nossa Política de Privacidade;
          <br /><br />
          2.2. Para utilizar a DUBPAY você deve ter pelo menos 18 (dezoito) anos e precisa preencher com veracidade todos os dados solicitados, mantendo sempre atualizados, possibilitando que receba sempre as melhores SOLUÇÕES disponíveis.
          <br /><br />
          2.3. A partir do cadastro, você será titular de uma conta pessoal e intransferível, sendo o seu uso de responsabilidade exclusiva do cliente. Detectando que existem dados falsos, ou não possuindo a idade mínima permitida, essa conta será automaticamente deletada pela DUBPAY.
          <br /><br />
          2.4. É dever do cliente manter as suas informações pessoais atualizadas, sob pena de ter seu acesso bloqueado.
          <br /><br />
          2.5 Durante o cadastro, o CLIENTE deverá registrar um e-mail válido e cadastrar uma “senha” / “login”, cujo uso e responsabilidade são exclusivamente pessoais, sendo vedado o repasse para terceiros;
          <br /><br />
          2.6. Nosso compromisso se baseia na harmonização dos interesses dos usuários e na compatibilização da proteção do consumidor, acrescidos com a necessidade de desenvolvimento econômico e tecnológico, sempre com base na boa-fé e no equilíbrio nas relações entre consumidores e fornecedores ( Art. 170 da CF/88).
          <br /><br />
          2.7. Nossa plataforma desenvolve serviços de conteúdo íntegro e procura fazer a divulgação adequada dos produtos e serviços, asseguradas a liberdade de escolha na disponibilização da melhor solução ao cliente;
          <br /><br />
          2.8. Estabelecemos que todos os produtos e serviços da nossa plataforma possuem informação adequada e clara, com especificação correta, características e preço definidos;
        </Text>
        <Heading as="h3" size="md" mb="4">
          3. RELAÇÃO CONTRATUAL - DUBPAY X USUÁRIO
        </Heading>
        <Text mb="4">
          3.1. Os serviços e o conteúdo oferecidos pela plataforma são de propriedade da DUBPAY, que ao estabelecer o contrato permite ao usuário o gozo das funcionalidades do sistema. Essa licença é de uso não-exclusivo, limitado, revogável e de uso pessoal.
          <br /><br />
          3.2 É da liberalidade do usuário subscrever a qualquer plano oferecido pela DUBPAY, sujeito às regras descritas nesses Termos de Uso.
          <br /><br />
          3.3. Além do oferecimento da subscrição aos planos da DUBPAY, nossa plataforma permitirá a compra de produtos para o uso do usuário dentro da plataforma, estando sujeita às regras de licenciamento dispostas na plataforma e/ou nesse Termo de Uso. Caso tais produtos envolvam a integração com plataformas de terceiros, o usuário também estará sujeito aos Termos de Uso, Política de Privacidade e Especificações de Segurança de tal terceiro.
          <br /><br />
          3.4. Todas as informações da relação entre a DUBPAY e os usuários são suficientemente precisas, claras e obrigam o respeito mútuo entre as partes.
          <br /><br />
          3.5. Temos o compromisso de não condicionar o fornecimento de produto ou de serviço ao fornecimento de outro produto ou serviço, nem elevar abusivamente e sem justa causa o preço de produtos ou serviços;
          <br /><br />
          3.6. Estamos disponíveis para solucionar qualquer dúvida ou problema através do e-mail: contato@dubpay.com.br.
          <br /><br />
          3.7. Nossa plataforma não envia ou entrega aos usuários, sem solicitação prévia, qualquer produto, ou fornece qualquer serviço;
          <br /><br />
          3.8. Fica proibido a qualquer das partes nessa relação exigir vantagem manifestamente excessiva;
        </Text>
        <Heading as="h3" size="md" mb="4">
          4. ASSINATURA E CANCELAMENTO DE PLANOS
        </Heading>
        <Text mb="4">
          4.1. A DUBPAY fornece 1 (um) tipo de assinatura de planos de serviço, conforme definido a seguir:
          <br /><br />
          4.1.1. Gratuita: 7% de taxa por transação efetivada + 3% de taxa por parcela antecipada quando houver antecipação. Utilização integral de todos os serviços disponíveis na plataforma.
          <br /><br />
          4.2. A renovação do plano será automática, exceto se houver manifestação contraria requerendo o cancelamento antes da data de faturamento.
          <br /><br />
          4.3. O cancelamento da subscrição ao plano pode ser realizado a qualquer tempo pelo usuário, sendo entendido como manifestação expressa de que não pretende renovar a licença no próximo período ainda não contabilizado para fins de cobrança. O cancelamento não exime o usuário da obrigação de pagar a DUBPAY os valores devidos até o fechamento do ciclo, pois a prestação só será findada no início do próximo ciclo.
          <br /><br />
          4.4. Nós da DUBPAY sabemos que cada um dos planos é mais adequado para solucionar determinados problemas do usuário. Pensando nisso, oferecemos a possibilidade de você transitar entre os planos (sujeito às mudanças de disponibilidade de recursos oferecidas por cada um), editando ou fazendo upgrade/downgrade de sua subscrição. É importante ressaltar que o usuário que deseja cancelar ou sair das Versões Pagas não perderá sua conta nem o conteúdo gerado, ele apenas virará um usuário do plano gratuito, perdendo acesso às features oferecidas anteriormente.
          <br /><br />
          4.5. A aplicação possui funcionalidades que podem ser compradas dentro da plataforma, as chamadas “In-App Purchases”. As eventuais compras desses serviços ocorrerão de maneira semelhante ao disposto acima.
          <br /><br />
          4.6.Todos os planos oferecidos seguem rigorosamente os padrões técnicos, buscando o equilibrio entre o custo-benefício, evitando vantagens abusivas que:
          <br /><br />
          I. ofendem os princípios fundamentais do sistema jurídico a que pertencemos;<br />
          II. restringem direitos ou obrigações fundamentais inerentes à natureza do contrato, de tal modo a ameaçar seu objeto ou equilíbrio contratual;<br />
          III. se mostram excessivamente onerosa para o usuário, considerando-se a natureza e conteúdo do contrato, o interesse das partes e outras circunstâncias peculiares ao caso;<br />
          <br /><br />
        </Text>
        <Heading as="h3" size="md" mb="4">
          5. PAGAMENTOS NA DUBPAY
        </Heading>
        <Text mb="4">
          5.1. Os pagamentos efetuados na DUBPAY deverão ser realizados dentro da aplicação por meio de Cartão de Credito, Pix e/ou Boleto Bancário, sendo processados das seguintes formas:
          <br /><br />
          a. Boleto bancário – liberação ocorrerá a partir da liquidação do boleto pelo consumidor, de acordo com as regras de compensação do banco ao qual foi destinado o pagamento;
          <br />
          b. Cartão de crédito – liberação a cada 30 (trinta) dias por parcela, a partir da data de aprovação do pagamento efetuado pelo consumidor.
          <br />
          c. Pix – liberação ocorrerá a partir da liquidação do pix pelo consumidor, de acordo com as regras de compensação do banco no qual é destinado o pagamento.
          <br /><br />
          5.2. O preço pago não é reembolsável, a menos que diversamente determinado pela DUBPAY. Reservamo-nos ainda no direito de estabelecer, remover e/ou revisar o preço relativo a todos os serviços ou bens obtidos por meio do uso da aplicação a qualquer momento. Contudo, nunca alteraremos o valor da sua subscrição sem antes avisá-lo.
          <br /><br />
          5.3. Havendo a incidência impostos, o usuário será responsável por seu recolhimento. Em caso de eventual recolhimento por parte da aplicação, a mesma explicita que repassará ao usuário no preço.
          <br /><br />
          5.4. A confirmação do pagamento por meio da aplicação ocorrerá em até 3 (três) dias úteis. O processamento das informações de pagamento e a confirmação do pagamento serão realizados por sistemas de terceiros (ex. instituição financeira ou administradora do cartão de crédito), sendo o aplicativo uma mera interface entre o cliente e esses sistemas.
          <br /><br />
          5.5. Para os produtos ou promoções da DUBPAY que exijam um prazo mínimo de assinatura é importante que o usuário esteja ciente de que podem ser cobradas tarifas adicionais, caso desista antes do prazo mínimo de assinatura.
          <br /><br />
          5.6. Caso a DUBPAY crie algum código promocional (por exemplo, cupom de desconto) este deve ser usado para a finalidade a que se destina, bem como dirigido ao público específico, seguindo todas suas condições. O código promocional pode ser cancelado caso se verifique que foi transferido, vendido ou utilizado com erro, fraude, ilegalidade ou violação às condições do respectivo código.
          <br /><br />
          5.7. A devolução de valores solicitadas pelo usuário (consumidor) deverá ser analisada pela DUBPAY em conjunto com o responsável pela venda do produto.
        </Text>
        <Heading as="h3" size="md" mb="4">
          6. DIREITOS DA DUBPAY SOBRE A APLICAÇÃO
        </Heading>
        <Text mb="4">
          6.1. Todos os direitos relativos à DUBPAY, incluindo suas funcionalidades, são de nossa propriedade exclusiva, inclusive no que diz respeito aos seus textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias e demais conteúdos produzidos direta ou indiretamente pela DUBPAY .
          <br /><br />
          6.2. O Conteúdo da DUBPAY é protegido pela Lei de Direitos Autorais e de Propriedade Intelectual (Lei 9.609/1998), sendo terminantemente proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do nosso conteúdo para qualquer finalidade, sem o consentimento prévio e expresso da DUBPAY.
          <br /><br />
          6.3 Qualquer uso não autorizado do Conteúdo da DUBPAY será considerado como violação dos direitos autorais e de propriedade intelectual da DUBPAY, devendo ser apurados nas esferas administrativas, civis e penais cabíveis, submetendo os infratores a pena de reclusão de 1 até 4 anos conforme art. 12 da Lei 9.609/98.
        </Text>
        <Heading as="h3" size="md" mb="4">
          7. PROPRIEDADE INTELECTUAL SOBRE O SOFTWARE E OS MATERIAIS DISPONIBILIZADOS
        </Heading>
        <Text mb="4">
          7.1. A DUBPAY zela pela qualidade dos materiais disponibilizados ao usuário considerando-os de suma importância, já que sua criação é fruto de muito trabalho e dedicação de nossos desenvolvedores. Por isso, reafirmamos que a DUBPAY garante que todos os direitos, títulos e interesses (incluindo os direitos autorais, da marca e outros de propriedade intelectual) sobre o serviço disponibilizado permanecerão sob a titularidade da DUBPAY.
          <br /><br />
          7.2. O usuário não adquirirá nenhum direito de propriedade sobre os serviços e conteúdos da DUBPAY, exceto quando haja outorga expressa neste Termo de Uso.
          <br /><br />
          7.3. É proibido que o usuário faça o download de nosso conteúdo com a finalidade de armazená-lo em banco de dados para oferecer para terceiro. Veda-se, também, que o conteúdo disponibilizado por nós seja usado para criar uma base de dados ou um serviço que possa concorrer de qualquer maneira com o nosso negócio.
          <br /><br />
          7.4. Nossa plataforma segue rigorosamente a Lei nº 13.709/18 (Lei Geral de Proteção de Dados ou “LGPD”) que regulamenta a forma como as organizações podem utilizar dados pessoais no Brasil, estabelecendo regras detalhadas para a coleta, uso, armazenamento, descarte e qualquer outro tipo de tratamento desses dados.
          <br /><br />
          7.4. Nossa plataforma segue rigorosamente a Lei nº 13.709/18 (Lei Geral de Proteção de Dados ou “LGPD”) que regulamenta a forma como as organizações podem utilizar dados pessoais no Brasil, estabelecendo regras detalhadas para a coleta, uso, armazenamento, descarte e qualquer outro tipo de tratamento desses dados.
          <br /><br />
          7.6. Asseguramos uma profunda obediência à LGPD, cujo conteúdo e diretrizes impõe uma profunda transformação no sistema de proteção de dados brasileiro, afetando todos os setores da economia, inclusive as relações entre clientes e fornecedores, dentre outras relações nas quais dados pessoais sejam coletados tanto no ambiente digital quanto fora dele.
        </Text>
        <Heading as="h3" size="md" mb="4">
          8. RECLAMAÇÕES SOBRE VIOLAÇÃO DE DIREITO AUTORAL
        </Heading>
        <Text mb="4">
          8.1. Alegações de infringência de direito autoral de qualquer conteúdo disponível na DUBPAY devem ser encaminhadas para o e-mail: contato@dubpay.com.br, para o devido conhecimento e tomada das medidas administrativas e judiciais cabíveis.
        </Text>
        <Heading as="h3" size="md" mb="4">
          9. RESPONSABILIDADES DOS USUÁRIOS E DA DUBPAY
        </Heading>
        <Text mb="4">
          9.1. A responsabilidade pelo Uso da DUBPAY é exclusivamente do usuário, sendo obrigatório que se respeite as regras deste Termo de Uso, bem como a legislação aplicável, em especial o Código de Defesa do Consumidor e o Código Civil (Lei 10.406/2002).
          <br /><br />
          9.2. A DUBPAY, bem como seu controlador, suas afiliadas, parceiras ou funcionários, não serão responsabilizados por danos diretos ou indiretos que resultem do acesso ou utilização da nossa plataforma.
          <br /><br />
          9.3. A DUBPAY NÃO SE RESPONSABILIZA POR INTERRUPÇÕES OU SUSPENSÕES DE CONEXÃO COM A INTERNET, TRANSMISSÕES DE COMPUTADOR INCOMPLETAS OU COM FALHAS, BEM COMO POR FALHA TÉCNICA DE QUALQUER NATUREZA.
          <br /><br />
          9.4 A DUBPAY TAMBÉM NÃO SERÁ RESPONSABILIZADA PELO MAU FUNCIONAMENTO ELETRÔNICO DE QUALQUER REDE DE INTERNET, HARDWARE OU SOFTWARE, BEM COMO PELA INDISPONIBILIDADE DE ACESSO À INTERNET OU À NOSSA PLATAFORMA.
          <br /><br />
          9.5. A DUBPAY não será responsável:
          <br />
          a. por eventuais erros e/ou inconsistências na transmissão de dados, bem como relacionados à qualidade ou disponibilidade da conexão de internet, capazes de obstar o adequado recebimento de informações pela DUBPAY;
          <br />
          b. por informações desatualizadas, incompletas e/ou inverídicas eventualmente apresentadas por meio do Site, bem como pelas Transações não efetivadas em razão da inconsistência das referidas informações;
          <br />
          c. pelo uso dos Serviços em desacordo com o disposto nestes Termos ou com a regulamentação aplicável;
          <br />
          d. por qualquer dano ou prejuízo que o usuário venha a suportar, decorrente da utilização indevida dos Serviços oferecidos pela nossa plataforma;
          <br />
          e. por perda de lucros, perda de receita, perda de dados nem qualquer danos indiretos, visto que segue rigorosamente os princípios e diretrizes do Código de Defesa do Consumidor (Lei nº 8.078/1990) .
          <br /><br />
          9.6. É de inteira responsabilidade do usuário manter o ambiente de seu dispositivo (computador, celular, tablet, entre outros) seguro, com o uso de ferramentas, como: antivírus, firewall, entre outras, de modo a contribuir na prevenção de riscos eletrônicos.
          <br /><br />
          9.7. A DUBPAY desenvolve alguns serviços utilizando-se de tecnologias integradas, sendo necessário que, eventualmente, se possa conter links para sites e aplicativos de terceiros. Contudo, isso não indica que a DUBPAY endossa, verifica, garante ou possui qualquer ligação com os proprietários desses sites ou aplicativos, sendo estes de inteira responsabilidades dos seus proprietários. Recomenda-se assim que você leia os termos de uso e políticas de privacidade de cada site de terceiros ou serviço que o usuário vier a visitar ou utilizar.
          <br /><br />
          9.8. A DUBPAY atua como facilitadora na interação entre você e terceiros independentes, como Fornecedores de Produtos, Empreendedores Digitais, Influenciadores, Gateways de Pagamentos, plataformas de e-commerces e Market Places, não sendo fornecedor direto de bens ou serviços. As plataformas que tenham interface integradas com a nossa são responsáveis por seus conteúdos, sendo de responsabilidade exclusiva dos seus proprietários qualquer dano ou prejuízo ocasionados ao usuários.
          <br /><br />
          9.9. É dever do usuário ler e decidir se concorda com os termos e condições dos sites dos parceiros da nossa plataforma que eventualmente seja direcionado parta utilizar nossos serviços.
          <br /><br />
          9.10. A DUBPAY procura assegurar que o uso da nossa plataforma não tenha interrupções, nem erros. No entanto, não podemos garantir a operação do plataforma em tempo integral, na medida em que esta depende de serviços prestados por terceiros e disponibilidade de informações em sites de terceiros, como empresas de telecomunicações e provedores de acesso à internet.
          <br /><br />
          9.11. Embora o ambiente da plataforma esteja sujeito a monitoramento por questões de segurança, não divulgaremos informações privativas dos Usuários, sem autorização prévia, exceto nos casos expressamente previstos nos termos da Política de Privacidade, ou mediante ordem judicial.
          <br /><br />
          9.12 O DUBPAY se reserva o direito de suspender imediatamente o uso da plataforma e a bloquear o acesso sem aviso prévio nos casos elencados abaixo:
          <br />
              I. usuário tenha fornecido informações falsas, imprecisas, incompletas ou enganosas;
          <br />
              II. usuário tenha violado os termos do presente termo de uso;
          <br />
              III. usuário esteja fazendo uso dos serviços em violação de lei;
          <br />
              IV. usuário esteja envolvido em condutas fraudulentas ou ilegais; ou
          <br />
              V. usuário esteja em atraso no pagamento dos serviços onerosos, de acordo com o plano escolhido, por período superior a 30 (trinta) dias.
          <br />
              VI. usuário esteja com índice de chargeback acima do permitido pela plataforma.
          <br /><br />
          9.13.Todas as despesas e prejuízos financeiros que a DUBPAY suportar decorrentes de uma má prestação de serviços/produtos para o cliente final deverão ser ressarcidas pelo estabelecimento causador do dano, que poderá ser acionado judicialmente através de ação de regresso;
          <br /><br />
          9.14. Àqueles que causarem danos à imagem ou à marca da DUBPAY, seja por palavras, mensagens, comentários, insinuações, diretamente ou indiretamente, por qualquer meio, serão responsabilizados nas esferas civil e penal.
        </Text>
        <Heading as="h3" size="md" mb="4">
          10. CONTEÚDO PRODUZIDO PELOS USUÁRIOS
        </Heading>
        <Text mb="4">
          10.1. Fica a exclusivo critério da DUBPAY permitir que o usuário apresente, carregue, publique ou disponibilize conteúdos ou informações de texto, imagem, áudio ou vídeo na nossa plataforma (“Conteúdo de Usuário”).
          <br /><br />
          10.2. É proibido qualquer Conteúdo de Usuário de caráter difamatório, calunioso, injurioso, violento, pornográfico, obsceno, ofensivo ou ilícito. A violação da Lei de Marcas e patentes, bem como de qualquer outra disposição legal por parte dos usuários nesse contexto, devem ser apurado no rigor da lei, devendo a responsabilidade recair sobre aqueles que lhe deram causa.
          <br /><br />
          10.3. A Titularidade do Conteúdo de usuário fornecido por você permanece de sua propriedade. Contudo, ao fornecê-lo para a DUBPAY você nos outorga uma licença em nível mundial, por prazo indeterminado, gratuita e transferível, e com direito a sublicenciar, usar, copiar, modificar, criar obras derivadas, distribuir, publicar, exibir esse Conteúdo de Usuário em todos os formatos e canais de distribuição possíveis, sem necessidade de aviso prévio e de qualquer pagamento, desde que isso esteja relacionado ao funcionamento da plataforma.
          <br /><br />
          10.4. A DUBPAY possui a prerrogativa de analisar, monitorar e remover Conteúdo de Usuário a nosso exclusivo critério, sem necessidade de aviso prévio do usuário.
          <br /><br />
          10.5. Nossa plataforma, ao realizar publicidade dos produtos e serviços, manteremos em nosso poder os dados fáticos, técnicos e científicos que dão sustentação à mensagem.
          <br /><br />
          10.6. É proibido toda e qualquer manifestação enganosa ou abusiva.
        </Text>
        <Heading as="h3" size="md" mb="4">
          11. Disposições finais sobre esse Termo de uso
        </Heading>
        <Text mb="4">
          11.1. Este Termo de Uso pode ser alterado ou atualizado a qualquer tempo, a fim de melhorar sua experiência e refletir os ajustes realizados. Asseguramos que as alterações serão previamente informados pelo endereço de e-mail fornecido no momento do cadastro.
          <br /><br />
          11.2. O usuário fica obrigado a aceitar os novos Termos de Uso, sob pena de ter seu acesso negado. Se de qualquer maneira você utilizar a DUBPAY mesmo após a alteração destes Termos de Uso, isso significa que você concorda com todas as modificações.
          <br /><br />
          11.2. Em caso de conflito entre estes termos e os termos modificador, os termos posteriores prevalecerão com relação a esse conflito, sendo obrigatoriamente observado as leis regem nosso ordenamento jurídico.
          <br /><br />
          11.3. Estes Termos de Uso são regidos pelas Leis da República Federativa do Brasil, especialmente pela CF/1988, pelo Código de Defesa do Consumidor (Lei nº 8.078/1990) e Pela Lei de Patentes e Direitos Autorais (Lei 9.609/98). Todas as dúvidas e situações não previstas nestes Termos de Uso serão primeiramente resolvidas pela DUBPAY e, caso persistam, deverão ser solucionadas pelo Foro da Comarca de Brasília/Distrito Federal, com exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.
          <br /><br />
          11.4.Em caso de dúvida, comentário ou sugestão, por favor, entre em contato conosco por meio do e-mail contato@dubpay.com.br, no horário comercial de 08:00 às 17:00.
        </Text>
      </Container>
    </Box>
  );
}

export default Terms;
