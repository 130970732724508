import {StyledNavbar} from "./styles/headerStyles"

const Navbar = (props) => {
    let navbarPosition = 'fixed' as const;
    let navbarBorder = 'transparent';
   
    return (
        <StyledNavbar
            position={navbarPosition}
            
            borderColor={navbarBorder}
           
        >
            {props.children}
        </StyledNavbar>
    );
};

export default Navbar;