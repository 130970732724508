import styled from "styled-components";

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1024px) {
    display:none
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;


`;

export const TitleName = styled.text`
color: white;
font-size: 12px; 
font-weight: bold;

`;
export const TitleEmail = styled.text`
color: white;
font-size: 10px; 
`;

export const ImageContainer = styled.div`
  width: 240px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
`;
