import styled from "styled-components";

export const SelectTypeUserContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center; 
    align-items: center; 
    gap: 15px;
`
export const SelectTypeModal = styled.div`
    border: 0.1px solid #D8D8D8;
    background-color: white;
    border-radius: 10px;
    padding: 20px 40px;
`
export const SelectTypeUserBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 180px;
    width: 180px;
    gap:5px;
`

export const BodyIcon = styled.div`
    bacgkround-color: #f0f0f0;
    border-radius: 50px;
`
export const ImageContainer = styled.div`
    width: 320px;
    
`;

export const Row = styled.div`
   width: 100%;
    
`;


export const Title = styled.div`
    
    
    
`;

export const Container = styled.div`
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;   
    gap: 20px; 
`