import {
  Box,
  Flex,
  Center,
  Tag,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {formatCurrency} from "utils/currency"
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { DebtAndCredit } from "models/Payment";
import * as React from "react";
import { formatCentToReal, formatCentToRealString } from "../formatCentToReal";
// Assets
import {
  MdCancel,
  MdCheckCircle,
  MdOutlineError,
  MdMoveDown,
} from "react-icons/md";

type RowObj = DebtAndCredit;

const columnHelper = createColumnHelper<RowObj>();

const formatNumberCentToReal = (number) => {
  
  let formatNumber = formatCentToReal(number);
  
  return formatNumber.toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}
// const columns = columnsDataCheck;
export default function ComplexTable(props: { tableData: any }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData;

  const columns = [
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Data
        </Text>
      ),
      cell: (info: any) => {  
        const date = new Date(info.getValue())

        const day = date.getUTCDate().toString();
        const month = (date.getUTCMonth() + 1).toString();
        const year = date.getUTCFullYear();
        return (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
          {day.padStart(2, "0")}/{month.padStart(2, "0")}/{year}
          </Text>
        </Flex>
      )},
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Id da operação
        </Text>
      ),
      cell: (info: any) => (
        
          <Text color={textColor} fontSize="sm" fontWeight="700">
            #{info.getValue()}
          </Text>
        
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <Flex align="status">
          
            {info.getValue() === "completed"
              ? <Tag colorScheme="green">Entrada</Tag>
              : info.getValue() === "reversed"
              ? <Tag colorScheme="blue">Estorno</Tag>
              : info.getValue() === "chargeback"
              ? <Tag colorScheme={"red"}>Cancelamento</Tag>
              : info.getValue() === "debt"
              ? <Tag colorScheme={"yellow"}>Saída</Tag>
              : null}
          
        </Flex>
      ),
    }),
    columnHelper.accessor("credit", {
      id: "credit",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Entrada (R$)
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatNumberCentToReal(
            parseFloat(
              info.getValue().toString()))}
        </Text>
      ),
    }),
    columnHelper.accessor("debt", {
      id: "debt",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Saída (R$)
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatNumberCentToReal(
            parseFloat(
              info.getValue().toString()))}
        </Text>
      ),
    }),
    columnHelper.accessor("total", {
      id: "total",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Total (R$)
        </Text>
      ),
      cell: (info) => {
        const value = parseFloat(info.getValue().toFixed(2).padEnd(2, "0"));

        if (value > 0) {
          return (
            <Text color={"green"} fontSize="sm" fontWeight="700">
              +{" "}
              {formatNumberCentToReal(
            parseFloat(
              info.getValue().toString()))}
            </Text>
          );
        } else {
          return (
            <Text color={"red"} fontSize="sm" fontWeight="700">
              {formatNumberCentToReal(
            parseFloat(
              info.getValue().toString()))}
            </Text>
          );
        }
      },
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="flex-start" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Extratos
        </Text>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
