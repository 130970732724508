import axios from "./api";
import {GetIntegrationResponse, GetIntegrationData, PostIntegration, PostIntegrationData, GetIntegration} from "models/Integrations"
import { useUserStore } from 'store/reducers/user';

export const getUserIntegrationByUserId = async (params: GetIntegrationResponse.Params): Promise<GetIntegrationResponse.Result> => {
    const url = `/seller/integration/${params.integrationId}/user/${params.userId}`;
    try {
     
      const integrationResult = await axios.get(
        `${process.env.REACT_APP_API_URL}${url}`
      );
        
      // console.log(integrationResult)
      return integrationResult.data as GetIntegrationResponse.Result;
    } catch (err) {
      console.log(err);
    }
}



export const postUserIntegration = async (body: PostIntegration.Params): Promise<PostIntegration.Result> => {
    const url = `/seller/integration/update`;
    try {
     
      const integrationResult = await axios.post(
        `${process.env.REACT_APP_API_URL}${url}`,
        body
      );
      
      return integrationResult.data as PostIntegration.Result;
    } catch (err) {
      console.log(err);
    }
}

export const getIntegrations = async (params: GetIntegration.Params): Promise<GetIntegration.Result> => {
  const token = useUserStore.getState().auth.token
  const url = `/user-integration`;
  try {
   
    const integrationResult = await axios.get(
      `${process.env.REACT_APP_API_URL}${url}`,{
        params: {
          type: params.type
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    
    return integrationResult.data as GetIntegration.Result;
  } catch (err) {
    console.log(err);
  }
}