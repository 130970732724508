import React from 'react';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import CheckTable from './CheckTableFrete';
import { useUserStore } from 'store/reducers/user';
import { Shipping as ShippingModel, ShippingResponse } from 'models/Shipping';
import { deleteShipping, getShippings } from 'controllers/Shippings';
import LoadingPage from 'components/Loading/LoadingPage';
import ShippingForm from './ShippingForm';
import { DeleteModal } from 'components/modal/DeleteModal';
import { AlertModal, AlertModel } from 'components/modal/AlertModal';

export default function Shipping() {
  const token = useUserStore((state) => state.auth.token);

  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [shippingData, setShippingData] = React.useState<ShippingResponse>({ data: [] });
  const [selectedShipping, setSelectedShipping] = React.useState<ShippingModel>(null);
  const [confirmDeletion, setConfirmDeletion] = React.useState<number>(null);
  const [alert, setAlert] = React.useState<AlertModel>(null);
  const [filters, setFilters] = React.useState({})

  const switchShow = () => {
    setSelectedShipping(null);
    setShow(!show);
  }

  const handleGetShippings = async (page: number = 1) => {
    try {
      setLoading(true);
      const response = await getShippings(page, token, filters);
      setShippingData(response);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }

  const selectShipping = (shipping: ShippingModel) => {
    setSelectedShipping(shipping);
    setShow(true);
  }

  const requestDeleteShipping = (shipping: ShippingModel) => {
    setConfirmDeletion(shipping.id);
  }

  const handleDeleteShipping = async (shippingId: number) => {
    if (!canDelete(shippingId)) {
      setConfirmDeletion(null);
      setAlert({
        type: "error",
        text: "Não é possível deletar todos os fretes de uma loja"
      });
      return;
    }

    setLoading(true);
    try {
      await deleteShipping(shippingId, token);
      const updatedShippingData = shippingData.data.filter(shipping => shipping.id != shippingId)

      setShippingData({
        ...shippingData,
        data: [
          ...updatedShippingData
        ]
      });
    } catch (error) {
      console.log(error);
    } finally {
      setConfirmDeletion(null);
      setLoading(false);
    }
  }

  const canDelete = (shippingId: number) => {
    const shippingToDelete = shippingData.data.find(shipping => shipping.id === shippingId);
    const shippingsOfTheSameStore = shippingData.data.filter(
      shipping => shipping.store.id === shippingToDelete.store.id
    );
    return shippingsOfTheSameStore.length > 1;
  }

  React.useEffect(() => {
    handleGetShippings();
  }, [filters])

	return (
    <>
      <LoadingPage isOpen={loading} />
      <Button onClick={switchShow}>{show ? 'Voltar' : 'Novo Frete'}</Button>

      {alert && (
        <Box mt="30px">
          <AlertModal alert={alert} setAlert={setAlert}/>
        </Box>
      )}

      {show ? (
        <ShippingForm
          switchShow={switchShow}
          show={show}
          shippingData={shippingData}
          setShippingData={setShippingData}
          selectedShipping={selectedShipping}
        />
      ) : (
        <Box pt={{ base: '130px', md: '60px', xl: '40px' }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
              <CheckTable
                tableData={shippingData}
                tableName="Frete"
                selectShipping={selectShipping}
                requestDeleteShipping={requestDeleteShipping}
                handleGetShippings={handleGetShippings}
                setFilters={setFilters}
              />
          </SimpleGrid>
        </Box>
      )}

      <DeleteModal
        title="Remover Frete?"
        isOpen={confirmDeletion != null}
        onClose={() => setConfirmDeletion(null)}
        handleDelete={() => handleDeleteShipping(confirmDeletion)}
      />
    </>
	);
}
