import React from 'react';
import { Box, FormLabel, Input } from '@chakra-ui/react';
import DeletableImageList, { DeletableImage } from '../images/DeletableImageList';
import { ImageFile } from 'models/File';
import { Control, Controller } from 'react-hook-form';
import { createImageFileList } from 'utils/file';

type Image = {
  url: string,
  id: number
}

type UpdateImageInputProps = {
  control: Control<any>
  id: string
  label: string
  images: Image[]
  onDelete: Function
  multiple?: boolean
  inputImages?: ImageFile[]
}

function UpdateImageInputComponent ({ control, id, label, images, onDelete, multiple=false, inputImages = null }: UpdateImageInputProps) {
  const [deletableImages, setDeletableImages] = React.useState<Image[]>([]);

  React.useEffect(() => {
    setDeletableImages(images)
  }, []);

  const handleImageDelete = async (id: number) => {
    const deleted = await onDelete(id);
    if (deleted) {
      setDeletableImages(deletableImages.filter(image => image.id !== id));
    }
  }

  const renderImages = () => {
    let imageList: DeletableImage[] = [];

    if (deletableImages) {
      imageList = deletableImages.map((image: Image, idx: number) => ({
        url: image.url,
        id: image.id,
        alt: `Imagem ${idx}`,
        deletable: true
      }));
    }

    if (inputImages && inputImages.length > 0) {
      const inputImagesArray = inputImages.map((image: ImageFile, idx: number) => ({
        url: image.preview,
        alt: `Imagem ${idx}`,
        deletable: false
      }));
      imageList = [...imageList, ...inputImagesArray];
    }

    return (
      <Box marginBottom="10px">
        <DeletableImageList images={imageList} onDelete={handleImageDelete} />
      </Box>
    );
  }

  return (
    <Box marginBottom="14px">
      <FormLabel htmlFor={id}>{label}</FormLabel>

      {renderImages()}

      {(!deletableImages || deletableImages.length == 0 || multiple) && (
        <Controller
          name={id}
          control={control}
          render={({ field }) => (
            <Input
              border="none"
              type="file"
              accept="image/png, image/jpeg"
              multiple={multiple}
              onChange={(e) => {
                if (e.target.files) {
                  let files = createImageFileList(e.target.files);
                  field.onChange(files);
                }
              }}
            />
          )}
        />
      )}
    </Box>
  );
}

export const UpdateImageInput = UpdateImageInputComponent;
