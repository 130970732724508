import styled from "styled-components"

export const Container = styled.div`
    margin: 20px;
`

export const Row = styled.div`
    width: 100%;
    
`
export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 20px;
    
`
export const RowBody = styled.div`
   padding-top: 10px;
   padding-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    
`
export const RowHeader = styled.div`

    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    
    font-weight: bold;
`
export const Item = styled.div`
    display: flex; 
  align-items: center; 
    justify-content: left;
   
`

export const FirstItem = styled.div`
    display: flex; 
  align-items: center; 
    justify-content: flex-start;
   
`
export const LastItem = styled.div`
    display: flex; 
  align-items: center; 
    justify-content: flex-end;
   
`

export const Status = styled.div`
    display: flex; 
    flex-direction: column;
  align-items: center; 
   
`