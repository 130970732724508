import api from 'services/api';
import { CreateSocialProofRequest, SocialProof, SocialProofResponse } from 'models/SocialProof';
import { notifyError } from 'utils/notification';

export async function getSocialProofs(page: number, token: string): Promise<SocialProofResponse> {
  try {
    const response = await api.get<SocialProofResponse>(
      `${process.env.REACT_APP_API_URL}/social-proofs?page=${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer as provas sociais.");
  }
}

export async function createSocialProof(data: CreateSocialProofRequest, token: string): Promise<SocialProof> {
  try {
    const response = await api.post<SocialProof>(
      `${process.env.REACT_APP_API_URL}/social-proofs`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao criar sua prova social.");
  }
}

export async function updateSocialProof(id: number, data: CreateSocialProofRequest, token: string): Promise<SocialProof> {
  try {
    const response = await api.post<SocialProof>(
      `${process.env.REACT_APP_API_URL}/social-proofs/${id}`,
      {
        ...data,
        "_method": "PUT"
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao atualizar sua prova social.");
  }
}

export async function deleteSocialProof(id: number, token: string): Promise<void> {
  try {
    await api.delete<SocialProof>(
      `${process.env.REACT_APP_API_URL}/social-proofs/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao deletar sua prova social.");
  }
}
