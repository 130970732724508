import * as React from 'react';
import { Flex, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdMoreHoriz } from 'react-icons/md';

type ListOptionsMenuProps = {
  data: any
  select?: Function
  deleteRequest?: Function
  showDetails?: Function
}
export function ListOptionsMenu ({ data, select, deleteRequest, showDetails }: ListOptionsMenuProps) {
  return (
    <Flex align='center'>
      <Menu strategy="fixed">
        <MenuButton as={IconButton} icon={<Icon as={MdMoreHoriz} w='28px' h='auto'/>} variant='ghost' aria-label='Options' />
        <MenuList>
          {showDetails !== undefined && (
            <MenuItem onClick={() => showDetails(data)}>Detalhes</MenuItem>
          )}
          {select !== undefined && (
            <MenuItem onClick={() => select(data)}>Editar</MenuItem>
          )}
          {deleteRequest !== undefined && (
            <MenuItem onClick={() => deleteRequest(data)}>Deletar</MenuItem>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
}
