import React from 'react';
import { useUserStore } from 'store/reducers/user';
import LoadingPage from 'components/Loading/LoadingPage';
import { Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, Input, Wrap, WrapItem } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { CreateStoreRequest, Store } from 'models/Store';
import { useForm } from 'react-hook-form';
import { CreateSellerIndicationRequest, SellerIndication, SellerIndicationResponse } from 'models/SellerIndication';
import axios from 'axios';
import { addTrailingSlash } from 'utils/form';
import { AlertModal, AlertModel } from 'components/modal/AlertModal'

type SellerIndicationFormProps = {
  switchShow: Function
  show: boolean
  handleGetSellerIndications: Function
  selected?: Store
}

export default function SellerIndicationForm({ switchShow, show, handleGetSellerIndications, selected }: SellerIndicationFormProps) {
  const token = useUserStore((state) => state.auth.token);
  const user = useUserStore((state) => state.auth.user);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState<AlertModel>(null);

  const defaultValues = selected ? selected : {};

  const {
    register,
    handleSubmit,
    reset
  } = useForm<CreateSellerIndicationRequest>({ defaultValues });

  const handleSave = async (data: CreateSellerIndicationRequest) => {
    await handleCreateIndication(data);
  }

  const handleCreateIndication = async (data: CreateSellerIndicationRequest) => {
    const createSellerIndicationRequestData: CreateSellerIndicationRequest = {
      status: "Inativo",
      user_indication_from_id: user.id,
      email: data.email,
      ...data,
    };
    const formData = new FormData();

    setLoading(true);

    formData.append("user_indication_from_id", user.id.toString());
    formData.append("user_indication_to_id", "0");
    formData.append("email", data.email);
    formData.append("status", "Inativo");
    //formData.append("emailFrom", emailFrom);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seller-indication`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      if (response) {
        handleGetSellerIndications();
        switchShow();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

  };


  React.useEffect(() => {
    reset();
  }, [show]);

  return (
    <>
      <LoadingPage isOpen={loading} />


      <Card
        flexDirection="column"
        marginTop={"10px"}
        w="100%"
        px="20px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        pt="80px"
      >
       
        <Box pt="20px">
          <form onSubmit={handleSubmit(handleSave)}>
            <FormControl rowGap="20px">
              {/* <SimpleGrid columns={2} spacing={10}> */}
              <FormLabel htmlFor="email">E-mail</FormLabel>
              <Input
                {...register("email")}
                id="email"
                type="email"
                isRequired
              />
              <br />

              {alert && (
                <Box mb="30px">
                  <AlertModal alert={alert} setAlert={setAlert}/>
                </Box>
              )}

              <Button type="submit">Enviar Convite</Button>
              <Button onClick={() => switchShow()}>Voltar</Button>
            </FormControl>
          </form>
        </Box>
      </Card>
    </>
  );
}
