import styled from 'styled-components';

export const InputStyles = styled.input`
  /* Estilos padrão */
  font-family: muli;
  padding: 10px 15px;
  border: 0.5px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.1s ease-in, box-shadow 0.2s ease-in;
  outline: none;
  /* Variantes */
  &:focus {
    box-shadow: 0 0 3px #00CF79;
    border-color: #00CF79;
  }
`;