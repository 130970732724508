import api from 'services/api';
import { TransactionStatusResponse } from 'models/Order';
import { notifyError } from 'utils/notification';

export async function getTransactionStatuses(token: string): Promise<TransactionStatusResponse> {
  try {
    const response = await api.get<TransactionStatusResponse>(
      `${process.env.REACT_APP_API_URL}/transaction-statuses`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer os status de pedido.");
  }
}
