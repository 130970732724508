import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Checkbox,
  Flex,
  Text,
  SlideFade
} from "@chakra-ui/react";
import { getMembershipPlans, createContract, completeRegistration } from "services/userRequests";
import { Container, Body, Row, RowFooterButton, SecondPageBody } from "../../styles"
import { useUserStore } from 'store/reducers/user';
import { usePerfilPage } from "../../store/index";
import { CustomButton } from "styleguide/Button"
import { H2, H5 } from "components/typograph";
const FinishStep = () => {
  const { onboardingPage, hasCnpj, setHasCnpj, setOnBoardingPage } = usePerfilPage();
  const [auth, updateStatusRegistration] = useUserStore((state) => [state.auth, state.updateStatusRegistration]);

  const [activeTab, setActiveTab] = React.useState(5);
  const [planos, setPlanos] = React.useState([]);
  const [isChecked, setIsChecked] = React.useState(false);
  const [selectPlan, setSelectPlan] = useState(null);
  let submitButton = null;

  useEffect(() => {
    (async () => {
      const plans = await getMembershipPlans();
      if (plans.success) {
        setPlanos(plans.data)
      }
    })();
  }, [])

  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFade(true)
  }, [])

  const handleFormSubmitAba5pular = () => {
    if(hasCnpj) {
      setOnBoardingPage(3)  
    } else {

      setOnBoardingPage(2)
    }

  }
  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const handleCheckboxChange = async (planosId: string) => {
    setIsChecked(!isChecked);
    setSelectPlan(planosId)
  };

  const sendDataToCompleteRegister = async () => {
    try {
      const contract = createContract({membershipPlanId: selectPlan});
      const registration = completeRegistration();

      const [contractResult, registrationResult] = await Promise.all([contract, registration]);

      if (contractResult.success && registrationResult) {
        updateStatusRegistration('Aguardando')
      }
    } catch (err) {
      err
    }
  }


  return (
     <SlideFade unmountOnExit={true} in={fade} offsetY='50px' reverse={true}>
        <Container>
           <H2 >
                Você completou seu cadastro
              </H2>
    <Box style={{
      backgroundColor: "#fff",
      flex: "1",
      alignItems: "center",
      padding: "10px"
    }}

    >
      <>
        {planos.map((plano) => {
          return (

            <React.Fragment>

             

              <FormControl display="flex" alignItems="center">
                <Flex justifyContent="space-between">
                  <Checkbox id="plano" isChecked={isChecked} onChange={() => handleCheckboxChange(plano.id)} />
                  <FormLabel htmlFor="plano" pl="4" fontSize="16px">
                    Eu Confirmo que todos os dados nesse cadastro são verdadeiros!
                  </FormLabel>

                </Flex>
              </FormControl>
            </React.Fragment>

          );
        })}
      </>
          </Box>
        <RowFooterButton >

       <CustomButton
              isFullWidth={false}
              variant={"tertiary"}
              onClick={handleFormSubmitAba5pular}
              >
              Voltar
            </CustomButton>
            <CustomButton
              isFullWidth={false}
              disabled={!isChecked}
              variant={"primary"}
              onClick={sendDataToCompleteRegister}
              >
             Enviar dados para análise
            </CustomButton>
              </RowFooterButton>
</Container>
      </SlideFade>
  )
}

export default FinishStep;