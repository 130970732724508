import * as React from "react";
import { Redirect, Route } from "react-router-dom";

import { useUserStore } from "store/reducers/user";

function PrivateRoute({ children, ...rest }: any) {
  const token = useUserStore((state) => state.auth.token);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/sign-in",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export default PrivateRoute;
