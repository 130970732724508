import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdNaturePeople,
  MdLock,
  MdOutlineShoppingCart,
  MdAppRegistration,
  MdEngineering,
  MdHelpCenter,
  MdOutlineAnalytics,
} from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";

import MainDashboard from "views/admin/default";
import MainPerfil from "views/admin/perfil";

import SignInCentered from "views/auth/signIn";
import SignUp from "views/auth/signUp";
import Confirm from "views/auth/confirm";
import alterarEmail from "views/admin/alterarEmail";
import alterarSenha from "views/admin/alterarSenha";
import Continue from "views/auth/continue";
import MainHelpDesk from "views/admin/helpdesk";
import MenuCadastros from "views/admin/cadastros/components/Menu";
import MenuConfiguracoes from "views/admin/configuracoes/components/Menu";
import MenuPedidos from "views/admin/pedidos/components/Menu";
import PaymentMenu from "views/admin/pagamento/pages/HomePayments";
import ForgotPassword from "views/auth/forgotPassword";
import ResetPassword from './views/auth/resetPassword';

import Store from "views/admin/cadastros/components/stores/Store";
import SellerIndication from "views/admin/indicados/SellerIndication"

const routes = [
  {
    name: "Perfil",
    layout: "/register",
    path: "/",
    icon: (
      <Icon as={MdNaturePeople} width="20px" height="20px" color="inherit" />
    ),
    component: MainPerfil,
  },
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Perfil",
    layout: "/admin",
    path: "/perfil",
    icon: (
      <Icon as={MdNaturePeople} width="20px" height="20px" color="inherit" />
    ),
    component: MainPerfil,
  },{
    name: "Cadastros",
    layout: "/admin",
    path: "/cadastros",
    icon: (
      <Icon as={MdAppRegistration} width="20px" height="20px" color="inherit" />
    ),
    component: MenuCadastros,
    submenus: [{
      name: "Lojas",
      path: "/stores",
      component: Store
    }]
  },
  {
    name: "Pedidos",
    layout: "/admin",
    path: "/pedidos",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: MenuPedidos,
  },
  {
    name: "Pagamentos",
    layout: "/admin",
    path: "/payments",
    icon: (
      <Icon
        as={MdOutlineAnalytics}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: PaymentMenu,
  },
  {
    name: "Indique e ganhe",
    layout: "/admin",
    path: "/indicados",
    icon: (
      <Icon
        //as={MdOutlineShoppingCart}
        as={FaTelegramPlane}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: SellerIndication,
  },

  // {
  //   name: "Pagamentos",
  //   layout: "/admin",
  //   path: "/pagamentos",
  //   icon: (
  //     <Icon as={MdOutlinePayment} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: MenuPagamentos,
  // },
  {
    name: "Configurações",
    layout: "/admin",
    path: "/configuracoes",
    icon: (
      <Icon as={MdEngineering} width="20px" height="20px" color="inherit" />
    ),
    component: MenuConfiguracoes,
  },
  {
    name: "Alterar Email",
    layout: "/admin",
    path: "/alterarEmail",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: alterarEmail,
  },
  {
    name: "Alterar Senha",
    layout: "/admin",
    path: "/alterarSenha",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: alterarSenha,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUp,
  },
  {
    name: "Confirm",
    layout: "/auth",
    path: "/confirm",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Confirm,
  },
  {
    name: "Continue",
    layout: "/auth",
    path: "/continue",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Continue,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
  },
  {
    name: "Suporte",
    layout: "/admin",
    path: "/helpdesk",
    icon: <Icon as={MdHelpCenter} width="20px" height="20px" color="inherit" />,
    component: MainHelpDesk,
    submenus: [{
      name: "Suporte",
      path: "/create",
      component: MainHelpDesk
    }]
  },
]

export default routes;
