import styled from "styled-components";



export const CardContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    @media (max-width: 768px) {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: center;
    }
`

export const CardRow = styled.div`
   
        width: 100%;
`


export const BodyText = styled.div`
   
        width: 100%;
        padding-top: 80px;
        
`

