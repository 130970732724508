import React from "react";
import {
  Box,
  FormLabel,
  Input,
  Select,
  Button,
  Radio,
  RadioGroup,
  Flex,
  FlexProps,
  BoxProps,
  HStack
} from '@chakra-ui/react';
import { getStores } from 'controllers/Stores';
import { Store, StoreResponse } from 'models/Store';
import { useUserStore } from 'store/reducers/user';
import { useForm } from 'react-hook-form';
import { PaymentType, PaymentTypeResponse, TransactionStatus, TransactionStatusResponse } from 'models/Order';
import { getOrderStatus, getPaymentType } from 'utils/order';
import { getPaymentTypes } from 'controllers/PaymentType';
import { getTransactionStatuses } from 'controllers/TransactionStatus';

type OrderFilterFields = {
  store_id: number
  product_name: string
  order_number: string
  shopify_order_number: string
  tracking_code: string
  customer_name: string
  customer_email: string
  payment_method_id: string
  transaction_status_id: string
  start_date: string
  end_date: string
}

type OrderFiltersProps = {
  showFilters: boolean,
  setFilters: Function
}

export default function OrderFilters({ showFilters, setFilters }: OrderFiltersProps) {
  const token = useUserStore((state) => state.auth.token);
  const [stores, setStores] = React.useState<Store[]>([]);
  const [paymentTypes, setPaymentTypes] = React.useState<PaymentType[]>([]);
  const [transactionStatuses, setTransactionStatuses] = React.useState<TransactionStatus[]>([]);

  const flexStyle: FlexProps = {
    direction: {base: "column", md: "row"},
    justify: "space-between"
  }
  const boxStyle: BoxProps = {
    w: {base: "100%", md: "48%"},
    py: "10px"
  }

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue
  } = useForm<OrderFilterFields>(
    {
      defaultValues: {
        tracking_code: "all"
      }
    }
  );
  const trackingCode = watch('tracking_code');

  const handleGetStores = async () => {
    try {
      const response: StoreResponse = await getStores(token);
      setStores(response.data);
    } catch (error: any) {}
  };

  const handleGetPaymentTypes = async () => {
    try {
      const response: PaymentTypeResponse = await getPaymentTypes(token);
      setPaymentTypes(response);
    } catch (error: any) {}
  }

  const handleGetTransactionStatuses = async () => {
    try {
      const response: TransactionStatusResponse = await getTransactionStatuses(token);
      setTransactionStatuses(response);
    } catch (error: any) {}
  }

  const handleFilter = (data: OrderFilterFields) => {
    if (data.tracking_code === "all") {
      delete data["tracking_code"];
    } else {
      data["tracking_code"] = (data["tracking_code"] === "yes").toString();
    }
    setFilters(data);
  }

  const handleReset = () => {
    reset();
    setFilters({});
  }

  React.useEffect(() => {
    handleGetStores();
    handleGetPaymentTypes();
    handleGetTransactionStatuses();
  }, [])

  if (showFilters) {
    return (
      <Box padding="25px">
        <form onSubmit={handleSubmit(handleFilter)}>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="store_id">Loja</FormLabel>
              <Select id="store_id" {...register("store_id")}>
                <option value="">Selecione uma loja</option>
                {
                  stores.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </Select>
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="product_name">Produto</FormLabel>
              <Input
                id="product_name"
                placeholder="Digite um produto"
                {...register("product_name")}
              />
            </Box>
          </Flex>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="order_number">Número do Pedido</FormLabel>
              <Input
                id="order_number"
                type="text"
                placeholder="Digite o número do pedido"
                {...register("order_number")}
              />
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="shopify_order_number">Número do Pedido Shopify</FormLabel>
              <Input
                id="shopify_order_number"
                type="text"
                placeholder="Digite o número do pedido do shopify"
                {...register("shopify_order_number")}
              />
            </Box>
          </Flex>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="customer_name">Nome do Comprador</FormLabel>
              <Input
                id="customer_name"
                placeholder="Digite um nome"
                {...register("customer_name")}
              />
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="customer_email">Email do Comprador</FormLabel>
              <Input
                id="customer_email"
                placeholder="Digite um email"
                {...register("customer_email")}
              />
            </Box>
          </Flex>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="payment_method_id">Forma de Pagamento</FormLabel>
              <Select id="payment_method_id" {...register("payment_method_id")}>
                <option value="">Selecione uma forma de pagamento</option>
                {
                  paymentTypes.map(item => (
                    <option key={item.id} value={item.id}>{getPaymentType(item.name)}</option>
                  ))
                }
              </Select>
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="transaction_status_id">Status</FormLabel>
              <Select id="transaction_status_id" {...register("transaction_status_id")}>
                <option value="">Selecione um status</option>
                {
                  transactionStatuses.map(item => (
                    <option key={item.id} value={item.id}>{getOrderStatus(item.name)}</option>
                  ))
                }
              </Select>
            </Box>
          </Flex>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="start_date">Data de Início</FormLabel>
              <Input
                type="date"
                id="start_date"
                {...register("start_date")}
              />
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="end_date">Data de Fim</FormLabel>
              <Input
                type="date"
                id="end_date"
                {...register("end_date")}
              />
            </Box>
          </Flex>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor='tracking_code'>Com Código de Rastreamento</FormLabel>
              <RadioGroup value={trackingCode} onChange={(nextValue) => {setValue("tracking_code", nextValue)}}>
                <HStack>
                  <Radio value="all">Todos</Radio>
                  <Radio value="yes">Sim</Radio>
                  <Radio value="no">Não</Radio>
                </HStack>
              </RadioGroup>
            </Box>
          </Flex>
          <Button type='submit' colorScheme="purple" margin="5px">Filtrar</Button>
          <Button onClick={handleReset} margin="5px">Limpar</Button>
        </form>
      </Box>
    );
  }

  return (<></>);
}
