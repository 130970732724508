import React from 'react';
import { Box, FormLabel, Input } from '@chakra-ui/react';
import { removeMoneyMask, replaceCommaToDot, replaceDotToComma } from 'utils/formatters';
import { NumericFormat } from 'react-number-format';

type FormInputProps = {
  setValue: Function
  id: string
  label: string
  value: string
  required?: boolean
}

export default function MoneyInput ({ setValue, id, label, value, required = true }: FormInputProps) {
  return (
    <Box marginBottom="14px">
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <NumericFormat
        customInput={Input}
        id={id}
        value={replaceDotToComma(value)}
        onChange={(event) => {
          const value = event.target.value;
          setValue(id, replaceCommaToDot(removeMoneyMask(value)));
        }}
        required={required}
        prefix="R$ "
        allowNegative={false}
        decimalScale={2}
        decimalSeparator=","
        thousandsGroupStyle="thousand"
        thousandSeparator="."
        fixedDecimalScale
      />
    </Box>
  );
};
