import * as React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import axios from "axios";
import { useUserStore } from "store/reducers/user";
import { useHistory } from "react-router-dom";

export default function UserReports() {
  const auth = useUserStore((state) => state.auth);
  const [isLoading, setIsLoading] = React.useState(false);
  const [senha_n, setSenhan] = React.useState("");
  const [senha_c, setSenhac] = React.useState("");
  const logout = useUserStore((state) => state.logout);
  const { push } = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [erro, setErro] = React.useState("");
  const [tituloErro, setTituloErro] = React.useState("");
  const [controle, setControle] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if(senha_n != senha_c){
      setErro("As senhas não conferem!");
      setTituloErro("Ops!");
      onOpen();
      setIsLoading(false);
    }else{
      const formData = new FormData();
      formData.append("id", String(auth.user.id));
      formData.append("oque", "senha");
      formData.append("senha_n", senha_n);
      formData.append("senha_c", senha_c);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/alterar`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth.token}`
            }
          }
        );

        setErro("Sua senha foi alterada com sucesso!");
        setTituloErro("Sucesso!");
        onOpen();
      } catch (error) {
        console.error(error);
        setErro("Houve um erro ao alterar a senha, tente mais tarde!");
        setTituloErro("Ops!");
        onOpen();
        
      } finally {
        // Set isLoading to false to hide the preloader
        setIsLoading(false);
      }
    }
  };

  const handleCloseErro = () => {
    if (controle) {
      handlelogout();
    } else {
      onClose();
    }
  };

  const handlelogout = () => {
    logout();
    push("/auth");
  };

  const renderPreloader = () => {
    return isLoading ? (
      <div className="preloader-overlay">
        <div className="preloader-content">
          <div className="preloader-spinner"></div>
        </div>
      </div>
    ) : null;
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Modal isOpen={isOpen} onClose={handleCloseErro}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{tituloErro}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{erro}</ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleCloseErro}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {renderPreloader()}
      <Flex justify="center">
        <Box
          overflow="auto"
          maxHeight="85vh"
          minHeight="85vh"
          maxWidth="300px"
          style={{ textAlign: "center" }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel htmlFor="senha_n">Nova senha:</FormLabel>
              <Input
                type="password"
                id="senha_n"
                bg="white"
                placeholder="*********"
                value={senha_n}
                onChange={(e) => setSenhan(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="senha_c">Repita a nova senha:</FormLabel>
              <Input
                type="password"
                id="senha_c"
                bg="white"
                placeholder="*********"
                value={senha_c}
                onChange={(e) => setSenhac(e.target.value)}
              />
            </FormControl>

            <Button
              type="submit"
              className="btn black"
              style={{ backgroundColor: "green", color: "#fff" }}
            >
              Atualizar senha
            </Button>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}
