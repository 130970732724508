import styled from "styled-components";

export const HeaderContent = styled.div`
 
  display:none
    width:100%;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    
  }
`;

export const RightContainer = styled.div`
 
display: none;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
  }
`;

export const LeftContainer = styled.div`
  
  display: none;

  @media (max-width: 1024px) {
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}
`;

export const ImageContainer = styled.div`
 
`;