import {
  Box,
  Flex,
  Icon,
  Tag,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {PaymentAdvancedRequest} from "models/PaymentRequests"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {usePaymentRequestStore} from "store/reducers/payments/paymentRequests"
import {useUserStore} from "store/reducers/user/index"
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import React, {useEffect} from "react";
// Assets
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";

type RowObj = {
  bank: string;
  agency: string;
  account: string;
  name: string;
};

const columnHelper = createColumnHelper<PaymentAdvancedRequest>();

// const columns = columnsDataCheck;
export default function PaymentAdvancedTab() {
  
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
  const { paymentRequests, getPaymentRequestByUserId } = usePaymentRequestStore();
  const {auth} = useUserStore();

  useEffect(() => {
    (async () => {
      const paymentRequestResult = await getPaymentRequestByUserId({userId: (auth.user.id).toString()})
      if (paymentRequestResult.success) {
       

        setData(paymentRequestResult.data);
      }
    })() 
  }, []);
  const columns = [
    columnHelper.accessor("type", {
      id: "bank",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Tipo
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() === "anticipation" ? "Antecipação" : "Saque" }
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("amount", {
      id: "agency",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Valor (R$)
        </Text>
      ),
      cell: (info: any) => {
        const amount = (info.getValue() / 100).toFixed(2).toString();

        return (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {amount.padEnd(2, "0")}
          </Text>
        </Flex>
      )},
    }),
    
    columnHelper.accessor("status", {
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Situação
        </Text>
      ),
      cell: (info: any) => {
        return (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() === "approved" ? <Tag colorScheme="green">Aprovado</Tag> : (info.getValue() === "pending" ? <Tag colorScheme="orange">Pendente</Tag> : <Tag colorScheme="red">Reprovado</Tag>)}
          </Text>
        </Flex>
      )},
    }),
    columnHelper.accessor("created_at", {
      id: "account",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Data
        </Text>
      ),
      cell: (info: any) => {
        const date = new Date(info.getValue())

        const day = date.getUTCDate().toString();
        const month = (date.getUTCMonth() + 1).toString();
        const year = date.getUTCFullYear();
        return (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {day.padStart(2, "0")}/{month.padStart(2, "0")}/{year}
          </Text>
        </Flex>
      )},
    }),
  ];
  const [data, setData] = React.useState<Array<PaymentAdvancedRequest>>(() => []);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
