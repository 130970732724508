import React from 'react'
import { Image } from 'models/Product';
import { Box, Text } from '@chakra-ui/react';
import ImageList from 'components/images/ImageList';

type ProductDetailsImagesProps = {
  images: Image[]
}

export default function ProductDetailsImages({ images }: ProductDetailsImagesProps) {
  const padding = "10px 20px";

  return (
    <Box padding={padding}>
      <Text fontSize={{base: "22px", md: "28px"}} fontWeight="bold" mb="10px">
        Imagens
      </Text>
      <ImageList images={images.map((image, idx) => ({
        url: image.src,
        alt: `Imagem ${idx}`
      }))} />
    </Box>
  );
}
