import React from "react";
import { Box, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import Store from "views/admin/cadastros/components/stores/Store";
import Products from "views/admin/cadastros/components/products/Products";
import SocialProof from "./SocialProof";
import Shipping from "./Shipping";
import Cupons from "./Cupons";

export default function MenuCadastros() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        {/* <CheckTable tableData={tableDataCheck} /> */}
        <Tabs isLazy isFitted variant="enclosed">
          <TabList>
            <Tab _selected={{ color: "#00CF79", fontWeight: "bold" }}>
              Loja
            </Tab>
            <Tab _selected={{ color: "#00CF79", fontWeight: "bold" }}>
              Produtos
            </Tab>
            <Tab _selected={{ color: "#00CF79", fontWeight: "bold" }}>
              Frete
            </Tab>
            <Tab _selected={{ color: "#00CF79", fontWeight: "bold" }}>
              Prova Social
            </Tab>
            <Tab _selected={{ color: "#00CF79", fontWeight: "bold" }}>
              Cupom de Desconto
            </Tab>
            {/* <Tab _selected={{ color: "#00CF79", fontWeight: "bold" }}>
              Usuários
            </Tab>
            <Tab _selected={{ color: "#00CF79", fontWeight: "bold" }}>
              Domínios
            </Tab> */}
          </TabList>

          <TabPanels>
            <TabPanel>
              <Store />
            </TabPanel>
            <TabPanel>
              <Products />
            </TabPanel>
            <TabPanel>
              <Shipping />
            </TabPanel>
            <TabPanel>
              <SocialProof />
            </TabPanel>
            <TabPanel>
              <Cupons />
            </TabPanel>
            {/* <TabPanel>
              <Users />
            </TabPanel>
            <TabPanel>
              <Domains />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </SimpleGrid>
    </Box>
  );

}
