import styled from "styled-components";



export const CardContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    @media (max-width: 768px) {
        justify-content: space-around;
        align-items: center;
        text-align: center;
    }
`

export const CardRow = styled.div`
   
        width: 100%;
`