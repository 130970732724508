import React from 'react'
import { Box, Button, Flex, FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
import { Order } from 'models/Order';
import DetailsText from 'components/details/DetailsText';
import { useForm } from 'react-hook-form';
import { updateTrackingCode } from 'controllers/Orders';
import { useUserStore } from 'store/reducers/user';
import LoadingPage from 'components/Loading/LoadingPage';
import { AlertModal, AlertModel } from 'components/modal/AlertModal';

type TrackingCodeProps = {
  order: Order,
  setOrder: Function
}

type UpdateTrackingCodeForm = {
  tracking_code: string,
  tracking_url: string
}

export default function TrackingCode({ order, setOrder }: TrackingCodeProps) {
  const token = useUserStore((state) => state.auth.token);

  const [updating, setUpdating] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<AlertModel>(null);

  const { register, handleSubmit } = useForm<UpdateTrackingCodeForm>(
    { defaultValues: { tracking_code: order.tracking_code, tracking_url: order.tracking_url } }
  )

  const handleUpdateTrackingCode = async (data: UpdateTrackingCodeForm) => {
    try {
      setLoading(true);
      await updateTrackingCode(order.id, data.tracking_code, data.tracking_url, token);
      setOrder({
        ...order,
        tracking_code: data.tracking_code,
        tracking_url: data.tracking_url
      });
      setUpdating(false);
    } catch (e) {
      setError({
        type: "error",
        text: "Erro ao atualizar código de rastreamento."
      })
    } finally {
      setLoading(false);
    }
  }

  const render = () => {
    if (updating) {
      return (
        <FormControl maxWidth="300px">
          <FormLabel htmlFor="tracking_code">Código de Rastreamento *</FormLabel>
          <Input
            mb="10px"
            id="tracking_code"
            name="tracking_code"
            {...register('tracking_code', { required: true })}
          />

          <FormLabel htmlFor="tracking_url">Url de Rastreamento *</FormLabel>
          <Input
            id="tracking_url"
            name="tracking_url"
            {...register('tracking_url', { required: true })}
          />
          <Text mt="10px" fontSize="12px" fontStyle="italic">Obs: Ao adicionar o código de rastreamento, o comprador receberá um email com as informações de rastreio.</Text>
          <Button my="10px" size="md" colorScheme='purple' onClick={handleSubmit(handleUpdateTrackingCode)}>
            Salvar
          </Button>
        </FormControl>
      )
    }
    if (!order.tracking_code) {
      return (
        <Button my="10px" size="md" colorScheme='purple' onClick={() => setUpdating(true)}>
          Adicionar Rastreamento
        </Button>
      )
    }
    return (
      <>
        <Flex justify="space-between" direction={{ base: 'column', md: 'row' }}>
          <Box>
            <DetailsText title="Código" value={order.tracking_code || '-'} />
            <DetailsText title="Url de Rastreamento" value={order.tracking_url  || '-'} />
          </Box>
          <Button my="10px" size="md" colorScheme="green" onClick={() => setUpdating(true)}>
            Atualizar
          </Button>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Box>
        <LoadingPage isOpen={loading} />
        {error && (
          <Box mb="10px">
            <AlertModal alert={error} setAlert={setError} />
          </Box>
        )}
        {render()}
      </Box>
    </>
  );
}
