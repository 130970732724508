import React from 'react';
import { Tag } from '@chakra-ui/react';

type StatusTagProps = {
  status: boolean
}

export default function StatusTag ({ status }: StatusTagProps) {
  return (
    <Tag
      backgroundColor={status ? "#01B574" : "#EE5D50"}
      color="white"
      fontWeight="bold"
    >
      {status ? "Ativa" : "Inativa"}
    </Tag>
  );
};
