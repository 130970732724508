import React, { useState, useEffect } from "react";
import BankingStep from "../components/Banking/BankingAccountForm"
import { usePerfilPage } from "../store/index";
import { Container, Body, Row, RowFooterButton, SecondPageBody } from "../styles"
import {
  Button,
  Collapse,
  SlideFade
} from "@chakra-ui/react";
import { useUserStore } from "store/reducers/user";
import BankingAccountForm from "../components/Banking/BankingAccountForm";
import { UpdateBankingAccount, UpdatePersonalDoc, UpdateLegaEntityDoc } from "models/User"
import DocumentStep2 from "../components/PersonalDocumentsStep"
import DocumentStep from "../components/Documents/DocumentStep"
import { updateBankingAccount, updateLegalEntity } from "services/userRequests";
import { updateOnboardingStep } from "services/userRequests";
import { CustomButton } from "styleguide/Button"
import { updatePersonalDoc } from "services/userRequests"
import { toastError, toastSuccess } from "styleguide/Toastify";
const ThirdStep = () => {
  const [updateEntityData, user, updateUserOnboardingStep, updateBankingAccountData, updateDocumentsData] = useUserStore(state => [state.updateEntityData, state.user, state.updateUserOnboardingStep, state.updateBankingAccount, state.updateDocumentsData]);
  const { onboardingPage, hasCnpj, setHasCnpj, setOnBoardingPage } = usePerfilPage();
  const [isValidForm, setIsValidForm] = useState(false);
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFade(true)
  }, [])
  type FormInfoType = UpdateBankingAccount.BankingAccount | UpdatePersonalDoc.Documents | null;
  const [formInfo, setFormInfo] = useState<FormInfoType>(null);

  const handlePreviousStep = () => {
    setFade(false)
    setTimeout(() => {
      setOnBoardingPage(1)
    }, 350)

  }

  const handleNextStep = async () => {
    
    try {


      if (hasCnpj && 'agencia' in formInfo) {
        const data = await updateBankingAccount({
          holder_name: user.name,
          agencia: formInfo.agencia,
          banco_id: formInfo.banco_id,
          conta: formInfo.conta,
          n_pix: formInfo.n_pix
        })

        if (!data.success) {
          toastError("Erro ao salvar documento")
          return
        }
        updateBankingAccountData(data.data);
      } else if ('foto_doc_pessoal_frente' in formInfo) {
        const data = {
          foto_doc_pessoal_frente: formInfo?.foto_doc_pessoal_frente,
          foto_doc_pessoal_verso: formInfo?.foto_doc_pessoal_verso,
          foto_rosto_frente: formInfo?.foto_rosto_frente,
          comprovante_endereco: formInfo?.comprovante_endereco
        }
        updateDocumentsData(data);
      }

      if (onboardingPage == 2) {

        const response = await updateOnboardingStep({
          onboarding_step: hasCnpj ? 3 : 4
        });

        if (response.success) {
          setFade(false)
          toastSuccess("Documentos registrados com sucesso")
          setTimeout(() => {
            if (hasCnpj) {
              setOnBoardingPage(3)
              
            } else {
              setOnBoardingPage(4)
             
            }
          }, 350)

        }

      } else {
        setFade(false)
        setTimeout(() => {
          if (hasCnpj) {
            setOnBoardingPage(3)
          } else {
            setOnBoardingPage(4)
          }
        }, 350)

      }
    } catch (err) {
      toastError("Encontramos um erro. Tente novamente mais tarde ou contate o suporte");
      console.log({ err });
    }
  }

  const handleSkipStep = () => {
    setFade(false)
    setTimeout(() => {
      if (hasCnpj) {
        setOnBoardingPage(3)
      } else {
        setOnBoardingPage(4)
      }
    }, 350)

  }

  return (
    <React.Fragment>
      <SlideFade unmountOnExit={true} in={fade} offsetY='50px' reverse={true}>
        <Container>
          <Row>
            {!hasCnpj ?
              <React.Fragment>
                <Row>
                  <DocumentStep setFormIsValid={setIsValidForm} setFormInfo={setFormInfo} />
                </Row>
              </React.Fragment>
              :

              <Row>
                <BankingAccountForm setFormIsValid={setIsValidForm} setFormInfo={setFormInfo} />
              </Row>

            }
          </Row>
          <RowFooterButton >
            <CustomButton
              isFullWidth={false}
              variant={"tertiary"}
              onClick={handlePreviousStep}
            >
              Voltar
            </CustomButton>
            <CustomButton
              isFullWidth={false}
              disabled={!isValidForm}
              variant={"primary"}
              onClick={handleNextStep}
            >
              Salvar e Avançar
            </CustomButton>
            {
              user.onboarding_step > 2 &&
              <CustomButton
                isFullWidth={false}
                variant={"secondary"}
                onClick={handleSkipStep}
              >
                Próximo
              </CustomButton>
            }
          </RowFooterButton>
        </Container>
      </SlideFade>
    </React.Fragment>
  )
}


export default ThirdStep;