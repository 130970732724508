import React from 'react'
import { Image, Variation } from 'models/Product';
import { Box, Flex, Text } from '@chakra-ui/react';
import ImageList from 'components/images/ImageList';
import DetailsText from 'components/details/DetailsText';
import CopyField from 'components/fields/CopyField';

type ProductDetailsVariationsProps = {
  variations: Variation[],
  images: Image[]
}

export default function ProductDetailsVariations({ variations, images }: ProductDetailsVariationsProps) {
  const padding = "10px 20px";

  return (
    <Box padding={padding}>
      <Text fontSize={{base: "22px", md: "28px"}} fontWeight="bold" mb="10px">
        Variações
      </Text>
      <Flex py={padding} flexWrap="wrap" my="10px" gap={4}>
        {variations.map((variation: Variation, index: number) => (
          <Flex
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            justifyContent="space-between"
            flexWrap="wrap"
            w="400px"
            p="14px"
          >
            <Box w={{base: "100%", md: "49%"}}>
              <Text as="p" fontSize={{base: "20px", md: "22px"}} fontWeight="bold" mb={2}>
                {variation.name}
              </Text>
              <DetailsText title={"Preço"} value={`R$ ${variation.price}`} />
              {variation.checkout_url && (
                <DetailsText title={"Url do Checkout"} value={
                  <CopyField text={variation.checkout_url} onlyIcon />
                } />
              )}
            </Box>
            <Flex w={{base: "100%", md: "39%"}}>
              <Box width={150}>
                <ImageList
                  images={
                    images.filter(image => image.id === variation.image_id).map(image => ({
                      url: image.src,
                      alt: `Imagem da variação ${variation.name}`
                    }))
                  }
                  width={150}
                  height={150}
                />
              </Box>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
