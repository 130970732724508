import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Checkbox,
  Flex,
  Text,
} from "@chakra-ui/react";

import { useUserStore } from 'store/reducers/user';
import axios from "axios";
import { CustomButton } from "styleguide/Button"
import { H1 } from "components/typograph";
import {changeRegistration} from "services/userRequests"

const FinishStep = () => {
  const [auth, updateStatusRegistration] = useUserStore((state) => [state.auth, state.updateStatusRegistration]);
  const changeRegistrationRequest = async () => {
    const response = await changeRegistration()

    response && updateStatusRegistration('Pedir')
  }

  return (
    <Box style={{
      backgroundColor: "#fff",
      flex: "1",
      alignItems: "center",
      padding: "10px",
     
    }}

    >
      <H1>Aguardando análise</H1>
      <CustomButton
        isFullWidth={true}
        variant={"primary"}
        type="submit"
        className="btn black"
       
      onClick={changeRegistrationRequest}
      >
        Quero alterar meus dados
      </CustomButton>
    </Box>

  )
}

export default FinishStep;