import { toast, ToastOptions } from "react-toastify";

const toastConfig: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
}

export const notifyError = (message: string) => {
  toast.error(message, toastConfig);
}

export const notifyAlert = (message: string) => {
  toast.warn(message, toastConfig);
}

export const notifySuccess = (message: string) => {
  toast.success(message, toastConfig);
}

export const notifyInfo = (message: string) => {
  toast.info(message, toastConfig);
}
