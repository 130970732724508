import React, {useState, useEffect} from "react";
import {
    Box,
    FormControl,
    FormLabel,
    Button,
    Checkbox,
    Flex,
    Text,
  } from "@chakra-ui/react";
  import { usePerfilPage } from "../../store";
  import { useUserStore } from 'store/reducers/user';
  import axios from "axios";
  import { getMembershipPlans, createContract, completeRegistration } from "services/userRequests";
import { H1, H6 } from "components/typograph";
import { CustomButton } from "styleguide/Button"
  const FinishStep = () => {
    const { onboardingPage, hasCnpj, setHasCnpj, setOnBoardingPage } = usePerfilPage();
    const [auth, updateStatusRegistration] = useUserStore((state) => [state.auth, state.updateStatusRegistration]);
     const [loading, setLoading] = useState(true);
     const [relacionamento, setRelacionamento] = useState(null);
    const user = useUserStore((state) => state.user);

    useEffect(() => {
      const relacionamentoDecoded = JSON.parse(user.relacionamento).reverse()[0];
      const items = relacionamentoDecoded.descricao.split(", ");
      const listItems = items.map((item, index) => <H6 key={index}>{`${index + 1}. ${item}`}<br/></H6>);


      setRelacionamento(listItems)
      setLoading(false);
    }, [])

    const sendDataToCompleteRegister = async () => {
      try {
        const registration = await completeRegistration();

        if (registration) updateStatusRegistration('Aguardando')
       
      } catch (err) {
        err
      }
    }

    const handleFormSubmitAba5pular = () => {

      if(hasCnpj) {
        setOnBoardingPage(3)  
      } else {
  
        setOnBoardingPage(2)
      }
  
    }

     if(loading) {
      return (<label>Carregando....</label>)
     } else {
      return (
        <Box style={{
            backgroundColor: "#fff",
            flex: "1",
            alignItems: "center",
            padding: "10px"
          }} 
          
          >
             <H1>Seus dados estão pendentes de correção conforme texto abaixo:</H1>
             <Box>{relacionamento}</Box>
             <CustomButton
              isFullWidth={false}
              variant={"tertiary"}
        onClick={handleFormSubmitAba5pular}
      >
        Voltar
      </CustomButton>
      <CustomButton
              isFullWidth={false}
              variant={"primary"}
        onClick={sendDataToCompleteRegister}
      >
        Enviar dados para análise
      </CustomButton>
        </Box>

    )
     }
   
}

export default FinishStep;