import React from 'react';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

type StarsInputProps = {
  value: number,
  setValue: Function
}

export default function StarsInput ({ value, setValue }: StarsInputProps)  {
  const renderStars = () => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <Box
          key={i}
          onClick={() => setValue(i)}
          color={i <= value ? 'yellow.400' : 'gray.200'}
          cursor="pointer"
        >
          <Icon as={AiFillStar} width="20px" height="auto" />
        </Box>
      );
    }

    return stars;
  };

  return <Flex>{renderStars()}</Flex>;
};
