import React from "react";
import {Button, Icon} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons";
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';

import IconButton from "components/iconButton"
import { SidebarResponsive } from "components/sidebar/Sidebar";
import routes from "routes";
import {useUserStore} from "store/reducers/user"
import {HeaderContent,ImageContainer,LeftContainer,RightContainer} from "./styles/mobileStyles"
import lmexpay_logo from '../../../assets/img/lmexpay_logo.png';

const DesktopHeader = () => {
    const auth = useUserStore((state) => state.auth);
    return (
        <React.Fragment>
            <HeaderContent>
                <LeftContainer>
                    {auth.user.complete_register &&
                    <SidebarResponsive routes={routes} /> 
}  
                    {/* <ImageContainer>
                            <img src="/dubpay-logo-white.png" alt="Logo" style={{maxHeight: '60%', maxWidth: '100%', objectFit: 'contain'}}/>
                    </ImageContainer> */}
                    {/* <IconButton icon={HamburgerIcon}/> */}
                </LeftContainer>
                <LeftContainer>
                    
                    <ImageContainer>
                            <img src={lmexpay_logo} alt="Logo" style={{maxHeight: '60%', maxWidth: '180px', objectFit: 'contain'}}/>
                    </ImageContainer>
                    
                </LeftContainer>
                <RightContainer>
                    <AdminNavbarLinks/>
                </RightContainer>
            </HeaderContent>
      
				
					
				
                </React.Fragment>
    )
}

export default DesktopHeader