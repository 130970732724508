import React from 'react';
import {H1Style, H2Style, H3Style, H4Style, H5Style, H6Style, SubtitleStyle} from "./styles";

export const H1 = (props) => {
    return (
        <H1Style>
            {props.children}
        </H1Style>
    )
}

export const H2 = (props) => {
    return (
        <H2Style>
            {props.children}
        </H2Style>
    )
}
export const H3 = (props) => {
    return (
        <H3Style>
            {props.children}
        </H3Style>
    )
}
export const H4 = (props) => {
    return (
        <H4Style>
            {props.children}
        </H4Style>
    )
}
export const H5 = (props) => {
    return (
        <H5Style>
            {props.children}
        </H5Style>
    )
}
export const H6 = (props) => {
    return (
        <H6Style>
            {props.children}
        </H6Style>
    )
}

export const Subtitle = (props) => {
    return (
        <SubtitleStyle>
            {props.children}
        </SubtitleStyle>
    )
}