import React from "react";
import { Box, Button, SimpleGrid } from "@chakra-ui/react";
import CheckTable from "./CheckTablePedidos";
import { useUserStore } from "store/reducers/user";
import { getOrders } from "controllers/Orders";
import { Order } from "models/Order";
import LoadingPage from "components/Loading/LoadingPage";
import OrderDetails from './OrderDetails';
import { useQuery } from '@tanstack/react-query';

export default function Pedidos() {
  const token = useUserStore((state) => state.auth.token);
  const [page, setPage] = React.useState<number>(1);
  const [filters, setFilters] = React.useState({})
  const [details, setDetails] = React.useState<Order>(null);

  const { data: ordersData, isLoading, refetch } = useQuery(
    ["orders", page, token, filters],
    () => getOrders(page, token, filters),
    {
      onError: (error) => {
        console.log(error);
      }
    }
  );

  const showDetails = (order: Order) => {
    setDetails(order);
  }

  return (
    <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
      {details ? (
        <>
          <Button onClick={() => setDetails(null)}>
            Voltar
          </Button>
          <OrderDetails order={details} setOrder={(order: Order) => {
            setDetails(order);
            refetch();
          }} />
        </>
      ) : (
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <CheckTable
              tableData={ordersData}
              tableName="Pedidos"
              setFilters={setFilters}
              showDetails={showDetails}
              page={page}
              setPage={setPage}
            />
          </SimpleGrid>
      )}

      <LoadingPage isOpen={isLoading || ordersData === undefined} />
    </Box>
  );
}
