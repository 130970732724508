import { Response } from './Request';

export interface StoreResponse extends Response<Store[]> {}

export interface Store {
  id: number;
  active: boolean;
  api_key: string;
  api_password: string;
  description: string;
  email: string;
  name: string;
  shared_secret: string;
  site: string;
  status: string;
  support_phone: string;
  url: string;
  user: User;
  type: StoreType;
  checkout_type: StoreCheckoutType;
  uuid: string;
}

export enum StoreType {
  LMEXPAY = 'lmexpay',
  SHOPIFY = 'shopify'
}

export enum StoreCheckoutType {
  SINGLE_PRODUCT = 'single_product',
  MULTI_PRODUCT = 'multi_product',
}

export interface User {
  id: number;
  name: string;
  email: string;
  remember_token: any;
  created_at: string;
  updated_at: string;
  role_id: number;
}

export interface CreateStoreRequest {
  name: string;
  email: string;
  description: string;
  site: string;
  support_phone: string;
  type: StoreType;
  checkout_type: StoreCheckoutType;
  api_key?: string;
  api_password?: string;
  shared_secret?: string;
  url?: string;
}
