import { CreateStoreRequest, StoreResponse } from "models/Store";
import axios from 'axios';
import { axiosConfig, getHeader } from 'controllers/config';
import { notifyError } from 'utils/notification';

const storeApi = axios.create({
  ...axiosConfig,
  baseURL: axiosConfig.baseURL + 'stores'
})

export async function getStores(token: string, page: number = null) {
  try {
    let url = page ? `?page=${page}` : "?paginate=false";

    const response = await storeApi.get<StoreResponse>(
      url,
      {
        headers: getHeader()
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer as lojas cadastradas.")
  }
}

export async function getUserStores(page: number = null) {
  try {
    let url = page ? `?page=${page}` : "?paginate=false";

    const response = await storeApi.get<StoreResponse>(
      url,
      {
        headers: getHeader()
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer as lojas cadastradas.")
  }
}

export async function createStore(data: CreateStoreRequest): Promise<StoreResponse> {
  try {
    const response = await storeApi.post<StoreResponse>(
      "",
      data,
      {
        headers: getHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Ops! Tivemos um problema ao criar sua nova loja.");
    }
  }
}

export async function updateStore(id: number, data: CreateStoreRequest): Promise<StoreResponse> {
  try {
    const response = await storeApi.put<StoreResponse>(
      `${id}`,
      data,
      {
        headers: getHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Ops! Tivemos um problema ao atualizar sua nova loja.");
    }
  }
}

export async function deleteStore(id: number) {
  await storeApi.delete(
    `${id}`,
    {
      headers: getHeader(),
    }
  );
}

export async function reinstallCheckout(id: number) {
  try {
    const response = await storeApi.post(
      `reinstall-theme/${id}`,
      {},
      {
        headers: getHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Ops! Tivemos um problema ao reinstalar o checkout.");
    }
  }
}
