import React, {useState} from 'react';
import {InputStyles} from "./styles";



export const CustomSelect = ({children, ...rest}) => {

    const [inputType, setInputType] = useState(rest.type);

    const toggleInputType = () => {
        setInputType(inputType === 'password' ? 'text' : 'password');
      };

  return <InputStyles {...rest}>{children} </InputStyles>;
};

