import React from "react";
import { Box, Button, SimpleGrid, } from "@chakra-ui/react";
import { useQuery } from '@tanstack/react-query';
import LoadingPage from 'components/Loading/LoadingPage';
import { DeleteModal } from 'components/modal/DeleteModal';
import { notifyError } from 'utils/notification';
import { deleteMemberAreaClassroom, getMemberAreaClassrooms } from 'controllers/MemberArea';
import { MemberAreaClassroom } from 'models/MemberAreaClassroom';
import CheckTableMemberArea from 'views/admin/configuracoes/components/MemberArea/CheckTableMemberArea';
import MemberAreaDetails from 'views/admin/configuracoes/components/MemberArea/MemberAreaDetails';
import MemberAreaForm from 'views/admin/configuracoes/components/MemberArea/MemberAreaForm';

export default function MemberArea() {
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [selected, setSelected] = React.useState<MemberAreaClassroom>(null);
  const [details, setDetails] = React.useState<MemberAreaClassroom>(null);
  const [show, setShow] = React.useState(false);
  const [confirmDeletion, setConfirmDeletion] = React.useState<number>(null);

  const { data: memberAreasData, isLoading, refetch } = useQuery(
    ["memberAreas", page],
    () => getMemberAreaClassrooms(page),
    {
      onError: (error) => {
        notifyError('Erro ao listar as áreas de membros. Recarregue a página!');
      }
    }
  );

  const switchShow = () => {
    setDetails(null);
    setSelected(null);
    setShow(!show);
  }

  const select = (memberAreaClassroom: MemberAreaClassroom) => {
    setSelected(memberAreaClassroom);
    setShow(true);
  }

  const showDetails = (memberAreaClassroom: MemberAreaClassroom) => {
    setDetails(memberAreaClassroom);
    setShow(true);
  }

  const requestDelete = (memberAreaClassroom: MemberAreaClassroom) => {
    setConfirmDeletion(memberAreaClassroom.id);
  }

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteMemberAreaClassroom(id);
      await refetch();
    } catch (error) {
      notifyError('Erro ao deletar área de membros.')
    } finally {
      setConfirmDeletion(null);
      setLoading(false);
    }
  }

  const render = () => {
    if (!show) {
      return (
        <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <CheckTableMemberArea
              tableData={memberAreasData}
              tableName="Integrações com Área de Membros"
              select={select}
              page={page}
              setPage={setPage}
              showDetails={showDetails}
              requestDelete={requestDelete}
            />
          </SimpleGrid>
        </Box>
      );
    }

    if (details) {
      return (
        <MemberAreaDetails memberAreaClassroom={details}/>
      )
    } else {
      return (
        <MemberAreaForm
          switchShow={switchShow}
          show={show}
          refetch={refetch}
          selected={selected}
        />
      )
    }
  }

  return (
    <>
      <LoadingPage isOpen={isLoading || loading} />
      <Button onClick={switchShow}>{show ? "Voltar" : "Nova Integração"}</Button>

      {render()}

      <DeleteModal
        title="Remover Integração?"
        isOpen={confirmDeletion != null}
        onClose={() => setConfirmDeletion(null)}
        handleDelete={() => handleDelete(confirmDeletion)}
      />
    </>
  );
}
