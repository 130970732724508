import React from "react";
import { Box, Text, Input, Flex, InputGroup, InputRightElement, Button } from "@chakra-ui/react";
import SakIntegration from "./SakIntegration"
import ReportanaIntegration from "./ReportanaIntegration";
import { useMutation, useQuery } from '@tanstack/react-query';
import { generateApiToken, getApiToken } from 'services/userRequests';
import LoadingPage from 'components/Loading/LoadingPage';
import { notifyError } from 'utils/notification';

const IntegrationsConfig = () => {
    const { data: apiToken, isLoading: isLoadingApiToken, refetch } = useQuery(
        ['getUserApiToken'],
        getApiToken,
    );
    const { mutateAsync: generateApiTokenRequest } = useMutation({
        mutationFn: generateApiToken,
    });

    const [showApiToken, setShowApiToken] = React.useState<boolean>(false);

    const handleGenerateApiToken = async () => {
        try {
            await generateApiTokenRequest();
            await refetch();
        } catch (error) {
            notifyError('Erro ao gerar token');
        }
    }

    return (
        <Flex direction={'column'} gap={"20px"}>
            <LoadingPage isOpen={isLoadingApiToken} />
            <Flex direction={'column'} gap={"20px"}>
                <Box>
                    <Text fontSize={"20px"} fontWeight={"bold"}>Api Token</Text>
                </Box>
                <Flex>
                    {apiToken && (
                        <InputGroup w="900px" mr="10px">
                            <Input value={apiToken} type={showApiToken ? 'text' : 'password'} />
                            <InputRightElement w="100px">
                                <Button colorScheme="purple" onClick={() => setShowApiToken(!showApiToken)} w="100px" h="1.7rem" borderRadius="6px" mr="5px">
                                    {showApiToken ? 'Esconder' : 'Mostrar'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    )}
                    <Button colorScheme="purple" onClick={handleGenerateApiToken}>
                        Gerar Novo Token
                    </Button>
                </Flex>
            </Flex>
            <Flex direction={'column'} gap={"20px"}>
                <Box>
                    <Text fontSize={"20px"} fontWeight={"bold"}>Integrações</Text>
                </Box>
                <Box>
                    <ReportanaIntegration />
                </Box>
                <Box>
                    <SakIntegration />
                </Box>
            </Flex>
        </Flex>
    )
}

export default IntegrationsConfig;
