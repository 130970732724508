import { useUserStore } from 'store/reducers/user';
import React from 'react';
import { useForm } from 'react-hook-form';
import { getStores } from 'controllers/Stores';
import LoadingPage from 'components/Loading/LoadingPage';
import { NumericFormat } from 'react-number-format';
import {
  Box,
  Button, Flex,
  FormControl,
  FormLabel,
  Input,
  Select, Switch,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { CouponModel, CreateCouponRequest } from 'models/Coupon';
import { createCoupon, updateCoupon } from 'controllers/Coupons';
import { useQuery } from '@tanstack/react-query';
import { AlertModal, AlertModel } from 'components/modal/AlertModal';
import { removeMoneyMask, removePercentageMask } from 'utils/formatters';

type CouponFormProps = {
  switchShow: Function
  show: boolean
  selected?: CouponModel
  refetch: Function
}

export default function CouponForm({ switchShow, show, refetch, selected = null }: CouponFormProps) {
  const token = useUserStore((state) => state.auth.token);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState<AlertModel>(null);

  const defaultValues = selected ? {
    active: selected.active,
    kind: selected.kind,
    percentage: selected.percentage,
    amount: selected.amount?.replace('.', ','),
    description: selected.description,
    name: selected.name,
    code: selected.code,
    started_at: selected.started_at,
    finished_at: selected.finished_at,
    store_id: selected.store.id,
  } : {
    active: true,
    kind: 'percentage',
  }

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm<CreateCouponRequest>({ defaultValues });
  const activeValue = watch('active');
  const kindValue = watch('kind');
  const amountValue = watch('amount');
  const percentageValue = watch('percentage');

  const handleSave = async (data: any) => {
    const updatedAmount = data.amount?.replace(',', '.');

    if (selected) {
      await handleUpdate({
        ...data,
        amount: updatedAmount,
      });
    } else {
      await handleCreate({
        ...data,
        amount: updatedAmount,
      });
    }
  }

  const handleCreate = async (data: CreateCouponRequest) => {
    setLoading(true);
    try {
      await createCoupon(data, token);
      refetch();
      switchShow();
    } catch (error) {
      setAlert({
        text: 'Erro ao criar o seu cupom. Tente Novamente!',
        type: 'error',
      })
    } finally {
      setLoading(false);
    }
  }

  const handleUpdate = async (data: CreateCouponRequest) => {
    setLoading(true);
    try {
      await updateCoupon(selected.id, data, token);
      refetch();
      switchShow();
    } catch (error) {
      setAlert({
        text: 'Erro ao atualizar o seu cupom. Tente Novamente!',
        type: 'error',
      })
    } finally {
      setLoading(false);
    }
  }

  const { data: storeData, isLoading } = useQuery(
    ["getAllStores"],
    () => getStores(token),
    {
      onError: (error) => {
        setAlert({
          text: 'Erro ao buscar lojas. Recarregue a página',
          type: 'error',
        })
      }
    }
  );

  React.useEffect(() => {
    if (selected && storeData) {
      setValue('store_id', selected.store.id);
    }
  }, [storeData]);

  React.useEffect(() => {
    reset();
  }, [show]);

  return (
    <>
      <LoadingPage isOpen={loading} />
      {alert && (
        <Box mt="30px">
          <AlertModal alert={alert} setAlert={setAlert}/>
        </Box>
      )}
      <Card flexDirection='column' marginTop={'10px'} w='100%' px='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Box pt="20px">
          <form onSubmit={handleSubmit(handleSave)}>
            <FormControl rowGap="20px">
              <FormLabel htmlFor="name">Nome</FormLabel>
              <Input id="name" type="text" {...register("name", { required: true })} />

              <br/>
              <br/>
              <FormLabel htmlFor="description">Descrição</FormLabel>
              <Input id="description" type="text" {...register("description", { required: true })} />

              <br/>
              <br/>
              <FormLabel htmlFor="description">Código</FormLabel>
              <Input id="code" type="text" {...register("code", { required: true })} />

              <br/>
              <br/>
              <Flex direction={{ base: "column", md: "row" }} gap={4} justifyContent="space-between">
                <Box w={{ base: "100%", md: "49%" }}>
                  <FormLabel htmlFor="type">Tipo</FormLabel>
                  <Select id="type" {...register("kind", { required: true })}>
                    <option value="value">R$</option>
                    <option value="percentage">%</option>
                  </Select>
                </Box>
                <Box w={{ base: "100%", md: "49%" }}>
                  <Box display={kindValue === "percentage" ? "block" : "none"}>
                    <FormLabel htmlFor="percentage">Porcentagem</FormLabel>
                    <NumericFormat
                      customInput={Input}
                      id="percentage"
                      value={percentageValue}
                      onChange={(event) => {
                        const value = event.target.value;
                        setValue('percentage', removePercentageMask(value));
                      }}
                      isAllowed={(values) => {
                        const { floatValue } = values;
                        return floatValue === undefined || floatValue <= 100;
                      }}
                      required={kindValue === "percentage"}
                      suffix="%"
                      decimalScale={0}
                      allowNegative={false}
                    />
                  </Box>
                  <Box display={kindValue === "value" ? "block" : "none"}>
                    <FormLabel htmlFor="amount">Valor</FormLabel>
                    <NumericFormat
                      customInput={Input}
                      id="amount"
                      value={amountValue}
                      onChange={(event) => {
                        const value = event.target.value;
                        setValue('amount', removeMoneyMask(value));
                      }}
                      required={kindValue === "value"}
                      prefix="R$ "
                      allowNegative={false}
                      decimalScale={2}
                      decimalSeparator=","
                      thousandsGroupStyle="thousand"
                      thousandSeparator="."
                      fixedDecimalScale
                    />
                  </Box>
                </Box>
              </Flex>

              <br />
              <Flex direction={{ base: "column", md: "row" }} gap={4} justifyContent="space-between">
                <Box w={{ base: "100%", md: "49%" }}>
                  <FormLabel htmlFor="started_at">Data de Início</FormLabel>
                  <Input id="started_at" type="date" {...register("started_at", { required: true })}/>
                </Box>
                <Box w={{ base: "100%", md: "49%" }}>
                  <FormLabel htmlFor="finished_at">Data de Término</FormLabel>
                  <Input id="finished_at" type="date" {...register("finished_at", { required: true })}/>
                </Box>
              </Flex>

              <br/>
              <FormLabel htmlFor="store_id">Loja *</FormLabel>
              <Select id="store_id" {...register("store_id", { required: true })}>
                <option value="">Selecione uma loja</option>
                {
                  storeData?.data.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </Select>

              <br/>
              <FormLabel htmlFor='active'>Ativo</FormLabel>
              <Switch
                id='active'
                isChecked={activeValue}
                onChange={() => {
                  setValue('active', !activeValue)
                }}
              />

              <br />
              <br />
              <Button type='submit'>Salvar</Button>
              <Button onClick={() => switchShow()}>Voltar</Button>
            </FormControl>
          </form>
        </Box>
      </Card>
    </>
  );
}
