import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useProductStore } from "../products";
import {UserData} from "models/User"
import {UpdateLegaEntity, UpdateBankingAccount, UpdateLegaEntityDoc, UpdatePersonalDoc} from "models/User"
interface IUser {
  id: number;
  name: string;
  email: string;
  activated: boolean;
  complete_register:boolean;
  role: { id: number; name: string };
  contractId: number;
  membershipPlanId: number;
  onboarding_step: number
}

interface IAuthentication {
  auth: {
    user?: IUser;
    token?: string;
    tokenShopify?: string;
  };
  user?: Partial<UserData>,
  updateContractId: (contractId: number) => void;
  updateMembershipPlanId: (membershipPlanId: number) => void;
  getToken: () => string;
  updateToken: (newToken: string) => void;
  updateUser: (user: UserData) => void;
  updateTokenShopify: (newTokenShopify: string) => void;
  logout: () => void;
  updateEntityData: (entity: UpdateLegaEntity.Body) => void;
  updateStatusRegistration: (value: string) => void;
  updateDocumentsData: (documents: UpdateLegaEntityDoc.Documents & UpdatePersonalDoc.Documents) => void;
 }

const clearProductsData = useProductStore.getState().clearProductsData;

export const useUserStore = create<IAuthentication>()(
  persist(
    (set) => ({
      auth: {},
      user: {},
      updateContractId: (contractId: number) =>
        set((state) => ({
          ...state,
          auth: {
            ...state.auth,
            user: { ...state.auth.user, contractId: contractId },
          },
        })),
      updateMembershipPlanId: (membershipPlanId: number) =>
        set((state) => ({
          ...state,
          auth: {
            ...state.auth,
            user: { ...state.auth.user, membershipPlanId: membershipPlanId },
          },
        })),
      getToken: () => {
        return useUserStore.auth.token
      }
         ,
      updateToken: (newToken: string) =>
        set((state) => ({ 
          ...state,
          auth: { ...state.auth, token: newToken } })),
      updateUser: (user: UserData) => {
        set((state) => ({
         ...state,
         auth: {
          ...state.auth,
            user: {
              ...state.auth.user,
              ...user
            }
         },
         user: {
          ...state.user,
          ...user
         }
        }))
      },
      updateEntityData: (entity: UpdateLegaEntity.Body) => {
        set((state) => ({
          ...state,
          user: {
            ...state.user,
            cnpj: entity.cnpj,
            cnpj_razao: entity.cnpj_razao,
            cnpj_social: entity.cnpj_social,
            cnpj_zipcode: entity.cnpj_zipcode,
            cnpj_address: entity.cnpj_address,
            cnpj_city: entity.cnpj_city,
            cnpj_state: entity.cnpj_state,
            cnpj_complement: entity.cnpj_complement,
            cnpj_number: entity.cnpj_number
          }
        }))
      },
      updateBankingAccount: (bank: UpdateBankingAccount.Body) => {
        set((state) => ({
          ...state,
          user: {
            ...state.user,
            banco_id: bank.banco_id,
            agencia: bank.agencia,
            conta: bank.conta,
            n_pix: bank.n_pix
          }
        }))
      },
      updateDocumentsData: (documents: UpdateLegaEntityDoc.Documents & UpdatePersonalDoc.Documents) => {
        set((state) => ({
          ...state,
          user: {
            ...state.user,
            foto_doc_pessoal_frente: documents?.foto_doc_pessoal_frente,
            foto_doc_pessoal_verso: documents?.foto_doc_pessoal_verso,
            foto_rosto_frente: documents?.foto_rosto_frente,
            comprovante_endereco: documents?.comprovante_endereco,
            cnpj_doc_mei: documents?.cnpj_doc_mei,
            cnpj_certidao: documents?.cnpj_certidao,
          }
        }))
      },
      updateUserOnboardingStep: (value: number) => {
        set((state) => ({
          ...state,
          user: {
            ...state.user,
            onboarding_step: value
          }
        }))
      },
      updateStatusRegistration: (value: string) => {
        set((state) => ({
          ...state,
          user: {
            ...state.user,
            analise: value
          }
        }))
      },
      updateTokenShopify: (newTokenShopify: string) =>
        set((state) => ({
          ...state,
          auth: { ...state.auth, tokenShopify: newTokenShopify },
        })),
      logout: () => {
        useUserStore.persist.clearStorage();
        clearProductsData();
      },
    }),
    {
      name: "user-credential",
    }
  )
);
