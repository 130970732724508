import React from "react";
import { Box, BoxProps, Button, Flex, FlexProps, FormLabel, Input, Select } from '@chakra-ui/react';
import { getStores } from 'controllers/Stores';
import { Store, StoreResponse } from 'models/Store';
import { useUserStore } from 'store/reducers/user';
import { useForm } from 'react-hook-form';

type AbandonedCartFilterFields = {
  store_id: number
  product_name: string
  customer_name: string
  customer_email: string
  start_date: string
  end_date: string
}

type AbandonedCartFilterProps = {
  showFilters: boolean,
  setFilters: Function
}

export default function AbandonedCartFilters({ showFilters, setFilters }: AbandonedCartFilterProps) {
  const token = useUserStore((state) => state.auth.token);
  const [stores, setStores] = React.useState<Store[]>([]);

  const flexStyle: FlexProps = {
    direction: {base: "column", md: "row"},
    justify: "space-between"
  }
  const boxStyle: BoxProps = {
    w: {base: "100%", md: "48%"},
    py: "10px"
  }

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<AbandonedCartFilterFields>();

  const handleGetStores = async () => {
    try {
      const response: StoreResponse = await getStores(token);
      setStores(response.data);
    } catch (error: any) {}
  };

  const handleReset = () => {
    reset();
    setFilters({});
  }

  const handleFilter = (data: AbandonedCartFilterFields) => {
    setFilters(data);
  }

  React.useEffect(() => {
    handleGetStores();
  }, [])

  if (showFilters) {
    return (
      <Box padding="25px">
        <form onSubmit={handleSubmit(handleFilter)}>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="store_id">Loja</FormLabel>
              <Select id="store_id" {...register("store_id")}>
                <option value="">Selecione uma loja</option>
                {
                  stores.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </Select>
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="product_name">Produto</FormLabel>
              <Input
                id="product_name"
                placeholder="Digite um produto"
                {...register("product_name")}
              />
            </Box>
          </Flex>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="customer_name">Nome do Comprador</FormLabel>
              <Input
                id="customer_name"
                placeholder="Digite um nome"
                {...register("customer_name")}
              />
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="customer_email">Email do Comprador</FormLabel>
              <Input
                id="customer_email"
                placeholder="Digite um email"
                {...register("customer_email")}
              />
            </Box>
          </Flex>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="start_date">Data de Início</FormLabel>
              <Input
                type="date"
                id="start_date"
                {...register("start_date")}
              />
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="end_date">Data de Fim</FormLabel>
              <Input
                type="date"
                id="end_date"
                {...register("end_date")}
              />
            </Box>
          </Flex>
          <Button type='submit' colorScheme="purple" margin="5px">Filtrar</Button>
          <Button onClick={handleReset} margin="5px">Limpar</Button>
        </form>
      </Box>
    );
  }

  return (<></>);
}
