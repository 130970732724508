import React from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-color-palette/lib/css/styles.css";
import CheckTable from './CheckTableCheckout';
import LoadingPage from 'components/Loading/LoadingPage';
import CheckoutForm from './CheckoutForm';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import { useUserStore } from 'store/reducers/user';
import { CheckoutResponse } from 'models/checkout';
import { deleteCheckout, getCheckouts } from 'controllers/Checkout';
import { Checkout as CheckoutModel } from 'models/checkout';
import { DeleteModal } from 'components/modal/DeleteModal';

export default function Checkout() {
  const token = useUserStore((state) => state.auth.token);

  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<CheckoutModel>(null);
  const [show, setShow] = React.useState(false);
  const [checkoutData, setCheckoutData] = React.useState<CheckoutResponse>({ data: [] });
  const [confirmDeletion, setConfirmDeletion] = React.useState<number>(null);

  const switchShow = () => {
    setSelected(null);
    setShow(!show);
  }

  const handleGetCheckouts = async () => {
      try {
        setLoading(true);
        const response = await getCheckouts(token);
        setCheckoutData(response);
      } catch (error: any) {
      } finally {
        setLoading(false);
      }
    };

  const select = (checkout: CheckoutModel) => {
    setSelected(checkout);
    setShow(true);
  }

  const requestDelete = (checkout: CheckoutModel) => {
    setConfirmDeletion(checkout.id);
  }

  const handleDelete = async (checkoutId: number) => {
    setLoading(true);
    try {
      await deleteCheckout(checkoutId, token);
      const updatedData = checkoutData.data.filter(checkout => checkout.id != checkoutId);

      setCheckoutData({
        ...checkoutData,
        data: [
          ...updatedData
        ]
      })
    } catch (error) {
      console.log(error);
    } finally {
      setConfirmDeletion(null);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    handleGetCheckouts();
  }, []);

	return (
    <>
      <LoadingPage isOpen={loading} />
      <Button onClick={switchShow}>{show ? 'Voltar' : 'Novo Checkout'}</Button>

      {show ? (
        <CheckoutForm
          switchShow={switchShow}
          show={show}
          handleGetCheckouts={handleGetCheckouts}
          selected={selected}
        />
      ) : (
        <Box pt={{ base: '130px', md: '60px', xl: '40px' }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
              <CheckTable
                tableData={checkoutData}
                tableName="Checkout"
                select={select}
                requestDelete={requestDelete}
              />
          </SimpleGrid>
        </Box>
      )}

      <DeleteModal
        title="Remover checkout?"
        isOpen={confirmDeletion != null}
        onClose={() => setConfirmDeletion(null)}
        handleDelete={() => handleDelete(confirmDeletion)}
      />
    </>
	);
}
