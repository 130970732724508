import React from 'react';
import { Box, FormLabel, Textarea } from '@chakra-ui/react';

type TextAreaInputProps = {
  register: Function
  id: string
  label: string
  required?: boolean
}

export default function TextAreaInput ({ register, id, label, required = true }: TextAreaInputProps) {
  return (
    <Box marginBottom="14px">
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <Textarea
        id={id}
        {...register(id, { required: required })}
      />
    </Box>
  );
};
