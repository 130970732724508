import {
  Box,
  SimpleGrid,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  GridItem,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import PaymentAreaTab from "./PaymentAreaTab";
import PaymentAdvancedTab from "./PaymentAdvancedTab";
import AccountData from "./AccountData";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import { usePaymentStore } from "store/reducers/payments/paymentArea";
import { usePaymentRequestStore } from "store/reducers/payments/paymentRequests";
import BalanceCard from "../components/BalanceCard";
import { useUserStore } from "store/reducers/user";
import {
  formatCentToReal,
  formatCentToRealString,
  formatCurrency,
} from "../formatCentToReal";
import { notifyAlert, notifyError, notifySuccess } from "utils/notification";
import { CardContainer, CardRow } from "../components/styles";

export default function UserReports() {
  // Chakra Color Mode
  const { auth } = useUserStore();

  const { getBalance, balance, getDebtsAndCredit, debtsAndCredits } =
    usePaymentStore();
  const { hasPendingPaymentRequests, requestAnticipation, requestWithdraw } =
    usePaymentRequestStore();

  const [loading, setLoading] = useState(true);
  const [buttonBalance, setButtonBalance] = useState(true);
  const [buttonFutureSettlement, setFutureSettlement] = useState(true);
  const [messageAnticipation, setMessageAnticipation] = useState(
    "Antecipe seus recebíveis. Clique no botão abaixo e verifique a disponibilidade"
  );
  const [messageWithdraw, setMessageWithdraw] = useState(
    "Valor disponível para saque"
  );
  const [hasPending, setHasPending] = useState(false);
  useEffect(() => {
    (async () => {
      await getBalance({ contractId: auth.user.contractId.toString() });
      await getDebtsAndCredit({ contractId: auth.user.contractId.toString() });
    })();
  }, []);

  useEffect(() => {
    if (balance && debtsAndCredits) {
      checkButton(balance.futureSettlement, balance.availableAmount);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [balance, debtsAndCredits]);

  const checkButton = (futureSettlement: number, availableAmount: number) => {
    (async () => {
      try {
        const hasPending = await hasPendingPaymentRequests({
          userId: auth.user.id,
        });
        if (hasPending.data) {
          setHasPending(true);
          const message =
            "Você possui solicitações pendentes. Aguarde liberação.";
          setMessageAnticipation(message);
          setFutureSettlement(true);
        } else {
          setFutureSettlement(false);
          setButtonBalance(false);
        }
      } catch (err) {
        setButtonBalance(true);
        setFutureSettlement(true);
      }
    })();
  };

  const requestWithdrawCb = async (userId: number, amount: number) => {
    (async () => {
      try {
        // console.log({
        //   amount: amount * 100
        // })
        const response = await requestWithdraw({
          amount: amount * 100,
          userId: userId,
        });
        if (response.success) {
          notifySuccess("Solicitação feita com sucesso!");
          setButtonBalance(true);
        } else {
          notifyError("Erro ao fazer solicitação!");
        }
      } catch (err) {
        notifyError("Erro ao fazer solicitação!");
      }
    })();
  };

  const requestAnticipationCb = async (userId: number, amount: number) => {
    (async () => {
      try {
        // console.log({
        //   amount: amount * 100
        // })
        const response = await requestAnticipation({
          amount: amount * 100,
          userId: userId,
        });
        if (response.success) {
          notifySuccess("Solicitação feita com sucesso!");
          setFutureSettlement(true);
        } else {
          notifyError("Erro ao fazer solicitação!");
        }
      } catch (err) {
        notifyError("Erro ao fazer solicitação!");
      }
    })();
  };

  if (loading) {
    return <React.Fragment />;
  } else {
    return (
      <React.Fragment>
        <Box pt={{ base: "60px", md: "130px", xl: "80px" }}>
          <SimpleGrid lineHeight={3} gap="20px" mb="20px">
            <SimpleGrid
              columns={{ base: 1, md: 3, lg: 3, "2xl": 4 }}
              gap="20px"
              mb="20px"
            >
              <GridItem>
                <BalanceCard
                  name="Saldo disponível"
                  value={formatCentToReal(balance.availableAmount)}
                  description={messageWithdraw}
                  buttonText="Sacar"
                  disableButton={buttonBalance}
                  onClickButton={(userId: number, amount: number) => {
                    requestWithdrawCb(userId, amount);
                  }}
                />
              </GridItem>

              <GridItem>
                <BalanceCard
                  name="Lançamentos futuros"
                  value={formatCentToReal(balance.futureSettlement)}
                  description={messageAnticipation}
                  buttonText="Antecipar"
                  disableButton={buttonFutureSettlement}
                  onClickButton={(userId: number, amount: number) => {
                    requestAnticipationCb(userId, amount);
                  }}
                />
              </GridItem>

              <GridItem>
                <Card
                  h={{ base: "200px", md: "100%", lg: "100%", "2xl": "100%" }}
                >
                  <Flex
                    my="auto"
                    h="100%"
                    flexDirection={"column"}
                    // align={{ base: "center", md: "flex-start", xl: "flex-start" }}
                    // justify={{ base: "center", md: "flex-start",xl: "space-around" }}
                  >
                    <CardContainer>
                      <CardRow>
                        {/* <Stat> */}
                        {/* <StatLabel
              lineHeight="100%"
              fontSize={{
                base: "sm",
              }}
              > */}
                        {"Total sacado"}
                        {/* </StatLabel>
          </Stat> */}
                      </CardRow>
                      <CardRow>
                        <br />
                        <Text as="b">
                          {formatCurrency(
                            formatCentToReal(balance.totalWithdrawn || 0)
                          )}
                        </Text>
                        <br />
                        <br />
                        <Text fontSize="sm" color={"grey"}>
                          {"A soma de todos os seus saques realizados"}
                        </Text>
                      </CardRow>
                      <br />
                      <br />
                      <br />
                      <br />
                    </CardContainer>
                  </Flex>
                </Card>
              </GridItem>

              <GridItem colSpan={{ base: 1, md: 1, lg: 1, "2xl": 1 }}>
                <Card py="15px">
                  <AccountData />
                </Card>
              </GridItem>
            </SimpleGrid>

            <SimpleGrid
              columns={{ base: 1, md: 1, xl: 1 }}
              gap="20px"
              mb="20px"
            >
              <Tabs isLazy isFitted variant="enclosed">
                <TabList>
                  <Tab _selected={{ color: "#9966ff", fontWeight: "bold" }}>
                    Entradas e saídas
                  </Tab>
                  <Tab _selected={{ color: "#9966ff", fontWeight: "bold" }}>
                    Solicitação recebíveis
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <PaymentAreaTab />
                  </TabPanel>
                  <TabPanel>
                    <PaymentAdvancedTab />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </SimpleGrid>
          </SimpleGrid>
        </Box>
      </React.Fragment>
    );
  }
}
