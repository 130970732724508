import React from "react";
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BiCopy } from 'react-icons/bi';

type CopyFieldsProps = {
  text: string
  onlyIcon?: boolean
}

export default function CopyField({ text, onlyIcon = false }: CopyFieldsProps) {
  const [isIconChanged, setIsIconChanged] = React.useState(false);

  const handleCopyClick = async (value: string) => {
    await navigator.clipboard.writeText(value);
    setIsIconChanged(true);

    setTimeout(() => {
      setIsIconChanged(false);
    }, 2000);
  }

  return (
    <Box as="button" onClick={() => handleCopyClick(text)} cursor="pointer">
      <Flex align="center">
        {!onlyIcon && <Text>{text}</Text>}
        <Icon as={isIconChanged ? AiFillCheckCircle : BiCopy} ml="5px" color={isIconChanged ? "green.500" : "gray.500"} />
      </Flex>
    </Box>
  );
}
