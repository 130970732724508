import {
  Box,
  Flex,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import MiniStatistics from "./MiniStatistics";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import ComplexTable from "./ComplexTable";
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import * as React from "react";
import { formatCentToReal, formatCentToRealString } from "../formatCentToReal";
// Assets
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";
import { usePaymentStore } from "store/reducers/payments/paymentArea";

type RowObj = {
	method: "credit" | "invoice" | "pix",
    installment: number,
    date: string,
    status: "failed" | "processing" | "payed",
    amount: number;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function PaymentAreaTab() {

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
  const { getBalance, balance, getDebtsAndCredit, debtsAndCredits } =
    usePaymentStore();
  
 
  return (
    <Box >
      <SimpleGrid
              columns={{ base: 2, md: 2, lg: 4, "2xl": 4 }}
              // columns={4}
              gap="20px"
              mb="20px"
            >
              <Card py="15px">
                <MiniStatistics
                  textColor="green"
                  name="Entradas"
                  value={
                    formatCentToReal(
                      parseFloat(
                        debtsAndCredits.credits.toFixed(2)
                      )
                    )
                  }
                />
              </Card>
              <Card py="15px">
                <MiniStatistics
                  textColor="orange"
                  name="Estornos"
                  value={
                    formatCentToReal(
                      parseFloat(debtsAndCredits.reversions.toFixed(2))
                    ) 
                  }
                />
              </Card>
              <Card>
                <MiniStatistics
                  textColor="red"
                  name="Cancelamentos"
                  value={
                    formatCentToReal(
                      parseFloat(
                        debtsAndCredits.chargebacks.toFixed(2)
                      )
                    )
                  }
                />
              </Card>
              <Card py="15px">
                <MiniStatistics
                  textColor="blue"
                  name="Saídas"
                  value={
                    formatCentToReal(
                      parseInt(debtsAndCredits.debts.toFixed(2))
                    )
                  }
                />
              </Card>
            </SimpleGrid>
          

          <SimpleGrid columns={{ base: 1, md: 1, lg: 1, "2xl": 1 }}>
            <ComplexTable tableData={debtsAndCredits.results} />
          </SimpleGrid>
        </Box>
  );
}
