import { ImageFile } from '../models/File';

export const createImageFileList = (fileList: FileList): ImageFile[] => {
  let files: ImageFile[] = [];

  Array.from(fileList).forEach((file: File) => {
    files.push({
      file: file,
      preview: URL.createObjectURL(file)
    });
  });

  return files;
}
