import React, {useState} from 'react';
import {ButtonStyles} from "./styles";

import { Spinner } from '@chakra-ui/react'

export const CustomButton = ({ variant, isFullWidth, children, ...rest }) => {
  return (
    <ButtonStyles variant={variant} isFullWidth={isFullWidth} {...rest}>
      {rest.isLoading ? <Spinner boxSize={3}/> : children}
    </ButtonStyles>
  );
};

export default CustomButton;

