import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center
} from "@chakra-ui/react";
import styled from "styled-components";
// Custom Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import { FaCoins, FaEthereum, FaFlag } from "react-icons/fa";
import routes from "routes";
import { useHistory } from "react-router-dom";
import { useUserStore } from "store/reducers/user";
import {IoEllipsisVertical} from "react-icons/io5"
import axios from "axios";


export default function HeaderLinks() {

  const logout = useUserStore((state) => state.logout);
  const { name } = useUserStore((state) => state.auth.user);
  const [isOpen, setIsOpen] = React.useState(false);
  const [tax, setTax] = React.useState(null);
  const handleClose = () => {
    setIsOpen(false);
  };

  const fetchTax = async () => {
    const token = useUserStore.getState().auth.token;
    const adminUrl = `/v1/users/`;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}${adminUrl}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      );
      const fetchedTax = result["data"]["data"]["seller"]["negotiated_tax_rate"];
      setTax(fetchedTax);

    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchTax();
  }, [tax]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = "white";
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = "navy.900";
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = "whiteAlpha.200";
  const { push } = useHistory();

  const IconDesktop = styled.div`
    display: flex;
    @media (max-width: 1024px) {
      display:none;
    }
  `
  const IconMobile = styled.div`
    display:none;
    @media (max-width: 1024px) {
      display: flex;
    }
  `
  return (
    <Flex
      w={"100%"}
      alignItems="center"
      flexDirection="row"

      flexWrap={"unset"}
      p="10px"

      boxShadow={shadow}
    >
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">

        <ModalContent
          style={{
            background: "linear-gradient(45deg, #25312F, #25312F)",
          }}
        >
          <ModalHeader>
            <Flex align="middle" justify="center">
              <FaFlag color="gold" style={{ marginTop: "13px" }} />
              <span style={{ fontSize: "36px", color: "white", marginLeft: "10px", marginRight: "10px", textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)" }}>
                Plano Gold
              </span>
              <FaCoins color="gold" style={{ marginTop: "13px" }} />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <div
              style={{
                backgroundColor: "gold",
                borderRadius: "10px",
                width: "95%",
                margin: "0 auto",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                padding: "10px",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Taxa por Transação: {(Math.round(tax * 100) / 100).toFixed(2)}%
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                (para Cartão, Boleto e Pix)
              </p>
            </div>
            <div
              style={{
                backgroundColor: "gold",
                borderRadius: "10px",
                width: "95%",
                margin: "0 auto",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                padding: "10px",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Taxa de Antecipação: 1,90%
              </p>
            </div>
            <div
              style={{
                backgroundColor: "gold",
                borderRadius: "10px",
                width: "95%",
                margin: "0 auto",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                padding: "10px",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Taxa de Saque: R$ 4,50
              </p>
            </div>
            <div
              style={{
                backgroundColor: "gold",
                borderRadius: "10px",
                width: "95%",
                margin: "0 auto",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                padding: "10px",
                border: "1px solid black",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "black",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Taxa de Gateway: R$ 1,00
              </p>

            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Menu>
        <MenuButton p="0px">
          <IconDesktop>
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={name}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
          </IconDesktop>
          <IconMobile>
          <Icon
					as={IoEllipsisVertical}
					color={"white"}
					
					w='20px'
					h='20px'
					
					_hover={{ cursor: 'pointer' }}
				/>
          
          </IconMobile>
          
        </MenuButton>
        <MenuList
          border="1px solid black"
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
        // border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Olá, {name}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {/* Task #232 */}
            {/* {<MenuItem
              onClick={() => {
                push("/admin/perfil");
              }}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Meu perfil</Text>
            </MenuItem>} */}
            {<MenuItem
              onClick={() => {
                push("/admin/alterarEmail");
              }}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Alterar email</Text>
            </MenuItem>}
            {<MenuItem
              onClick={() => {
                push("/admin/alterarSenha");
              }}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Alterar senha</Text>
            </MenuItem>}
            {<MenuItem
              onClick={handleOpen}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Plano</Text>
            </MenuItem>}
            {/* <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Config. Notificações</Text>
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                logout();
                push("/auth");
              }}
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Sair</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
