import React from 'react';
import { useForm } from 'react-hook-form';
import LoadingPage from 'components/Loading/LoadingPage';
import { Box, Button, FormControl } from '@chakra-ui/react';
import Card from 'components/card/Card';
import useStores from 'hooks/stores';
import FormInput from 'components/form/FormInput';
import SelectInput from 'components/form/SelectInput';
import FormTitle from 'components/form/FormTitle';
import { notifyError, notifySuccess } from 'utils/notification';
import { CreateMemberAreaClassroomRequest, MemberAreaClassroom } from 'models/MemberAreaClassroom';
import { createMemberAreaClassroom, updateMemberAreaClassroom } from 'controllers/MemberArea';
import useStoreProducts from 'hooks/products';
import useMemberAreaProviders from 'hooks/memberAreaProviders';

type MemberAreaFormProps = {
  switchShow: Function
  show: boolean
  selected?: MemberAreaClassroom
  refetch: Function
}

export default function MemberAreaForm({ switchShow, show, refetch, selected = null }: MemberAreaFormProps) {
  const [loading, setLoading] = React.useState(false);

  const defaultValues = selected ? {
    name: selected.name,
    api_key: selected.api_key,
    classroom_id: selected.classroom_id,
    store_id: selected.store_id,
    product_id: selected.product.id,
    provider_id: selected.provider.id,
  } : {}

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm<CreateMemberAreaClassroomRequest>({ defaultValues });
  const storeId = watch('store_id');

  const { stores: storeData } = useStores();
  const { providers } = useMemberAreaProviders();
  const { products: productData, isLoadingStoreProducts } = useStoreProducts(storeId);

  const handleSave = async (data: any) => {
    if (selected) {
      await handleUpdate(data);
    } else {
      await handleCreate(data);
    }
  }

  const handleCreate = async (data: CreateMemberAreaClassroomRequest) => {
    setLoading(true);
    try {
      await createMemberAreaClassroom(data);
      notifySuccess('Integração criada com sucesso!');
      refetch();
      switchShow();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      notifyError(errorMessage ? errorMessage : 'Erro ao criar integração. Tente Novamente!');
    } finally {
      setLoading(false);
    }
  }

  const handleUpdate = async (data: CreateMemberAreaClassroomRequest) => {
    setLoading(true);
    try {
      await updateMemberAreaClassroom(selected.id, data);
      notifySuccess('Integração atualizada com sucesso!');
      refetch();
      switchShow();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      notifyError(errorMessage ? errorMessage : 'Erro ao atualizar integração. Tente Novamente!');
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (selected && storeData) {
      setValue('store_id', selected.store_id);
    }
    if (selected && productData) {
      setValue('product_id', selected.product?.id);
    }
  }, [storeData, productData])

  React.useEffect(() => {
    reset();
  }, [show]);

  return (
    <>
      <LoadingPage isOpen={loading} />
      <Card
        flexDirection="column"
        marginTop={"10px"}
        w="100%"
        px="20px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Box>
          <FormTitle title={selected ? "Editar Integração" : "Nova Integração"} />
          <form onSubmit={handleSubmit(handleSave)}>
            <FormControl rowGap="20px">
              <FormInput register={register} id="name" label="Nome *" />

              <SelectInput
                register={register}
                id="store_id"
                label="Loja *"
                options={storeData?.data.map(item => (
                  { value: item.id.toString(), label: item.name }
                ))}
                placeholder="Selecione uma loja"
              />

              {storeId && (
                <SelectInput
                  register={register}
                  id="product_id"
                  label="Produto *"
                  options={productData?.data.map(item => (
                    { value: item.id.toString(), label: item.name }
                  ))}
                  placeholder={isLoadingStoreProducts ? "Buscando Produtos..." : "Selecione um produto"}
                  disabled={isLoadingStoreProducts}
                />
              )}

              {!selected && (
                <SelectInput
                  register={register}
                  id="member_area_provider_id"
                  label="Provedor *"
                  options={providers?.data.map(item => (
                    { value: item.id.toString(), label: item.name }
                  ))}
                  placeholder="Selecione um provedor"
                />
              )}

              <FormInput register={register} id="api_key" label="Chave Secreta *" />
              <FormInput register={register} id="classroom_id" label="Id da Turma *" />

              <Button type='submit' colorScheme='green'>Salvar</Button>
            </FormControl>
          </form>
        </Box>
      </Card>
    </>
  );
}
