import { Links, Meta } from './checkout';
import { Store } from './Store';
import { Product } from './Product';

export interface PixelResponse {
  data?: Pixel[];
  links?: Links;
  meta?: Meta;
}

export enum PixelType {
  GOOGLE = 'google',
  META = 'meta',
  TIKTOK = 'tiktok',
}

export interface Pixel {
  id: number;
  pixel_name: string;
  bill_check: boolean;
  pix_check: boolean;
  code: string;
  store: Store;
  product?: Product;
  convert_label?: string;
  type: PixelType;
  api_token?: string;
  paid_pix_check?: boolean;
  paid_bill_check?: boolean;
}

export interface CreatePixelRequest {
  pixel_name: string;
  bill_check: boolean;
  pix_check: boolean;
  code: string;
  store_id: number;
  product_id: number;
  convert_label?: string;
  type: PixelType;
  api_token?: string;
  paid_pix_check?: boolean;
  paid_bill_check?: boolean;
}
