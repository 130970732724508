import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';

type ImageProps = {
  url: string
  alt: string
}

type ImageListProps = {
  images: ImageProps[]
  width?: number
  height?: number
}

export default function ImageList ({ images, width = 200, height = 200 }: ImageListProps) {
  return (
    <Flex direction="row" flexWrap="wrap" gap={4}>
      {images.map((image: ImageProps) => (
        <Box
          key={image.url}
          borderRadius="lg"
          padding="4px"
          borderColor="gray.300"
          borderWidth="1px"
          borderStyle="dashed"
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxW={width}
          maxH={height}
          style={{
            aspectRatio: `${width} / ${height}`,
            width: '100%'
          }}
        >
          <Image
            src={image.url}
            alt={image.alt}
            width="100%"
            height="100%"
            objectFit="contain"
          />
        </Box>
      ))}
    </Flex>
  );
};
