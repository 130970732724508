import React from 'react';
import { Box, Button, FormControl, FormLabel, Input, Select, Switch } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { CreateShippingRequest, Shipping, ShippingResponse } from 'models/Shipping';
import { useUserStore } from 'store/reducers/user';
import { useForm } from 'react-hook-form';
import { createShipping, updateShipping } from 'controllers/Shippings';
import LoadingPage from 'components/Loading/LoadingPage';
import useStores from 'hooks/stores';
import useStoreProducts from 'hooks/products';

type ShippingFormProps = {
  switchShow: Function
  show: boolean
  shippingData: ShippingResponse
  setShippingData: Function
  selectedShipping?: Shipping
}

export default function ShippingForm({ switchShow, show, shippingData, setShippingData, selectedShipping = null }: ShippingFormProps) {
  const token = useUserStore((state) => state.auth.token);
  const [loading, setLoading] = React.useState(false);

  const defaultValues = selectedShipping ? {
    name: selectedShipping.name,
    description: selectedShipping.description,
    price: selectedShipping.price,
    is_free: selectedShipping.is_free,
    multiply_by_products: selectedShipping.multiply_by_products,
    is_default: selectedShipping.is_default,
    product_id: selectedShipping.product?.id,
    store_id: selectedShipping.store.id,
  } : {
    is_free: true,
    multiply_by_products: false,
    is_default: false,
  }

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue
  } = useForm<CreateShippingRequest>({ defaultValues });
  const storeIdValue = watch('store_id');
  const isFreeValue = watch('is_free');
  const multiplyByProductsValue = watch('multiply_by_products');
  const isDefaultValue = watch('is_default');

  const { stores: storeData } = useStores();
  const { products: productData, isLoadingStoreProducts } = useStoreProducts(storeIdValue);

  const handleSaveShipping = async (data: CreateShippingRequest) => {
    if (selectedShipping) {
      await handleUpdateShipping(data);
    } else {
      await handleCreateShipping(data);
    }
  }

  const handleCreateShipping = async (data: CreateShippingRequest) => {
    setLoading(true);
    try {
      const response: Shipping = await createShipping(data, token);
      setShippingData({
        ...shippingData,
        data: [
          response,
          ...shippingData.data
        ]
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      switchShow();
    }
  }

  const handleUpdateShipping = async (data: CreateShippingRequest) => {
    setLoading(true);
    try {
      const response: Shipping = await updateShipping(selectedShipping.id, data, token);
      const updatedShippingData = shippingData.data.map(shipping => {
        if (shipping.id == selectedShipping.id) {
          return response;
        }
        return shipping;
      })

      setShippingData({
        ...shippingData,
        data: [
          ...updatedShippingData
        ]
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      switchShow();
    }
  }

  React.useEffect(() => {
    if (selectedShipping && storeData) {
      setValue('store_id', selectedShipping.store.id);
    }
    if (selectedShipping && productData) {
      setValue('product_id', selectedShipping.product?.id);
    }
  }, [storeData, productData])

  React.useEffect(() => {
    reset();
  }, [show]);

  return (
    <>
      <LoadingPage isOpen={loading} />

      <Card flexDirection='column' marginTop={'10px'} w='100%' px='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Box pt={{ base: '130px', md: '60px', xl: '40px' }}>
          <form onSubmit={handleSubmit(handleSaveShipping)}>
            <FormControl rowGap='20px'>
              <FormLabel htmlFor="name">Nome *</FormLabel>
              <Input id="name" type="text" {...register("name", { required: true })}/>

              <br/>
              <br/>
              <FormLabel htmlFor="description">Descrição *</FormLabel>
              <Input id="description" type="text" {...register("description", { required: true })}/>

              <br/>
              <br/>
              <FormLabel htmlFor="store_id">Loja *</FormLabel>
              <Select id="store_id" {...register("store_id", { required: true })}>
                <option value="">Selecione uma loja</option>
                {
                  storeData?.data.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </Select>

              {
                storeIdValue && (
                  <>
                    <br/>
                    <FormLabel htmlFor="product_id">Produto</FormLabel>
                    <Select disabled={isLoadingStoreProducts} id="product_id" {...register("product_id")}>
                      <option value="">{isLoadingStoreProducts ? "Buscando Produtos..." : "Todos"}</option>
                      {
                        productData?.data.map(item => {
                          return (
                            <option key={item.id} value={item.id}>{item.name}</option>
                          );
                        })
                      }
                    </Select>
                  </>
                )
              }

              <br/>
              <FormLabel htmlFor='is_free'>Grátis</FormLabel>
              <Switch
                id='is_free'
                isChecked={isFreeValue}
                onChange={() => {
                  setValue("is_free", !isFreeValue)
                }}
              />

              {!isFreeValue && (
                <>
                  <br/>
                  <br/>
                  <FormLabel htmlFor="price">Valor *</FormLabel>
                  <Input id="price" type="number" step="0.01" {...register("price", { required: true })}/>
                </>
              )}

              <br/>
              <br/>
              <FormLabel htmlFor='multiply_by_products'>Multiplicar por Produtos</FormLabel>
              <Switch
                id='multiply_by_products'
                isChecked={multiplyByProductsValue}
                onChange={() => {
                  setValue("multiply_by_products", !multiplyByProductsValue)
                }}
              />

              <br/>
              <br/>
              <FormLabel htmlFor='is_default'>Padrão</FormLabel>
              <Switch
                id='is_default'
                isChecked={isDefaultValue}
                onChange={() => {
                  setValue("is_default", !isDefaultValue)
                }}
              />

              <br />
              <br />
              <Button type='submit'>Salvar</Button>
              <Button onClick={() => switchShow()}>Voltar</Button>
            </FormControl>
          </form>
        </Box>
      </Card>
    </>
  );
}
