import styled from "styled-components";


export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  
`;

export const GridItem = styled.div`
    align-self: end; 
`;

export const RenderImgContainer = styled.div<{ fileName: File }>`
    border-radius: 5px;
    height: 80px;
    width: 600px;
    border: 0.5px solid ${props => props.fileName ? 'green' : 'grey'};
    background-color: ${props => props.fileName ? '#F0FFF0' : 'white'};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 15px;
`
export const TitleLabelContainer = styled.div`
    width: 100%;     
    display: flex;
    flex-direction: column;
`
export const TitleLabel = styled.label`
    width: 100%;     
    display: flex;
    flex-direction: column;
    font-weight: bold;
`
export const SubtitleLabel = styled.label`
    font-size:10px;
`
export const RenderImgButtonLabel = styled.label`
    cursor: pointer;
    display: flex;
    width: 100px;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    text-align: center;
    flex-direction: column;
`
export const RenderImgIconStatus = styled.div`
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

`
