import React from 'react'
import { useUserStore } from 'store/reducers/user';
import { Store, StoreResponse } from 'models/Store';
import { Box, BoxProps, Button, Flex, FlexProps, FormLabel, Input, Select } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { getStores } from 'controllers/Stores';

type ShippingFilterFields = {
  store_id: number
  name: string
}

type ShippingFiltersProps = {
  showFilters: boolean,
  setFilters: Function
}

export default function ShippingFilters({ showFilters, setFilters }: ShippingFiltersProps) {
  const token = useUserStore((state) => state.auth.token);
  const [stores, setStores] = React.useState<Store[]>([]);

  const flexStyle: FlexProps = {
    direction: {base: "column", md: "row"},
    justify: "space-between"
  }
  const boxStyle: BoxProps = {
    w: {base: "100%", md: "48%"},
    py: "10px"
  }

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<ShippingFilterFields>();

  const handleGetStores = async () => {
    try {
      const response: StoreResponse = await getStores(token);
      setStores(response.data);
    } catch (error: any) {}
  };

  const handleFilter = (data: ShippingFilterFields) => {
    setFilters(data);
  }

  const handleReset = () => {
    reset();
    setFilters({});
  }

  React.useEffect(() => {
    handleGetStores();
  }, []);

  return (
    <>
      {showFilters && (
        <Box padding="25px">
          <form onSubmit={handleSubmit(handleFilter)}>
            <Flex {...flexStyle}>
              <Box {...boxStyle}>
                <FormLabel htmlFor="store_id">Loja</FormLabel>
                <Select id="store_id" {...register("store_id")}>
                  <option value="">Selecione uma loja</option>
                  {
                    stores.map(item => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))
                  }
                </Select>
              </Box>
              <Box {...boxStyle}>
                <FormLabel htmlFor="name">Nome</FormLabel>
                <Input
                  id="name"
                  placeholder="Digite um nome"
                  {...register("name")}
                />
              </Box>
            </Flex>
            <Button type='submit' colorScheme="purple" margin="5px">Filtrar</Button>
            <Button onClick={handleReset} margin="5px">Limpar</Button>
          </form>
        </Box>
      )}
    </>
  );
}
