import React from "react"
import { Container, Row, RowBody, Item, RowHeader, Header, LastItem, FirstItem } from "./styles"
import { Tag, Icon } from "@chakra-ui/react";
import { Text } from "styleguide/Typograph"
import { getOrderColor, getOrderStatus, getPaymentType, getPaymentTypeIcon } from 'utils/order';
import { Order } from 'models/Order';
import { formatCurrency } from 'utils/currency';

type ListOrderProps = {
    orders: Order[]
}

const ListOrders = ({ orders }: ListOrderProps) => {
    return (
        <Container>
            <Header>
                <Item>
                <Text type="h4">

                Últimos pedidos
                </Text>

                </Item>
                {/* <Item>
                <CustomButton variant={"tertiary"} isFullWidth={false}>
                    Ver pedidos
                </CustomButton>

                </Item> */}
            </Header>
            <Row>
                <RowHeader>
                    <FirstItem>
                    <Text type="h9" >
                        Comprador
                    </Text>
                    </FirstItem>
                    <Item>
                    <Text type="h9" >
                        Método
                        </Text>
                    </Item>
                    <Item>
                        <Text type="h9" >
                            Loja
                        </Text>
                    </Item>
                    <Item>
                        <Text type="h9" >
                            Status
                        </Text>
                    </Item>
                    <LastItem>
                    <Text type="h9" >
                        Valor (R$)
                        </Text>
                    </LastItem>
                </RowHeader>
            </Row>
            {orders.slice(0, 5).map((value) => (
                <Row>
                    <RowBody>
                        <FirstItem >
                            <Text type="h6" >
                                {value.customer?.first_name}
                            </Text>
                        </FirstItem>
                        <Item>
                            <Icon
                              as={getPaymentTypeIcon(value.transaction.type.name)}
                              w='24px'
                              h='auto'
                              color='purple.600'
                              title={getPaymentType(value.transaction.type.name)}
                            />
                        </Item>
                        <Item>
                            <Text type="h7" >
                                {value.store.name}
                            </Text>
                        </Item>
                        <Item>
                            <Tag colorScheme={getOrderColor(value.transaction.status.name)} size={"md"}>
                                {getOrderStatus(value.transaction.status.name)}
                            </Tag>
                        </Item>
                        <LastItem>
                            <Text type="h6">
                                {formatCurrency(parseFloat(value.total_price))}
                            </Text>
                        </LastItem>
                    </RowBody>


                </Row>
            ))}
        </Container>
    )
}


export default ListOrders;
