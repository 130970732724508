import React, { useEffect } from 'react';
import { Box, GridItem, Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { CustomGridItem, HeaderContent, HeaderItem } from "./styles";
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdApproval, MdCancel, MdRequestPage } from 'react-icons/md';
import { useUserStore } from 'store/reducers/user';
import { getTransactions } from 'services/getTransactions';
import Stepper from "react-stepper-horizontal";
import StepGuide from "./components/StepGuide"
import { CustomSelect } from "styleguide/Select"
import { getOrderMetrics, getOrdersPerDay, getOrderSumMetrics, getProductMetrics, getViewMetrics } from "services/metricsRequest"
import ListOrders from './components/ListOrders/ListOrders';
import PaymentMethodsGraph from './components/PaymentMethodsGraph';
import OrdersPerDayGraph from './components/OrdersPerDayGraph';
import ProductsHighlights from './components/ProductsHighlights'
import ViewsMetricsGraph from './components/ViewsMetricsGraph'
import Dashboard from './pages/Dashboard'
import StepDashboard from './pages/StepDashboard'
import { Text } from "styleguide/Typograph"

export default function UserReports() {
	const [totalOrders, setTotalOrders] = React.useState("");
	const [totalPaid, setTotalPaid] = React.useState("");
	const [totalFailed, setTotalFailed] = React.useState("");
	const [totalBoleto, setTotalBoleto] = React.useState("");
	const [totalCard, setTotalCard] = React.useState("");
	const [totalPix, setTotalPix] = React.useState("");
	const [orderSum, setOrderSum] = React.useState({})
	const [listOrders, setListOrders] = React.useState([])
	const [viewMetrics, setViewMetrics] = React.useState([])
	const [ordersPerDay, setOrdersPerDay] = React.useState([])
	const [productsHighlights, setProductsHighlights] = React.useState([])
	const auth = useUserStore((state) => state.auth);

	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');


	const formatFloatValue = (number: any) => {
		var valorTotal = String(number);
		var len = valorTotal.length;
		var valorTotalFormatado = valorTotal.substring(0, len - 2) + "." + valorTotal.substring(len - 2);

		return new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: 2
			// These options are needed to round to whole numbers if that's what you want.
			//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
			//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		}).format(Number(valorTotalFormatado));

	}

	const handleGetTransactions = async () => {
		// const { data } = await getTransactions({ contractId: `${auth.user.contractId}` })
		// const ordersMetrics = await getOrderMetrics({ daysAgo: 30 })
		// const ordersSumMetrics = await getOrderSumMetrics({ daysAgo: 30 });
		// const viewMetricsResult = await getViewMetrics({ daysAgo: 30 });
		// const productsHighlightsResult = await getProductMetrics({ daysAgo: 30 });
		// const ordersPerDayResult = await getOrdersPerDay({ daysAgo: 30 });

		// if (ordersSumMetrics.success) {
		// 	setOrderSum(ordersSumMetrics.data)
		// }

		// if (viewMetricsResult.success) {
		// 	setViewMetrics(viewMetricsResult.data)
		// }
		// if (ordersMetrics.success) {
		// 	setListOrders(ordersMetrics.data)
		// }
		// if (ordersPerDayResult.success) {
		// 	setOrdersPerDay(ordersPerDayResult.data)
		// }

		// if (productsHighlightsResult.success) {
		// 	setProductsHighlights(productsHighlightsResult.data)
		// }

		// const transactionPix = formatFloatValue(data.filter(item => item.payment_method == "pix" && item).reduce((accumulator: any, currentValue) => accumulator + Number(currentValue.amount), 0));
		// setTotalPix(transactionPix);

		// const transactionBoleto = formatFloatValue(data.filter(item => item.payment_method == "invoice" && item).reduce((accumulator: any, currentValue) => accumulator + Number(currentValue.amount), 0));
		// setTotalBoleto(transactionBoleto);

		// const transactionCartao = formatFloatValue(data.filter(item => item.payment_method == "credit" && item).reduce((accumulator: any, currentValue) => accumulator + Number(currentValue.amount), 0));
		// setTotalCard(transactionCartao);

		// const ordersPaid = formatFloatValue(data.filter(item => item.status == "completed" && item).reduce((accumulator: any, currentValue) => accumulator + Number(currentValue.amount), 0));
		// setTotalPaid(ordersPaid);

		// const ordersFailed = formatFloatValue(data.filter(item => item.status == "cancelled" && item).reduce((accumulator: any, currentValue) => accumulator + Number(currentValue.amount), 0));
		// setTotalFailed(ordersFailed);

		// const orders = formatFloatValue(data.reduce((accumulator: any, currentValue) => accumulator + Number(currentValue.amount), 0));
		// setTotalOrders(orders);

	}

	useEffect(() => {

	}, [])
	React.useMemo(() => {
		// handleGetTransactions();
	}, []);

	return (
		<Box >
			{/*{auth.user.analise == "Aprovado" ?	(<Dashboard />) : (	<StepDashboard />)	}*/}
			<Dashboard />
		</Box>
	);
}
