import React from "react";
import { FaFilePdf, FaArrowLeft, FaFileImage, FaCheck, FaArrowRight, FaPaperclip, FaUpload } from 'react-icons/fa';
import {
    FormLabel,
    Button,
    Icon
} from "@chakra-ui/react";
import { RenderImgContainer, 
    RenderImgButtonLabel, 
    TitleLabel, 
    RenderImgIconStatus,
    SubtitleLabel,
    TitleLabelContainer } from "../styles";

const RenderImageInput = ({ id, name, value, onChange }) => {
    const fileName = value instanceof File ? value.name : value;

    const imageUrl = value instanceof File ? URL.createObjectURL(value) : fileName !== '' && fileName !== null ? `${process.env.REACT_APP_API_URL_IMAGE}/storage/` + fileName : '/cam.png';

    return (
        <RenderImgContainer fileName={fileName}>

            <RenderImgIconStatus >
                {fileName ?
                    <Icon as={FaCheck} boxSize={9} color="green" /> :
                    <Icon as={FaFileImage} boxSize={9} />}

            </RenderImgIconStatus>
            <input
                id={id}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={onChange}
            />


<TitleLabelContainer>
                <TitleLabel>{name}</TitleLabel>
                <SubtitleLabel>Formatos aceitos de arquivos: jpg, png e bmp (limite 10MB)</SubtitleLabel>
            </TitleLabelContainer>
            <RenderImgButtonLabel htmlFor={id}>
                <FaPaperclip style={{ marginRight: '5px' }} />
                {fileName ? "Editar" : "Selecionar"}
            </RenderImgButtonLabel>
        </RenderImgContainer>
    );
}

export default RenderImageInput;