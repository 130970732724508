import React, { useEffect, useState } from "react";
import { Collapse, Switch, Text, Input, Button } from "@chakra-ui/react";
import { Container, Row, SwitchRow, Item } from "./styles"
import { getUserIntegrationByUserId, postUserIntegration, getIntegrations } from "services/integrations"
import { useUserStore } from 'store/reducers/user';
import { CustomButton } from "styleguide/Button"
import { notifyError, notifySuccess } from "utils/notification";

const SakIntegration = () => {

    const user = useUserStore((state) => state.auth);

    const [abandonedCartSwitch, setAbandonedCartSwitch] = useState(false)
    const [pendingPix, setPendingPix] = useState(false)
    const [pendingInvoice, setPendingInvoice] = useState(false)
    const [ReportanaClientId, setReportanaClientId] = useState("")
    const [ReportanaClientSecret, setReportanaClientSecret] = useState("")
    const [integrationSwitch, setIntegrationSwitch] = useState(false)
    const [SakclientId, setSakClientId] = useState("")
    const [SakclientSecret, setSakClientSecret] = useState("")

    const postAbandonedCart = async () => {

        if (integrationSwitch) {
            try {

                const accessKeyEncode = SakclientId;

                const abandonedRequest = postUserIntegration({
                    accessKey: accessKeyEncode,
                    name: 'sak',
                    type: 'abandoned_cart',
                    status: abandonedCartSwitch,
                    userId: user.user.id
                });

                const pixRequest = postUserIntegration({
                    accessKey: accessKeyEncode,
                    name: 'sak',
                    type: 'pending_pix',
                    status: pendingPix,
                    userId: user.user.id
                });

                const invoiceRequest = postUserIntegration({
                    accessKey: accessKeyEncode,
                    name: 'sak',
                    type: 'pending_invoice',
                    status: pendingInvoice,
                    userId: user.user.id
                });


                const [abandonedResult, pixResult, invoiceResult] = await Promise.all([
                    abandonedRequest, pixRequest, invoiceRequest]);


                if (abandonedResult && pixResult && invoiceResult) {
                    notifySuccess("Integração atualizada com sucesso!")
                } else {
                    notifyError("Erro ao realizar integração!")
                }
            } catch (err) {
                console.log(err)
            }
        }
    }

    useEffect(() => {
        (async () => {
            const reportana = await getIntegrations({ type: 'sak' });

            if (reportana.data.length > 0) {
                setIntegrationSwitch(true)
                const accessKeyDecode = reportana.data[0].access_key;
                setSakClientId(accessKeyDecode);
            }

            reportana.data.forEach(value => {
                const name = value.integration.type

                if (name == 'abandoned_cart') {
                    setAbandonedCartSwitch(true)
                }
                if (name == 'pending_pix') {
                    setPendingPix(true)
                }
                if (name == 'pending_invoice') {
                    setPendingInvoice(true)
                }
            })

        })()
    }, [])

    return (
        <Container>
            <SwitchRow>
                <Item>
                    <Switch isChecked={integrationSwitch} onChange={() => { setIntegrationSwitch(!integrationSwitch) }} />
                </Item>
                <Item>
                    <Text fontWeight={"bold"} color={"purple"}>
                        Sak
                    </Text>

                </Item>
            </SwitchRow>


            <Row>
                <Collapse in={integrationSwitch}>
                    <Container>
                        <Row>
                            <Text>Client ID (Solicitar ao suporte do SAK)  </Text>
                            <Input
                                value={SakclientId}
                                onChange={(event) => setSakClientId(event.target.value)}
                            />
                        </Row>
                    </Container>
                    <SwitchRow>
                        <Item>
                            <Switch isChecked={abandonedCartSwitch} onChange={() => { setAbandonedCartSwitch(!abandonedCartSwitch) }} />
                        </Item>
                        <Item>
                            <Text fontWeight={"bold"} color={"purple"}>
                                Notificar carrinho abandonado
                            </Text>

                        </Item>
                    </SwitchRow>
                    <SwitchRow>
                        <Item>
                            <Switch isChecked={pendingInvoice} onChange={() => { setPendingInvoice(!pendingInvoice) }} />
                        </Item>
                        <Item>
                            <Text fontWeight={"bold"} color={"purple"}>
                                Notificar boleto pendente
                            </Text>

                        </Item>
                    </SwitchRow>
                    <SwitchRow>
                        <Item>
                            <Switch isChecked={pendingPix} onChange={() => { setPendingPix(!pendingPix) }} />
                        </Item>
                        <Item>
                            <Text fontWeight={"bold"} color={"purple"}>
                                Notificar pix pendente
                            </Text>

                        </Item>
                    </SwitchRow>
                    <Row>

                        <CustomButton isFullWidth={false} variant={'primary'} onClick={() => postAbandonedCart()}>Salvar</CustomButton>
                    </Row>
                </Collapse>
            </Row>
        </Container>
    )
}


export default SakIntegration;