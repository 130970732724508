import React from 'react';
import {
    Box,
    BoxProps,
    Button,
    Flex,
    FlexProps,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    Select
} from '@chakra-ui/react';
import { notifyError } from 'utils/notification';
import LoadingPage from 'components/Loading/LoadingPage';
import useStores from 'hooks/stores';
import { useForm } from 'react-hook-form';

type ImportExcelModalProps = {
    isOpen: boolean
    onClose: Function
}
type OrderReportFilters = {
    store_id: string;
    start_date: string;
    end_date: string;
    date: string;
};
export function ImportExcelModal({ isOpen, onClose }: ImportExcelModalProps) {
    const { stores, isLoadingStores } = useStores();
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const flexStyle: FlexProps = {
        direction: { base: "column", md: "row" },
        justify: "space-between"
    }
    const boxStyle: BoxProps = {
        w: { base: "100%", md: "48%" },
        py: "10px"
    }

    const {
        register,
        handleSubmit,
    } = useForm<OrderReportFilters>({
        defaultValues: {
            end_date: new Date().toISOString().split('T')[0],
            start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0],
            date: new Date().toISOString().split('T')[0], // Adicionando a data atual como valor padrão
        }
    });

    const importExcelRequest = async (data: OrderReportFilters) => {
        try {
            setLoading(true);

            // Adicione aqui a lógica para importar o arquivo Excel
            console.log("Data selecionada:", data.date);

            onClose();
        } catch (error) {
            notifyError("Erro ao importar arquivo Excel, tente novamente mais tarde.")
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={() => onClose()}>
            <ModalContent>
                <LoadingPage isOpen={isLoading || isLoadingStores} />
                <ModalHeader>Importar Excel</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit(importExcelRequest)}>
                        <FormControl rowGap='20px'>
                            <Box>
                                <FormLabel htmlFor="start_date">De</FormLabel>
                                <Input
                                    type="date"
                                    id="start_date"
                                    {...register("start_date")}
                                />
                            </Box>
                            <Box>
                                <FormLabel htmlFor="end_date">Até</FormLabel>
                                <Input
                                    type="date"
                                    id="end_date"
                                    {...register("end_date")}
                                />
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mt="20px" textAlign="center">
                                <Button type="submit" colorScheme="green">
                                    Extrair Excel
                                </Button>
                            </Box>
                        </FormControl>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
