import {
  HasPendingPaymentRequests,
  PaymentAdvancedRequest,
  RequestAnticipation,
  RequestWithdraw,
  ListPaymentRequestsByUserId
} from "models/PaymentRequests";
import { useCallback } from "react";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  hasPendingPaymentRequests,
  requestAnticipation,
  requestWithdraw,
  getPaymentRequestByUserId
} from "services/paymentRequest";

interface IPaymentRequests {
  paymentRequests: Array<PaymentAdvancedRequest>,
  requestWithdraw: (
    params: RequestWithdraw.Params
  ) => Promise<RequestWithdraw.Result>;
  requestAnticipation: (params: RequestAnticipation.Params) => Promise<RequestAnticipation.Result>;
  hasPendingPaymentRequests: (
    params: HasPendingPaymentRequests.Params
  ) => Promise<HasPendingPaymentRequests.Result>;
  getPaymentRequestByUserId: (
    params: ListPaymentRequestsByUserId.Params
  ) => Promise<ListPaymentRequestsByUserId.Result>;
}

export const usePaymentRequestStore = create<IPaymentRequests>()(
  persist(
    
    (set) => ({
      paymentRequests: [],
      requestWithdraw: async (
        params: RequestWithdraw.Params
      ): Promise<RequestWithdraw.Result> => {
        const debtsAndCredits: RequestWithdraw.Result =
          await requestWithdraw({
            amount: params.amount,
           userId: params.userId
          });

        return debtsAndCredits;
      },
      requestAnticipation: async (
        params: RequestAnticipation.Params
      ): Promise<RequestAnticipation.Result> => {
        const transactions: RequestAnticipation.Result = await requestAnticipation({
          amount: params.amount,
          userId: params.userId
        });

        return transactions;
      },

      hasPendingPaymentRequests: async (
        params: HasPendingPaymentRequests.Params
      ): Promise<HasPendingPaymentRequests.Result> => {
        const balance: HasPendingPaymentRequests.Result = await hasPendingPaymentRequests({
          userId: params.userId
        });
       
        return balance;
      },
      getPaymentRequestByUserId: async (
        params: ListPaymentRequestsByUserId.Params
      ): Promise<ListPaymentRequestsByUserId.Result> => {
        const requests: ListPaymentRequestsByUserId.Result = await getPaymentRequestByUserId({
          userId: params.userId
        });
        set({paymentRequests: requests.data});
        return requests;
      },
    }),
    {
      name: "payments-request-store",
    }
  )
);
