export const removeMoneyMask = (value: string) => {
  return value.replace('R$ ', '').replace('.', '');
}

export const replaceCommaToDot = (value: string) => {
  if (!value) return value;

  return value.replace(',', '.');
}

export const replaceDotToComma = (value: string) => {
  if (!value) return value;

  return value.replace('.', ',');
}

export const removePercentageMask = (value: string) => {
  return value.replace('%', '');
}

export const phoneNumberFormat = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '($1) $2')
  .replace(/(\d{5})(\d)/, '$1-$2')
  .replace(/(-\d{4})\d+?$/, '$1')
}

export const capitalizeFirstLetter = (string: string) => {
  const lowerCaseString = string.toLowerCase();
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
}
