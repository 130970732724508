import React, { useEffect, useState } from "react"
import { GetOrders } from "models/Metrics"
import { Container, Row, Item, Header } from "./styles"
import { Stack, Skeleton, Box, SkeletonCircle } from "@chakra-ui/react";
import Chart, { Props } from 'react-apexcharts';
import { Text } from "styleguide/Typograph"

const ListOrders = (props: { methods }) => {

  const categories = ['pix', 'crédito', 'boleto']
  const [orders, setOrders] = useState<Array<GetOrders.Body>>()
  const [state, setState] = useState<Props>({
    options: {
      chart: {
        id: 'apexchart-example',
        width: '100%'
      },

      labels: ['pix', 'crédito', 'boleto'],
      legend: {
        show: false
      },

    },
    series: [10, 1, 1]
  });

  useEffect(() => {
    // console.log(props.methods.order_results)
    const order_results = props.methods.order_results
    if (order_results?.pix_total && order_results?.credit_total && order_results?.invoice_total) {
      setState({
        options: {
          chart: {
            id: 'apexchart-example',
            width: '100%',
            toolbar: {
              show: false
            }
          },
          labels: ['Pix', 'Crédito', 'Boleto'],
          xaxis: {
            categories: ['Pix', 'Crédito', 'Boleto']
          },
          legend: {
            show: true,
            position: 'top'
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          


        },
        series: [
          parseFloat(order_results.pix_total),
          parseFloat(order_results.credit_total),
          parseFloat(order_results.invoice_total)
        ]
      })
    }
  }, [props.methods]);

  return (
    <Container>
      <Header>
        <Item>
          <Text type="h4">

            Métodos de pagamentos mais utilizados
          </Text>

        </Item>

      </Header>



      <Row>
        <Chart
          options={state.options}
          series={state.series}
          type='donut'
          width='100%'
          height='300px'

        />
      </Row>

    </Container>
  )
}


export default ListOrders;