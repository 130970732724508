// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .preloader-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .preloader-spinner {
    width: 40px;
    height: 40px;
    border: 2px solid #000;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 50%;
    animation: preloader-spin 1s infinite linear;
  }
  
  @keyframes preloader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 600px) {
    .box {
      flex-direction: column;
    }
  }

  
  `, "",{"version":3,"sources":["webpack://./src/views/admin/perfil/UserReports.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,iCAAiC;IACjC,mCAAmC;IACnC,kBAAkB;IAClB,4CAA4C;EAC9C;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF;;EAEA;IACE;MACE,sBAAsB;IACxB;EACF","sourcesContent":[".preloader-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 9999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .preloader-content {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .preloader-spinner {\n    width: 40px;\n    height: 40px;\n    border: 2px solid #000;\n    border-top: 2px solid transparent;\n    border-right: 2px solid transparent;\n    border-radius: 50%;\n    animation: preloader-spin 1s infinite linear;\n  }\n  \n  @keyframes preloader-spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n\n  @media (max-width: 600px) {\n    .box {\n      flex-direction: column;\n    }\n  }\n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
