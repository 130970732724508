import React, {useEffect, useState} from "react";
import { NavLink, useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Fade,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton,
  Stack,
} from "@chakra-ui/react";

import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import api from "services/api";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useEmailStore } from "store/reducers/email";
import axios from "axios";
import $ from "jquery";
import "jquery-mask-plugin";
import "bootstrap";

import {Text as CustomText} from "styleguide/Typograph"
import {CustomInput} from "styleguide/Input"
import {CustomButton} from "styleguide/Button"
import {toastSuccess, toastError} from "styleguide/Toastify"


const Confirm = () => {

  const { push }= useHistory();

  const updateEmail = useEmailStore((state) => state.updateEmail);

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const [loading, setLoading] = React.useState(false);
  const [fade, setFade] =React.useState(false);
  const [reloading, setreLoading] = React.useState(false);
  const [mail_code, setmail_code] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [reemail, setReEmail] = React.useState("");

   useEffect(() => {
      setFade(true)
    },[])


  const handleSignUp = async (e: React.SyntheticEvent) => {
    try {
      updateEmail("");
      e.preventDefault();
      

      setLoading(true);

      const response = await api.post(`${process.env.REACT_APP_API_URL}/mail_active`, {
        mail_code,
        email
      });
      
      toastSuccess("Código validado. Faça seu login")
      setTimeout(() => {
        push("/auth/sign-in");
      }, 1500);
      
    } catch (error: any) {
      console.log({error})
      if (error.response.status === 400) {
        toastError(error.response.data.message)
      } else {
        toastError("Ops! Tivemos um erro em ativar seu email com esse código, tente reenviar o email ou redigitar o código!")
      }
    } finally {
      setLoading(false);
    }
  };

  
  $(() => {
    //$('#cpf').mask('000.000.000-00');
    $('#imagemLogo').attr('style','position:fixed;');
    

  });
  
  const [modalshow, setModal] = React.useState(false);
  const handleClose = () => {
    setModal(false);
    push("/auth/sign-in");
  };
  const handleShow = () => setModal(true);

  const [modalresend, setModalresend] = React.useState(false);
  const handleCloseresend = () => {
    setModalresend(false);
    push("/auth/confirm");
  };
  const handleShowresend = () => setModalresend(true);


  const [modalerro, setModalerro] = React.useState(false);
  const handleCloseerro = () => {
    setModalerro(false);
  };
  const handleShowerro = () => setModalerro(true);

 
  const handleResend = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      

      setreLoading(true);

      await api.post(`${process.env.REACT_APP_API_URL}/mail_resend`, {
        email: reemail
      });
      toastSuccess("Um email com o código de confirmação foi enviado para você!")
      
    } catch (error: any) {
      toastError("Ops! Tivemos um erro em ativar seu email com esse código, tente reenviar o email ou redigitar o código. o código está em seu email, caso você não tenha achado na caixa de entrada, verifique sua caixa de span, pode estar lá.!")
    } finally {
      setreLoading(false);
    }
  };

  const [reenviarEmail, setReenviarEmail] = useState(false)

  function handleReenviarEmail (estado) {
    setReenviarEmail(estado)
  }

  return (
    <Fade in={fade}>

    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        className="mt-0 pt-0"
        maxW={{ base: "100%", md: "max-content", lg: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="auto"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "10px", md: "10px" }}
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
      


      <Modal isOpen={modalshow} onClose={handleClose}>
        <ModalContent>
          <ModalHeader>Confirmado com sucesso!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Vá para tela de login e complete seu cadastro!</ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={handleClose}>
            Ir para o login
          </Button>
        </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalerro} onClose={handleCloseerro}>
        <ModalContent>
          <ModalHeader>Não ativou!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Ops! Tivemos um erro em ativar seu email com esse código, tente reenviar o email ou redigitar o código. o código está em seu email, caso você não tenha achado na caixa de entrada, verifique sua caixa de span, pode estar lá.!</ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={handleCloseerro}>
            Tentar Novamente
          </Button>
        </ModalFooter>
        </ModalContent>
      </Modal>

        <Box me="auto" className="mt-0 pt-0">
          <Heading color={textColor} fontSize="36px" mb="5px" className="cadastro">
            Confirmar sua conta de email!
          </Heading>
          <Text
            className="cadastro"
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Digite o código e confirme.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          className="pt-0"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
          h="100%"
          display={'flex'}
        >
          <Flex align="center"></Flex>
          {!reenviarEmail ? (
            <>
            <form
              onSubmit={(e) => {
                handleSignUp(e);
              }}
            >
              <FormControl>
              <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <CustomInput
                id="email"
                className="input-group-text text-left"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@simmmple.com"
                mb="14px"
                fontWeight="500"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
                
                <FormLabel
                  ms="4px"
                  mt="14px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Código<Text color={brandStars}>*</Text>
                </FormLabel>
                <Stack spacing={4}>
                  <CustomInput
                    id="mail_code"
                    className="input-group-text text-left"
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Código Validação"
                    // ={10}
                    // mt="14px"
                    size="lg"
                    type={"number"}
                    variant="auth"
                    value={mail_code}
                    onChange={(e) => setmail_code(e.target.value)}
                  />
                  <CustomButton
                    isFullWidth={true}
                    type="submit"
                    variant="primary"
                  >
                    {loading ? "Confirmando..." : "Confirmar"}
                  </CustomButton>

                </Stack>

                
              </FormControl>
            </form>
            <Stack mt={2}>
              <span>
              {"Meu codigo nao chegou! "}
                <NavLink to="#">
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                    onClick={() =>  handleReenviarEmail(true)}
                  >
                    Reenviar Email.
                  </Text>

                </NavLink>

              </span>
              <NavLink to="/auth/sign-in">
                <Text
                  color={textColorBrand}
                  as="span"
                  // ms="5px"
                  fontWeight="500"
                >
                {"Já confirmei, ir para o Login"}
                </Text>
              </NavLink>
            </Stack>
            </>
          ) : (
            <Stack spacing={2}>
              <form
                onSubmit={(e) => {
                  handleResend(e);
                }}
              >
                <FormControl paddingTop={"12px"}>
                <FormLabel
                    display="flex"
                    
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Stack spacing={4}>
                    <CustomInput
                      id="email"
                      className="input-group-text text-left"
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="email"
                      placeholder="mail@simmmple.com"
                      mb="14px"
                      fontWeight="500"
                      size="lg"
                      value={reemail}
                      onChange={(e) => setReEmail(e.target.value)}
                      
                    />

                    <CustomButton
                      isFullWidth={true}
                      type="submit"
                      fontSize="sm"
                      variant="primary"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      mb="24px" 
                    >
                      {reloading ? "Reenviando..." : "Reenviar Email"}
                    </CustomButton>
                  </Stack>
                </FormControl>
              </form>
              <span>
              {"Meu codigo chegou! "}
              <NavLink to="#">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                  onClick={() =>  handleReenviarEmail(false)}
                >
                  Voltar para a confirmação.
                </Text>

              </NavLink>

              </span>
            </Stack>
          )}

        </Flex>
      </Flex>
    </DefaultAuth>
    </Fade>
  );
};

export default Confirm;