import { create } from "zustand";
import { persist } from "zustand/middleware";
import { UserData } from "models/User"

interface IPerfilPage {
  onboardingPage: number;
  hasCnpj: boolean,
  setHasCnpj: (value: boolean) => void
  setOnBoardingPage: (value: number) => void
}

export const usePerfilPage = create<IPerfilPage>()(
  persist(
    (set) => ({
      onboardingPage: 0,
      hasCnpj: false,
      setHasCnpj: (value) => set((state) => ({
        ...state,
        hasCnpj: value
      })),
      setOnBoardingPage: (value) => set((state) => ({
        ...state,
        onboardingPage: value
      })),
    }),
    {
      name: "auth",
    }
  )
);