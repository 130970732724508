import styled from "styled-components";
import { Box } from "@chakra-ui/react";

export const StyledNavbar = styled(Box)`
    position: fixed;
    boxShadow: none;
    background-position: center;
    background-cize: cover;
    background-color: #25312F;
    transition-delay: 0s, 0s, 0s, 0s;
    transition-duration: 0.25s, 0.25s, 0.25s, 0s;
    transition-property: box-shadow, background-color, filter, border;
    transition-timing-function: linear, linear, linear, linear;
    display: flex;
    flex-direction: column;
    height: 55px;
    justify-content: center;
    align-items: center;
    line-height: 25.6px;
    right: 0px;
    top: 0px;
    width: 100vw;
    
`;