import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {useEffect} from "react";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import Card from "components/card/Card";
import { SellerIndication, SellerIndicationResponse } from "models/SellerIndication";
import { ListOptionsMenu } from 'components/menu/ListOptionsMenu';

type RowObj = {
  email: string;
  status: string;
  amount_orders_user: string;
};

type CheckTableProps = {
  tableData: SellerIndicationResponse,
  tableName: string,
  select: Function,
  requestDelete: Function,
  page: number,
  setPage: Function
}

const columnHelper = createColumnHelper<SellerIndication>();

// const columns = columnsDataCheck;
export default function CheckTable({ tableData, tableName, select, requestDelete, page, setPage }: CheckTableProps) {
// export default function CheckTable(props: {
//   tableData: SellerIndicationResponse;
//   tableName: any;
//   select: Function;
//   requestDelete: Function;
//   showDetails: Function;
// }) {
  //const { tableData, tableName, select, requestDelete, showDetails } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const columns = [
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          E-mail do Indicado
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
	columnHelper.accessor('status', {
		id: 'status',
		header: () => (
			<Text
				justifyContent='space-between'
				align='center'
				fontSize={{ sm: '10px', lg: '12px' }}
				color='gray.400'>
				Status
			</Text>
		),
		cell: (info) => (
			<Text color={textColor} fontSize='sm' fontWeight='700'>
				{info.getValue()}
			</Text>
		)
	}),
	columnHelper.accessor('amount_orders_user', {
		id: 'amount_orders_user',
		header: () => (
			<Text
				justifyContent='space-between'
				align='center'
				fontSize={{ sm: '10px', lg: '12px' }}
				color='gray.400'>
				TotalVendas
			</Text>
		),
		cell: (info) => (
			<Text color={textColor} fontSize='sm' fontWeight='700'>
				{info.getValue()}
			</Text>
		)
	}),
];

	//const [ data, setData ] = React.useState(() => [ ...tableData ]);
	//const [ data, setData ] = React.useState(() => [ ...tableData ]);
	const [ data, setData ] = React.useState(() => [ ]);
	useEffect(() => {
		if (tableData.length > 0) {
		  setData(tableData);
		}
		
	  },[tableData])
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			// // columnFilters: []
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					{tableName}
				</Text>
				{/* <Menu /> */}
			</Flex>
			<Box>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px' 
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</Box>
		</Card>
	);
} 