import axios from "./api";
import { RequestAnticipation, HasPendingPaymentRequests, PaymentAdvancedRequest, RequestWithdraw, ListPaymentRequestsByUserId } from "models/PaymentRequests";

export const requestAnticipation = async (
  params: RequestAnticipation.Params
): Promise<RequestAnticipation.Result> => {
  const adminUrl = `/seller/anticipation`;
  try {
   
    const balance = await axios.post(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      params
    );
    
    return balance.data as RequestAnticipation.Result;
  } catch (err) {
    console.log(err);
  }
};

export const requestWithdraw = async (
    params: RequestWithdraw.Params
  ): Promise<RequestWithdraw.Result> => {
    const adminUrl = `/seller/withdraw`;
    try {
     
      const balance = await axios.post(
        `${process.env.REACT_APP_API_URL}${adminUrl}`,
        params
      );
      
      return balance.data as RequestWithdraw.Result;
    } catch (err) {
      console.log(err);
    }
  };

  export const hasPendingPaymentRequests = async (
    params: HasPendingPaymentRequests.Params
  ): Promise<HasPendingPaymentRequests.Result> => {
    const adminUrl = `/seller/has-pending-payment-requests/${params.userId}`;
    try {
     
      const balance = await axios.get(
        `${process.env.REACT_APP_API_URL}${adminUrl}`,
        
      );
      
      return balance.data as HasPendingPaymentRequests.Result;
    } catch (err) {
      console.log(err);
    }
  };

  export const getPaymentRequestByUserId = async (
    params: ListPaymentRequestsByUserId.Params
  ): Promise<ListPaymentRequestsByUserId.Result> => {
    const adminUrl = `/seller/requests/user/${params.userId}`;
    try {
     
      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}${adminUrl}`,
        
      );
      
      return request.data as ListPaymentRequestsByUserId.Result;
    } catch (err) {
      console.log(err);
    }
  };