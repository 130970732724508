import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Select
} from '@chakra-ui/react';
import { getOrderReport } from 'services/ordersRequest';
import { exportToExcel } from 'utils/sheet';
import { getOrderStatus, getPaymentType } from 'utils/order';
import { notifyError } from 'utils/notification';
import LoadingPage from 'components/Loading/LoadingPage';
import useStores from 'hooks/stores';
import { useForm } from 'react-hook-form';
import { OrderReportFilters } from 'models/Order';

type OrderReportModalProps = {
  isOpen: boolean
  onClose: Function
}

export function OrderReportModal({ isOpen, onClose }: OrderReportModalProps) {
  const { stores, isLoadingStores } = useStores();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const flexStyle: FlexProps = {
    direction: {base: "column", md: "row"},
    justify: "space-between"
  }
  const boxStyle: BoxProps = {
    w: {base: "100%", md: "48%"},
    py: "10px"
  }

  const {
    register,
    handleSubmit,
  } = useForm<OrderReportFilters>({
    defaultValues: {
      end_date: new Date().toISOString().split('T')[0],
      start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0],
    }
  });

  const generateReportRequest = async (data: OrderReportFilters) => {
    try {
      setLoading(true);

      const response = await getOrderReport(data);

      exportToExcel(response.map(order => {
        return {
          'Pedido': order.order_number,
          'Pedido Shopify': order.shopify_order_number,
          'Loja': order.store,
          'Status': getOrderStatus(order.status),
          'Forma de Pagamento': getPaymentType(order.payment_method),
          'Parcelas': order.installments,
          'Valor': order.total_price,
          'Nome do Comprador': order.customer_name,
          'Email do Comprador': order.customer_email,
          'Telefone do Comprador': order.customer_phone,
          'CPF do Comprador': order.customer_document,
          'Endereço do Comprador': order.customer_address,
          'Itens': order.items,
          'Data': order.date,
        }
      }), `Relatório de Pedidos`);

      onClose();
    } catch (error) {
      notifyError("Erro ao gerar relatório, tente novamente mais tarde.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <ModalContent>
        <LoadingPage isOpen={isLoading || isLoadingStores} />
        <ModalHeader>Gerar Relatório de Pedidos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(generateReportRequest)}>
            <FormControl rowGap='20px'>
              <Box>
                <FormLabel htmlFor="store_id">Loja</FormLabel>
                <Select id="store_id" {...register("store_id")}>
                  <option value="">Todas</option>
                  {
                    stores?.data.map(item => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))
                  }
                </Select>
              </Box>
              <Flex {...flexStyle}>
                <Box {...boxStyle}>
                  <FormLabel htmlFor="start_date">Data de Início</FormLabel>
                  <Input
                    type="date"
                    id="start_date"
                    {...register("start_date")}
                  />
                </Box>
                <Box {...boxStyle}>
                  <FormLabel htmlFor="end_date">Data de Fim</FormLabel>
                  <Input
                    type="date"
                    id="end_date"
                    {...register("end_date")}
                  />
                </Box>
              </Flex>
              <Box display="flex" justifyContent="right" mt="10px">
                <Button type="submit" color="green">
                  Gerar Relatório
                </Button>
              </Box>
            </FormControl>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
