// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  SimpleGrid
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, {useEffect, useState} from "react";
import AccountTable from './AccountTable';
import {usePaymentStore} from "store/reducers/payments/paymentArea"

export default function Default(props: {
  startContent?: JSX.Element;
  endContent?: JSX.Element;
}) {
  const { startContent, endContent } = props;
  const [name, setName] = useState("VISA")
  const [value, setValue] = useState("Dados bancários")
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const {getBalance, getTransactions, balance, transactions} = usePaymentStore();
  const transactionsDatabase = [{
  "bank": balance.bank,
	"agency": balance.agency,
	"account": balance.account,
	"name": balance.name
  }]
 
  return (
    // <Card py='15px'>
    <Flex
      my="auto"
      h="100%"
      align={{ base: 'start', xl: "start" }}
      justify={{ base: "center", xl: 'start' }}
    >
      <React.Fragment>
        
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1, "2xl": 1 }}>
          <AccountTable tableData={transactionsDatabase} />
        </SimpleGrid>
       
      </React.Fragment>
    </Flex>
    
  );
}
