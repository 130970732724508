import {
  GetBalance,
  GetTransactions,
  Transaction,
  GetDebtsAndCredits,
  DebtsAndCredits,
} from "models/Payment";
import { useCallback } from "react";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  getBalance,
  getTransactions,
  getDebtsAndCredits,
} from "services/getTransactions";

interface IPaymentArea {
  balance?: GetBalance.Result;
  transactions?: Transaction[];
  debtsAndCredits?: DebtsAndCredits;
  getTransactions: (
    params: GetTransactions.Params
  ) => Promise<GetTransactions.Result>;
  getBalance: (params: GetBalance.Params) => Promise<GetBalance.Result>;
  getDebtsAndCredit: (
    params: GetDebtsAndCredits.Params
  ) => Promise<GetDebtsAndCredits.Result>;
}

export const usePaymentStore = create<IPaymentArea>()(
  persist(
    (set) => ({
      getDebtsAndCredit: async (
        params: GetDebtsAndCredits.Params
      ): Promise<GetDebtsAndCredits.Result> => {
        const debtsAndCredits: GetDebtsAndCredits.Result =
          await getDebtsAndCredits({
            contractId: params.contractId,
            fromDate: params.fromDate,
            toDate: params.toDate,
          });

        set({ debtsAndCredits: debtsAndCredits.data });
        return debtsAndCredits;
      },
      getTransactions: async (
        params: GetTransactions.Params
      ): Promise<GetTransactions.Result> => {
        const transactions: GetTransactions.Result = await getTransactions({
          contractId: params.contractId,
          fromDate: params.fromDate,
          toDate: params.toDate,
        });

        set({ transactions: transactions.data });
        return transactions;
      },

      getBalance: async (
        params: GetBalance.Params
      ): Promise<GetBalance.Result> => {
        const balance: GetBalance.Result = await getBalance({
          contractId: params.contractId,
        });
        set((state) => ({
          balance: {
            ...state.balance,
            account: balance?.account,
            agency: balance?.agency,
            availableAmount: balance?.availableAmount,
            bank: balance?.bank,
            futureSettlement: balance?.futureSettlement,
            name: balance?.name,
            totalWithdrawn: balance?.totalWithdrawn,
          },
        }));
        return balance;
      },
    }),
    {
      name: "payments-store",
    }
  )
);
