import axios from "./api";
import { useUserStore } from 'store/reducers/user';
import { OrderReport, OrderReportFilters } from 'models/Order';

export const getOrderReport = async (filters: OrderReportFilters): Promise<OrderReport[]> => {
    const token = useUserStore.getState().auth.token;

    const params = {
      start_date: filters.start_date,
      end_date: filters.end_date
    };

    if (filters.store_id) {
        params['store_id'] = filters.store_id;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports/orders`,
      {
          headers: { 'Authorization': `Bearer ${token}` },
          params
      }
    );

    return response.data.data;
};
