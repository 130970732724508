import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, } from "@chakra-ui/react";
import * as React from "react";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {syncProduct} from "services/productsRequest";
import Card from "components/card/Card";
import { Product, ProductResponse } from "models/Product";
import { formatCurrency } from "utils/currency";
import { ListOptionsMenu } from 'components/menu/ListOptionsMenu';
import PaginationButtons from 'components/paginationButtons/PaginationButtons';
import { getProductType } from 'utils/products';
import { notifyInfo, notifyError } from 'utils/notification';
import SourceTag from 'components/tags/SourceTag';
import ShowFilters from 'components/filter/ShowFilters';
import ProductFilters from 'views/admin/cadastros/components/products/ProductFilters';

type CheckTableProps = {
  tableData: ProductResponse,
  tableName: string,
  select: Function,
  showDetails: Function,
  page: number,
  setPage: Function,
  requestDelete: Function,
  refetch: Function,
  setFilters: Function
}

const columnHelper = createColumnHelper<Product>();

export default function CheckTable({ tableData, tableName, select, showDetails, page, setPage, requestDelete, setFilters }: CheckTableProps) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [loadingButton, setLoadingButton] = React.useState(false);

  const syncProductsRequest = async () => {
    try {
      setLoadingButton(true)

      const response = await syncProduct()

      if (response.success) {
        notifyInfo("Solicitação de sincronização feita com sucesso! Em breve os produtos serão sincronizados.")
      } else {
        notifyError("Erro ao sincronizar produtos! Verifique se a loja está conectada.")
      }
    } catch (error) {
      notifyError("Erro ao sincronizar produtos!")
    } finally {
      setLoadingButton(false)
    }
  }

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Nome
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("variations", {
      id: "variations",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Preço
        </Text>
      ),

      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info?.getValue()[0]?.price ? formatCurrency(parseFloat(info?.getValue()[0].price)) : "-"}
        </Text>
      ),

    }),
    columnHelper.accessor("type", {
      id: "type",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Tipo
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {getProductType(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("store.name", {
      id: "store.name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Loja
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("source", {
      id: "source",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Origem
        </Text>
      ),
      cell: (info) => (
        <SourceTag source={info.getValue()} />
      ),
    }),
    {
      id: 'options',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Opções
        </Text>
      ),
      cell: (info: CellContext<Product, string>) => {
        if (info.row.original.source === 'lmexpay') {
          return (
            <ListOptionsMenu
              data={info.row.original}
              showDetails={showDetails}
              select={select}
              deleteRequest={requestDelete}
            />
          );
        } else {
          return (
            <ListOptionsMenu
              data={info.row.original}
              showDetails={showDetails}
            />
          )
        }
      }
    },
  ];

  const data = tableData?.data;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {tableName}
        </Text>
        <Box>
          {!showFilters && (
            <Button
              variant={"solid"}
              colorScheme={"shopify"}
              isLoading={loadingButton}
              onClick={() => {syncProductsRequest()}}
              mr="10px"
              >
                Sincronizar com shopify
            </Button>
          )}
          <ShowFilters showFilters={showFilters} setShowFilters={setShowFilters} />
        </Box>
      </Flex>
      <ProductFilters showFilters={showFilters} setFilters={setFilters} />
      {data && (
        <Box>
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows
                .map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>

          <PaginationButtons
            onClick={setPage}
            currentPage={page}
            lastPage={tableData?.meta?.last_page}
          />

        </Box>
      )}
    </Card>
  );
}
