import React from "react";
import FinishStep from "../components/FinishStep/FinishStep"
import CorrigirStep from "../components/FinishStep/CorrigirStep"
import AguardandoStep from "../components/FinishStep/AguardandoStep"
import Approved from "../components/FinishStep/Approved"
import { useUserStore } from 'store/reducers/user';

const FifthStep = ( ) => {
    
    const user = useUserStore((state) => state.user);

    
    if (user.analise === 'Pedir') {
        return (
            
            <FinishStep/>
        )
    } else if (user.analise === 'Corrigir') {
        return (
            <CorrigirStep/>
        ) 
    } else if (user.analise === 'Aprovado'){
        return (
            <Approved/>
        ) 
    } else {
        return (
            <AguardandoStep/>
        )
    }
}

export default FifthStep;