import React, {useState, useEffect} from "react";
import PersonalDocumentsStep from "../components/PersonalDocumentsStep";
import FinishStep from "../components/FinishStep/FinishStep"
import { Container, Body, Row, RowFooterButton } from "../styles"
import {
    Button,
    Collapse
} from "@chakra-ui/react";
import { usePerfilPage } from "../store/index";
import { useUserStore } from "store/reducers/user";
import DocumentStep from "../components/Documents/DocumentStep";
import {updatePersonalDoc, updateLegalEntityDoc} from "services/userRequests" 
import { updateOnboardingStep } from "services/userRequests";
import { CustomButton } from "styleguide/Button"
import { toastError, toastSuccess } from "styleguide/Toastify";
const FourthStep = () => {
    
    const { onboardingPage, hasCnpj, setHasCnpj, setOnBoardingPage } = usePerfilPage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [updateEntityData, user, updateUserOnboardingStep, updateDocumentsData] = useUserStore(state => [state.updateEntityData, state.user, state.updateUserOnboardingStep, state.updateDocumentsData]);
    
    const [formInfo, setFormInfo] = useState(null);
    const [fade, setFade] = useState(false);
    useEffect(() => {
      setFade(true)
    }, [])



    const handlePreviousStep = () => {
        setOnBoardingPage(2)
    }

    const handleNextStep = async () => {
        // try {

        try{
        
         const data = {
          foto_doc_pessoal_frente: formInfo?.foto_doc_pessoal_frente,
          foto_doc_pessoal_verso: formInfo?.foto_doc_pessoal_verso,
          foto_rosto_frente: formInfo?.foto_rosto_frente,
          comprovante_endereco: formInfo?.comprovante_endereco
        }
        updateDocumentsData(data);
       
        if (onboardingPage == 3) {
    
            const response = await updateOnboardingStep({
              onboarding_step: 4
            });
      
            if (response.success) {
              setOnBoardingPage(4);
            }
      
          } else {
            setOnBoardingPage(4);
          }

        } catch (err) {
            toastError("Encontramos um erro. Tente novamente mais tarde ou contate o suporte");
            console.log({err});
          }
        // setOnBoardingPage(4);
    }

    const handleSkipStep = () => {
        setFade(false)
        setTimeout(() => {
          setOnBoardingPage(3)
        }, 350)
    
      }
    return (
        <React.Fragment>
            

            <DocumentStep setFormIsValid={setIsValidForm} setFormInfo={setFormInfo} />
            
            <RowFooterButton >
            <CustomButton
              isFullWidth={false}
              variant={"tertiary"}
              onClick={handlePreviousStep}
            >
              Voltar
            </CustomButton>
            <CustomButton
              isFullWidth={false}
              disabled={!isValidForm}
              variant={"primary"}
              onClick={handleNextStep}
            >
              Salvar e Avançar
            </CustomButton>
            {
              user.onboarding_step > 3 &&
              <CustomButton
                isFullWidth={false}
                variant={"secondary"}
                onClick={handleSkipStep}
              >
                Próximo
              </CustomButton>
            }
          </RowFooterButton>
        </React.Fragment>
    )
}

export default FourthStep;