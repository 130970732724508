import api from 'services/api';

export async function forgotPassword(email: string): Promise<void> {
  await api.post(
    `${process.env.REACT_APP_API_URL}/forgot_password`,
    {
      email: email
    }
  );
}

export async function resetPassword(
  email: string,
  password: string,
  passwordConfirmation: string,
  token: string
): Promise<void> {
  await api.post(
    `${process.env.REACT_APP_API_URL}/reset_password`,
    {
      email: email,
      token: token,
      password: password,
      'password_confirmation': passwordConfirmation,
    }
  );
}
