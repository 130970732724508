import React from 'react';
import { NavLink, useHistory, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue, Collapse } from "@chakra-ui/react";
import { useUserStore } from 'store/reducers/user';
import axios from 'axios';


export function SidebarLinks(props: { routes: RoutesType[] }) {
  //   Chakra color mode
  const auth = useUserStore((state) => state.auth);
  const [aprovado, setAprovado] = React.useState(false);

	const fetchDataStatus = async () => {
		try {
		  const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/realtime?id=`+auth.user.id,
			{
			  headers: {
				 Authorization: `Bearer ${auth.token}`
			  }
			}
		  );
		  const parsedData = response.data[0];
		  if(!parsedData.complete_register){
			setAprovado(false);
		  }else{
			setAprovado(true);
		  }
		}catch (error) {
		  // Handle error
		}
	  }
	
	  React.useEffect(() => {
		fetchDataStatus();
		const intervalId = setInterval(fetchDataStatus, 15000);
		return () => clearInterval(intervalId);
	  }, []);



  const { push } = useHistory();
  
  // if(!auth.user.activated){
  //   push({pathname:"/auth/continue", state:{name:auth.user.name,email:auth.user.email}});
  // }
  
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes: RoutesType[]) => {
    // Verifica se complete_register é falso
    if (!aprovado) {
      return routes.map((route: RoutesType, index: number) => {
        if (
          (route.layout === "/admin" && route.path === "/perfil") ||
          (route.layout === "/admin" && route.path === "/helpdesk")
        ) {
          const activeSubmenu = route.submenus?.length > 0 && activeRoute(route.path);
          return (
            <NavLink key={index} to={route.layout + route.path}>
              {route.icon ? (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  
                  >
                    <Flex
                      w="100%"
                      alignItems="center"
                      justifyContent="center"
                      
                    >
                      <Box
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeIcon
                            : textColor
                        }
                        me="18px"
                      >
                        {route.icon}
                      </Box>
                      <Text
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : textColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase())
                            ? "bold"
                            : "normal"
                        }
                      >
                        {route.name}
                      </Text>
                    </Flex>
                    <Box
                      h="36px"
                      w="4px"
                      bg={
                        activeRoute(route.path.toLowerCase())
                          ? brandColor
                          : "transparent"
                      }
                      borderRadius="5px"
                    />
                  </HStack>
                  {/* {route.submenus &&
                  <Collapse in={activeSubmenu} unmountOnExit={true}>
                      {route.submenus.map((value: SubmenuRouteType) => {
                        return (
                          <React.Fragment>

                            {value.name}
                          </React.Fragment>
                        )
                      })}
                  </Collapse>
                  } */}
                </Box>
              ) : (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  >
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                    <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                  </HStack>
                </Box>
              )}
            </NavLink>
          );
        }
      });
    } else {
      return routes.map((route: RoutesType, index: number) => {
        const activeSubmenu = route.submenus?.length > 0 && activeRoute(route.path);
        if (
          (route.layout === "/admin" && route.path !== "/alterarEmail" && route.path !== "/alterarSenha")
        ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon ? (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py="5px"
                  ps="10px"
                >
                  <Flex
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                    
                  >
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me="18px"
                    >
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h="36px"
                    w="4px"
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius="5px"
                  />
                </HStack>
                {/* {route.submenus &&
                  <Collapse in={activeSubmenu} unmountOnExit={true}>
                      {route.submenus.map((subRoute: SubmenuRouteType, subIndex: number) => {
                        return (
                  
                            <NavLink key={subIndex} to={route.layout + route.path + subRoute.path}>
                              {subRoute.name}

                            </NavLink>
                         
                        )
                      })}
                  </Collapse>
                  } */}
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py="5px"
                  ps="10px"
                >
                  <Text
                    me="auto"
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                  >
                    {route.name}
                  </Text>
                  <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
        }
      });
    }
  };

  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;

