/* eslint-disable */
// Chakra Imports
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import DesktopHeader from "./components/DesktopHeader";
import MobileHeader from "./components/MobileHeader";
import HeaderContainer from "./components/HeaderContainer"


export default function AdminNavbar(props: {
	secondary: boolean;
	message: string|boolean;
	brandText: string;
	logoText: string;
	fixed: boolean;
	onOpen: (...args: any[]) => any;
}) {
	const [ scrolled, setScrolled ] = useState(false);
	


	useEffect(() => {
		
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});

	let gap = '0px';
	
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	return (
		<HeaderContainer>
			<Flex
				w='100%'
				flexDirection={"row"}
				alignItems={"center"}
				mb={gap}
				h="60px">
					<DesktopHeader/>
					<MobileHeader/>	
				
			</Flex> 
		</HeaderContainer>
	);
}
