import { CouponModel, CouponResponse, CreateCouponRequest } from "models/Coupon";
import api from "services/api";

export async function getCoupons(page: number, token: string) {
  const response = await api.get<CouponResponse>(
    `${process.env.REACT_APP_API_URL}/coupons?page=${page}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export async function createCoupon(data: CreateCouponRequest, token: string): Promise<CouponModel> {
  const response = await api.post<CouponModel>(
    `${process.env.REACT_APP_API_URL}/coupons`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
  );
  return response.data;
}

export async function updateCoupon(id: number, data: CreateCouponRequest, token: string): Promise<CouponModel> {
  const response = await api.put<CouponModel>(
    `${process.env.REACT_APP_API_URL}/coupons/${id}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export async function deleteCoupon(couponId: number, token: string) {
  await api.delete(
    `${process.env.REACT_APP_API_URL}/coupons/${couponId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}
