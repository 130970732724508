import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/css/App.css";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import RegisterLayout from "./layouts/register";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrivateRoute from "views/auth/PrivateRoute";
import "bootstrap";
import Terms from 'views/docs/terms';
import PrivacyPolicy from 'views/docs/privacyPolicy';

const queryClient = new QueryClient();

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <ToastContainer newestOnTop  />
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <PrivateRoute path={`/register`}>
              <Route component={RegisterLayout} />
            </PrivateRoute>
            <PrivateRoute path={`/admin`}>
              <Route component={AdminLayout} />
            </PrivateRoute>
            <Route path={`/termos`} component={Terms} />
            <Route path={`/politica`} component={PrivacyPolicy} />
            <Redirect from="/" to="/auth" />
          </Switch>
        </HashRouter>
      </React.StrictMode>
    </QueryClientProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
