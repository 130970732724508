import { CreateSocialProofRequest, Proof, SocialProof } from 'models/SocialProof';
import { useUserStore } from 'store/reducers/user';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { createSocialProof, updateSocialProof } from 'controllers/SocialProofs';
import LoadingPage from 'components/Loading/LoadingPage';
import {
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  Select,
  Stack,
  StackDivider,
  StatLabel,
  Switch,
  VStack
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import StarsInput from 'components/form/StarsInput';
import { AlertModal, AlertModel } from 'components/modal/AlertModal';
import useStores from 'hooks/stores';
import useStoreProducts from 'hooks/products';

type SocialProofFormProps = {
  switchShow: Function
  show: boolean
  selectedSocialProof?: SocialProof
  handleGetSocialProofs: Function
}

export default function SocialProofForm({ switchShow, show, handleGetSocialProofs, selectedSocialProof = null }: SocialProofFormProps) {
  const token = useUserStore((state) => state.auth.token);
  const apiUrl = process.env.REACT_APP_STORAGE_URL;
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState<AlertModel>(null);

  const getFormProofs = () => {
    const proofs = [];
    if (selectedSocialProof && selectedSocialProof.proofs) {
      for (const proof of selectedSocialProof.proofs) {
        proofs.push(proof);
      }
    }
    if (proofs.length === 0) {
      proofs.push({
        show_stars: true,
        stars: 5
      });
    }
    return proofs;
  }

  const defaultValues = selectedSocialProof ? {
    title: selectedSocialProof.title,
    active: selectedSocialProof.active,
    product_id: selectedSocialProof.product?.id,
    store_id: selectedSocialProof.store.id,
    proofs: getFormProofs()
  } : {
    active: true,
    proofs: [
      {
        show_stars: true,
        stars: 5
      }
    ]
  }

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    control
  } = useForm<CreateSocialProofRequest>({ defaultValues });
  const storeIdValue = watch('store_id');
  const proofs = watch('proofs');
  const activeValue = watch('active');

  const { append, remove } = useFieldArray({
    control,
    name: 'proofs',
  });

  const { stores: storeData } = useStores();
  const { products: productData, isLoadingStoreProducts } = useStoreProducts(storeIdValue);

  const handleSave = async (data: CreateSocialProofRequest) => {
    if (selectedSocialProof) {
      await handleUpdate(data);
    } else {
      await handleCreate(data);
    }
  }

  const handleCreate = async (data: CreateSocialProofRequest) => {
    setLoading(true);
    try {
      const proofs = filterProofs(data);
      await createSocialProof({
        ...data,
        proofs
      }, token);
      handleGetSocialProofs();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      switchShow();
    }
  }

  const handleUpdate = async (data: CreateSocialProofRequest) => {
    setLoading(true);
    try {
      const proofs = filterProofs(data);
      await updateSocialProof(selectedSocialProof.id, {
        ...data,
        proofs
      }, token);

      handleGetSocialProofs()
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      switchShow();
    }
  }

  const filterProofs = (data: CreateSocialProofRequest) => {
    const filteredProofs = data.proofs.filter((proof: Proof) => {
      return (proof.imageFile.length > 0 || proof.image) && proof.description && proof.name;
    })

    return filteredProofs.map((proof: Proof) => {
      if (proof.imageFile.length === 0) {
        delete proof.imageFile;
      } else {
        // @ts-ignore
        proof.imageFile = proof.imageFile[0];
      }
      return proof;
    })
  }

  const renderSocialProof = (proof: Proof, idx: number) => {
    const showStars = proof.show_stars;
    const stars = proof.stars;
    const fileData = proof.imageFile;
    let file = null;
    let url = "";

    if(selectedSocialProof && proof.image) {
      url = `${apiUrl}/${proof.image}`;
    }

    if (fileData?.length) {
      file = fileData[0];
      url = URL.createObjectURL(file);
    }

    return (
      <Box>
        <Flex justify="space-between">
          <FormLabel htmlFor={`proofs.${idx}`}>#{idx+1}</FormLabel>
          {proofs.length > 1 && (
            <CloseButton onClick={() => remove(idx)} />
          )}
        </Flex>
        <Stack direction={'row'}>
          {alert && (
            <Box mt="30px">
              <AlertModal alert={alert} setAlert={setAlert}/>
            </Box>
          )}
          <InputGroup>
            <Stack direction={'column'}>
              <Image
                borderRadius='full'
                border='1px solid black'
                backgroundColor='gray.200'
                boxSize='150px'
                src={url}
              />
              <Input type={'file'} accept="image/png, image/jpeg" w={'20vw'} {...register(`proofs.${idx}.imageFile`, { required: !proof.image })} />
            </Stack>
          </InputGroup>
          <Stack direction={'column'}>
            <FormLabel htmlFor='showStars'>Mostrar Estrelas</FormLabel>
            <Switch
              id='showStars'
              isChecked={showStars}
              onChange={() => {
                setValue(`proofs.${idx}.show_stars`, !showStars)
              }}
            />

            {showStars && (
              <>
                <FormLabel htmlFor={"stars-"+idx}>Avaliação</FormLabel>
                <Box id={"stars-"+idx}>
                  <StarsInput  value={stars} setValue={(value: number) => {
                    setValue(`proofs.${idx}.stars`, value)
                  }} />
                </Box>
              </>
            )}
            <FormLabel>Nome</FormLabel>
            <Input w={'40vw'} type="text"  {...register(`proofs.${idx}.name`, { required: true })} />

            <FormLabel>Depoimento</FormLabel>
            <Input w={'40vw'} type="text" {...register(`proofs.${idx}.description`, { required: true })}/>
          </Stack>
        </Stack>
      </Box>
    );
  }

  React.useEffect(() => {
    if (selectedSocialProof && storeData) {
      setValue('store_id', selectedSocialProof.store.id);
    }
    if (selectedSocialProof && productData) {
      setValue('product_id', selectedSocialProof.product?.id);
    }
  }, [storeData, productData]);

  React.useEffect(() => {
    reset();
  }, [show]);

  return (
    <>
      <LoadingPage isOpen={loading} />
      <Card flexDirection='column' marginTop={'10px'} w='100%' px='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Box pt={{ base: '130px', md: '60px', xl: '40px' }}>
          <form onSubmit={handleSubmit(handleSave)}>
            <FormControl rowGap='20px'>
              <FormLabel htmlFor="title">Titulo *</FormLabel>
              <Input id="title" type="text" {...register("title", { required: true })}/>

              <br/>
              <br/>
              <FormLabel htmlFor='is_free'>Ativa</FormLabel>
              <Switch
                id='active'
                isChecked={activeValue}
                onChange={() => {
                  setValue("active", !activeValue)
                }}
              />


              <br/>
              <br/>
              <FormLabel htmlFor="store_id">Loja *</FormLabel>
              <Select id="store_id" {...register("store_id", { required: true })}>
                <option value="">Selecione uma loja</option>
                {
                  storeData?.data.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </Select>

              {
                storeIdValue && (
                  <>
                    <br/>
                    <FormLabel htmlFor="product_id">Produto</FormLabel>
                    <Select disabled={isLoadingStoreProducts} id="product_id" {...register("product_id")}>
                      <option value="">{isLoadingStoreProducts ? "Buscando Produtos..." : "Todos"}</option>
                      {
                        productData?.data.map(item => {
                          return (
                            <option key={item.id} value={item.id}>{item.name}</option>
                          );
                        })
                      }
                    </Select>
                  </>
                )
              }

              <br />
              <br />
              <StatLabel>Depoimentos</StatLabel>

              <VStack
                divider={<StackDivider borderColor='gray.200' />}
                spacing={4}
                align='stretch'
              >
                {proofs &&
                  proofs.map((proof, index) =>
                    renderSocialProof(proof, index))
                }
              </VStack>
              <br/>

              {proofs && proofs.length < 3 && (
                <Flex mb="10px" justify="right">
                  <Button
                    type="button"
                    colorScheme="blue"
                    onClick={() => append({
                      show_stars: true,
                      stars: 5
                    })}
                  >
                    Adicionar Depoimento
                  </Button>
                </Flex>
              )}

              <Button type='submit'>Salvar</Button>
              <Button onClick={() => switchShow()}>Voltar</Button>
            </FormControl>
          </form>
        </Box>
      </Card>
    </>
  );
}
