import { CreateProductRequest, CreateVariationRequest, Product, ProductResponse, Variation } from "models/Product";
import axios from 'axios';
import { axiosConfig, getHeader } from '../config';
import { filtersToUrl } from 'utils/filters';

const productsApi = axios.create({
  ...axiosConfig,
  baseURL: axiosConfig.baseURL + 'products'
})

const productImageApi = axios.create({
  ...axiosConfig,
  baseURL: axiosConfig.baseURL + 'products/images'
})

const productVariationApi = axios.create({
  ...axiosConfig,
  baseURL: axiosConfig.baseURL + 'products/variations'
})

export async function getProducts(page: number = null, filters = {}, list = false) {
  let url = page ? `?page=${page}` : "?paginate=false";
  url += filtersToUrl(filters);

  if (list) {
    url += "&list=true";
  }

  const response = await productsApi.get<ProductResponse>(url, {
    headers: getHeader()
  });

  return response.data;
}

export async function createProduct(data: CreateProductRequest): Promise<Product> {
  const response = await productsApi.post<Product>(
    "",
    data,
    {
      headers: {
        ...getHeader(),
        'Content-Type': 'multipart/form-data',
      }
    }
  );
  return response.data;
}

export async function updateProduct(id: number, data: CreateProductRequest): Promise<Product> {
  const response = await productsApi.post<Product>(
    `${id}`,
    {
      ...data,
      "_method": "PUT"
    },
    {
      headers: {
        ...getHeader(),
        'Content-Type': 'multipart/form-data',
      }
    }
  );
  return response.data;
}

export async function createOrUpdateVariation(data: CreateVariationRequest, productId: number): Promise<Variation> {
  const response = await productVariationApi.post<Variation>(
    '',
    {
      ...data,
      product_id: productId,
    },
    {
      headers: {
        ...getHeader(),
        'Content-Type': 'multipart/form-data',
      }
    }
  );
  return response.data;
}

export async function deleteProduct(id: number) {
  await productsApi.delete(`${id}`, {
    headers: getHeader()
  });
}

export async function deleteProductImage(id: number) {
  await productImageApi.delete(`${id}`, {
    headers: getHeader()
  });
}

export async function deleteProductVariation(id: number) {
  await productVariationApi.delete(`${id}`, {
    headers: getHeader()
  });
}
