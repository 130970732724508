import { useQuery } from '@tanstack/react-query';
import { notifyError } from 'utils/notification';
import { getProducts } from 'controllers/Products';

const useStoreProducts = (storeId: number) => {
  const filter = {
    store_id: storeId,
  }

  const { data: products, isLoading: isLoadingStoreProducts } = useQuery(
    ["getAllStoreProducts", filter],
    () => getProducts(null, filter, true),
    {
      enabled: !!storeId,
      onError: (error) => {
        notifyError('Erro ao buscar produtos. Recarregue a página')
      },
    }
  );

  return { products, isLoadingStoreProducts };
};

export default useStoreProducts;
