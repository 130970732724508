import { CheckoutResponse, CreateCheckoutRequest } from "models/checkout";
import api from "services/api";
import { notifyError } from "utils/notification";

export async function getCheckouts(token: string) {
  try {
    const response = await api.get<CheckoutResponse>(
      `${process.env.REACT_APP_API_URL}/checkouts`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer os checkouts cadastrados.");
  }
}

export async function createCheckout(data: CreateCheckoutRequest, token: string) {
  const response = await api.post<CheckoutResponse>(
    `${process.env.REACT_APP_API_URL}/checkouts`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
}

export async function updateCheckout(id: number, data: CreateCheckoutRequest, token: string) {
  const response = await api.post<CheckoutResponse>(
    `${process.env.REACT_APP_API_URL}/checkouts/${id}`,
    {
      ...data,
      "_method": "PUT"
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
}

export async function deleteCheckout(id: number, token: string): Promise<void> {
  try {
    const response = await api.delete<void>(
      `${process.env.REACT_APP_API_URL}/checkouts/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao deletar seu checkout.");
  }
}
