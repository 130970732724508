import { useQuery } from '@tanstack/react-query';
import { useUserStore } from 'store/reducers/user';
import { getStores } from 'controllers/Stores';
import { notifyError } from '../utils/notification';

const useStores = () => {
  const token = useUserStore((state) => state.auth.token);

  const { data: stores, isLoading: isLoadingStores } = useQuery(
    ["getAllStores"],
    () => getStores(token),
    {
      onError: (error) => {
        notifyError('Erro ao buscar lojas. Recarregue a página')
      },
    }
  );

  return { stores, isLoadingStores };
};

export default useStores;
