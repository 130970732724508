import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Select,
  Input,
  Button,
  Collapse
} from "@chakra-ui/react";
import { useForm } from "react-hook-form"
import axios from "axios";
import { useUserStore } from 'store/reducers/user';
import { LegalEntityData } from "../../models/types"
import { FormRow, Row, Label, TextCenter, DisplayRow } from "../../styles";
import InputMask from "react-input-mask"
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import { event } from "jquery";
import { Text as CustomText } from "styleguide/Typograph"
import { CustomInput } from "styleguide/Input"
import { CustomSelect } from "styleguide/Select"
import { Container, ContainerCollapse } from "./styles"

const LegalEntityDataStep = ({ setFormIsValid, setFormInfo }) => {

  const [auth, user] = useUserStore((state) => [state.auth, state.user]);


  const [activeTab, setActiveTab] = React.useState(2);
  const [isLoading, setIsLoading] = React.useState(false);

  const [erro, setErro] = React.useState("");
  const [openCep, setOpenCep] = React.useState(false);
  useEffect(() => {
    handleCep("cnpj_zipcode", user.cnpj_zipcode)
  }, [])



  const { watch, setValue } =
    useForm<LegalEntityData>({
      defaultValues: {
        cnpj: user?.cnpj,
        cnpj_razao: user?.cnpj_razao,
        cnpj_social: user?.cnpj_social,
        cnpj_zipcode: user?.cnpj_zipcode,
        cnpj_address: user?.cnpj_address,
        cnpj_city: user?.cnpj_city,
        cnpj_state: user?.cnpj_state,
        cnpj_complement: user?.cnpj_complement,
        cnpj_number: user?.cnpj_number,
        neighborhood: ""
      },
    });
  const formData = watch();

  const validateRazao = () => {
    return formData.cnpj_razao?.length >= 5;
  };

  const validateCnpj = () => {
    return formData.cnpj?.replace(/[^0-9]/g, "").length >= 11;
  };

  const validateSocial = () => {
    return formData.cnpj_social?.length >= 1;
  };

  const validateCEP = () => {
    return formData.cnpj_zipcode?.replace(/[^0-9]/g, "").length >= 1;
  }

  const validateAddress = () => {
    return formData.cnpj_address?.length >= 8;
  };

  const validateNeighbor = () => {
    return formData.neighborhood?.length > 0;
  };

  const validateCity = () => {
    return formData.cnpj_city?.length > 0;
  };

  const validateState = () => {
    return formData.cnpj_state?.length == 2;
  };

  const validateNumber = () => {
    return formData.cnpj_number?.length > 0;
  };

  const allowToSubmit = () => {
    return validateRazao() && validateCnpj() && validateSocial() && validateCEP() && validateCity() &&
      validateState() && validateNumber();
  }

  useEffect(() => {
    if (allowToSubmit()) {
      setFormIsValid(true);
      setFormInfo(formData)
    } else {
      setFormIsValid(false);
    }
  }, [allowToSubmit()])

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValue(name, value);


  };

  const handleCepChange = (event) => {
    const { name, value } = event.target;

    handleCep(name, value);
  }



  const handleCep = (name, value) => {

    setValue(name, value);

    if (validateCEP()) {
      const newZipcode = formData.cnpj_zipcode?.replace(/[^0-9]/g, "");
      if (newZipcode.length == 8) {
        axios
          .get(`https://viacep.com.br/ws/${newZipcode}/json/`)
          .then((response) => {
            if (response.data.erro) {
              setErro('Seu cep não foi encontrado ou está incorreto, por favor digite novamente!');
              setValue("cnpj_zipcode", "");
            } else {
              setValue("cnpj_address", response.data.logradouro);
              setValue("cnpj_city", response.data.localidade);
              setValue("cnpj_state", response.data.uf);
              setOpenCep(true);
            }
          })
          .catch();
      }
    }
  };

  const handleCnpjValidation = () => {
    if (formData.cnpj) {
      const cnpj = formData.cnpj.replace(/[^0-9]/g, "")
      return cnpjValidator.isValid(cnpj) || cnpj.length === 0
    } else {
      return true
    }
  };

  return (
    <React.Fragment>
      <Row>
        <TextCenter>

          <CustomText type={"h1"}>
            Dados Pessoa Jurídica
          </CustomText>
        </TextCenter>
      </Row>
      <Row>
        <TextCenter>

          <CustomText type={"h6"}>
            Informe os dados da sua empresa
          </CustomText>
        </TextCenter>
      </Row>
      <Container style={{ marginTop: '20px' }}>
        <React.Fragment>
          <FormRow>
            <CustomText type={"h7"}>Cnpj</CustomText>
            <CustomInput
              as={InputMask}
              mask={"**.***.***/****-**"}
              name="cnpj"
              value={formData.cnpj}
              placeholder="00.000.000/0001-00"
              onChange={handleInputChange}
              onBlur={handleCnpjValidation}
              required
            />
            {!handleCnpjValidation() && <span style={{ color: 'red' }}>CNPJ inválido</span>}
          </FormRow>

          <FormRow>
            <CustomText type={"h7"}>Razão Social (30 max. caracteres)</CustomText>
            <CustomInput
              name="cnpj_razao"
              maxLength={30}
              value={formData.cnpj_razao}
              onChange={handleInputChange}
              required
            />
          </FormRow>

          <FormRow>
            <CustomText type={"h7"}>Nome Fantasia</CustomText>
            <CustomInput
              name="cnpj_social"
              value={formData.cnpj_social}
              onChange={handleInputChange}
              required
            />
          </FormRow>

          <FormRow>
            <CustomText type={"h7"}>CEP</CustomText>
            <CustomInput
              name="cnpj_zipcode"
              value={formData.cnpj_zipcode}
              as={InputMask}
              mask="**.***-***"
              onKeyUp={handleCepChange}
              onChange={handleInputChange}
              required
            />
          </FormRow>

          <Collapse in={openCep}>
            <ContainerCollapse>
              <FormRow>
                <CustomText type={"h7"}>Endereço</CustomText>
                <CustomInput
                  name="cnpj_address"
                  value={formData.cnpj_address}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>

              <FormRow>
                <CustomText type={"h7"}>Cidade</CustomText>
                <CustomInput
                  name="cnpj_city"
                  value={formData.cnpj_city}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
              <FormRow>
                <CustomText type={"h7"}>Bairro</CustomText>
                <CustomInput
                  name="neighborhood"
                  value={formData.neighborhood}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>

              <FormRow>
                <CustomText type={"h7"}>Estado</CustomText>
                <CustomInput
                  name="cnpj_state"
                  value={formData.cnpj_state}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>

              <FormRow>
                <CustomText type={"h7"}>Complemento</CustomText>
                <CustomInput
                  name="cnpj_complement"
                  value={formData.cnpj_complement}
                  onChange={handleInputChange}
                />
              </FormRow>

              <FormRow>
                <CustomText type={"h7"}>Número</CustomText>
                <CustomInput
                  name="cnpj_number"
                  value={formData.cnpj_number}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
            </ContainerCollapse>
          </Collapse>
        </React.Fragment>
      </Container>
    </React.Fragment>
  )
}

export default LegalEntityDataStep;