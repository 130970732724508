import React from 'react'
import { Store, StoreType } from 'models/Store';
import { Box, Button, Divider, Flex, Link, Stack, StackDivider, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import DetailsText from 'components/details/DetailsText';
import SourceTag from 'components/tags/SourceTag';
import StatusTag from 'components/tags/StatusTag';
import { reinstallCheckout } from 'controllers/Stores';
import { notifyError, notifySuccess } from 'utils/notification';
import LoadingPage from 'components/Loading/LoadingPage';

type StoreDetailsProps = {
  store: Store
}

export default function StoreDetails({ store }: StoreDetailsProps) {
  const [loading, setLoading] = React.useState(false);

  const handleReinstallCheckout = async () => {
    try {
      setLoading(true);

      await reinstallCheckout(store.id);
      notifySuccess("Checkout reinstalado com sucesso. Em alguns instantes estará disponível.");
    } catch (error: any) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      flexDirection="column"
      marginTop={"10px"}
      w="100%"
      px="20px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <LoadingPage isOpen={loading} />
      <Box padding="10px 20px">
        <Flex justifyContent="space-between">
          <Text fontSize="32px" fontWeight="bold">
            {store.name}
          </Text>
          {store.type === StoreType.SHOPIFY && (
            <Button colorScheme='purple' onClick={() => handleReinstallCheckout()}>Reinstalar Checkout</Button>
          )}
        </Flex>
        <Flex>
          <StatusTag status={store.active} />
          <Box width="10px"  />
          <SourceTag source={store.type} />
        </Flex>
        <Box py='20px'>
          <DetailsText title={"Descrição"} value={store.description} />
          <DetailsText title={"Id da loja"} value={store.uuid} />
        </Box>
        <Divider />
        <Stack
          direction={{ base: 'column', xl: 'row' }}
          divider={<StackDivider borderColor={{ base: 'gray.200', xl: 'white' }} />}
          spacing='24px'
          pt='20px'
        >
          <Box>
            <DetailsText title={"Email"} value={store.email} />
            <DetailsText title={"Telefone do Suporte"} value={store.support_phone} />
            <Link href={store.site} target="_blank">
              <DetailsText title={"Site"} value={store.site} />
            </Link>
            {store.url && (
              <Link href={store.url} target="_blank">
                <DetailsText title={"Url Shopify"} value={store.url} />
              </Link>
            )}
          </Box>
        </Stack>
      </Box>
    </Card>
  )
}
