import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Checkbox } from "@chakra-ui/react";
import { useUserStore } from "store/reducers/user";
import axios from "axios";
import { usePerfilPage } from "../../store/index";
import { BankAccountData } from "../../models/types";
import { FormRow, Row, Label, TextCenter, DisplayRow } from "../../styles";
import { Container } from "./styles";
import { Text as CustomText } from "styleguide/Typograph";
import { CustomInput } from "styleguide/Input";
import SearchableSelect from "components/form/SearchableSelect";

const PersonalBankingStep = ({ setFormIsValid, setFormInfo }) => {
  const [dataselectb, setDataselectb] = React.useState([]);
  const [dataDict, setDataDict] = React.useState({});

  const [auth, user] = useUserStore((state) => [state.auth, state.user]);
  const explodeConta = (
    agencia: string | null
  ): { numero: string; digito: string } => {
    if (!agencia || !agencia.includes("-") || agencia.length === 0) {
      return null;
    }

    // Divida a string da agência em duas partes usando o hífen como delimitador
    const [numero, digito] = agencia.split("-");

    // Se qualquer parte estiver faltando ou o dígito tiver mais de um caractere, retorne null
    if (!numero || !digito || digito.length !== 1) {
      return null;
    }

    // Retorne o número da agência e o último dígito
    return {
      numero,
      digito,
    };
  };

  const { watch, setValue } = useForm<BankAccountData>({
    defaultValues: {
      banco_id: user?.banco_id!,
      agencia: explodeConta(user?.agencia)?.numero! || user?.agencia,
      agenciaLast: explodeConta(user?.agencia)?.digito!,
      conta: explodeConta(user?.conta)?.numero! || user?.conta,
      contaLast: (explodeConta(user?.conta)?.digito !== undefined) ? explodeConta(user?.conta)?.digito! : '0',
      n_pix: user?.n_pix!,
      checkForm: false,
    },
  });

  const formData = watch();

  const fetchData = useCallback(async () => {
    try {
      const parsedDataB = [
        [
            "00000000",
            "XP INVESTIMENTOS",
            "102",
            "Sim",
            "RSFN",
            "XP Investimentos",
            "22/04/2002"
        ],
        [
            "00000000",
            "CAIXA ECONÔMICA",
            "104",
            "Sim",
            "RSFN",
            "Caixa Econômica",
            "22/04/2002"
        ],
        [
            "00000000",
            "AGIBANK",
            "121",
            "Sim",
            "RSFN",
            "Agibank",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO GENIAL",
            "125",
            "Sim",
            "RSFN",
            "Banco Genial",
            "22/04/2002"
        ],
        [
            "00000000",
            "CRESOL",
            "133",
            "Sim",
            "RSFN",
            "Cresol",
            "22/04/2002"
        ],
        [
            "00000000",
            "UNICRED",
            "136",
            "Sim",
            "RSFN",
            "Unicred",
            "22/04/2002"
        ],
        [
            "00000000",
            "BRL TRUST DTVM",
            "173",
            "Sim",
            "RSFN",
            "BRL Trust DTVM",
            "22/04/2002"
        ],
        [
            "00000000",
            "PEFISA",
            "174",
            "Sim",
            "RSFN",
            "Pefisa",
            "22/04/2002"
        ],
        [
            "00000000",
            "STONE",
            "197",
            "Sim",
            "RSFN",
            "Stone",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO BTG PACTUAL",
            "208",
            "Sim",
            "RSFN",
            "Banco BTG Pactual",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO ORIGINAL",
            "212",
            "Sim",
            "RSFN",
            "Banco Original",
            "22/04/2002"
        ],
        [
            "00000000",
            "BS2",
            "218",
            "Sim",
            "RSFN",
            "BS2",
            "22/04/2002"
        ],
        [
            "00000000",
            "BCO FIBRA S.A",
            "224",
            "Sim",
            "RSFN",
            "BCO FIBRA S.A",
            "22/04/2002"
        ],
        [
            "00000000",
            "BRADESCO/NEXT",
            "237",
            "Sim",
            "RSFN",
            "Bradesco/Next",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO ABC BRASIL",
            "246",
            "Sim",
            "RSFN",
            "Banco ABC Brasil",
            "22/04/2002"
        ],
        [
            "00000000",
            "NUBANK",
            "260",
            "Sim",
            "RSFN",
            "Nubank",
            "22/04/2002"
        ],
        [
            "00000000",
            "CCR DE SÃO MIGUEL DO OESTE",
            "273",
            "Sim",
            "RSFN",
            "CCR de São Miguel do Oeste",
            "22/04/2002"
        ],
        [
            "00000000",
            "MONEY PLUS",
            "274",
            "Sim",
            "RSFN",
            "Money Plus",
            "22/04/2002"
        ],
        [
            "00000000",
            "PAGSEGURO",
            "290",
            "Sim",
            "RSFN",
            "Pagseguro",
            "22/04/2002"
        ],
        [
            "00000000",
            "PJBANK",
            "301",
            "Sim",
            "RSFN",
            "PJBank",
            "22/04/2002"
        ],
        [
            "00000000",
            "VORTX DTVM LTDA",
            "310",
            "Sim",
            "RSFN",
            "VORTX DTVM LTDA",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO BMG",
            "318",
            "Sim",
            "RSFN",
            "Banco BMG",
            "22/04/2002"
        ],
        [
            "00000000",
            "MERCADO PAGO",
            "323",
            "Sim",
            "RSFN",
            "Mercado Pago",
            "22/04/2002"
        ],
        [
            "00000000",
            "QI SOCIEDADE DE CRÉDITO",
            "329",
            "Sim",
            "RSFN",
            "QI Sociedade de Crédito",
            "22/04/2002"
        ],
        [
            "00000000",
            "ACESSO SOLUÇÕES DE PAGAMENTO",
            "332",
            "Sim",
            "RSFN",
            "Acesso Soluções de Pagamento",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO C6",
            "336",
            "Sim",
            "RSFN",
            "Banco C6",
            "22/04/2002"
        ],
        [
            "00000000",
            "ITAÚ",
            "341",
            "Sim",
            "RSFN",
            "Itaú",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO XP S.A.",
            "348",
            "Sim",
            "RSFN",
            "BANCO XP S.A.",
            "22/04/2002"
        ],
        [
            "00000000",
            "ÓTIMO",
            "355",
            "Sim",
            "RSFN",
            "Ótimo",
            "22/04/2002"
        ],
        [
            "00000000",
            "SINGULARE",
            "363",
            "Sim",
            "RSFN",
            "Singulare",
            "22/04/2002"
        ],
        [
            "00000000",
            "GERENCIANET PAGAMENTOS DO BRASIL",
            "364",
            "Sim",
            "RSFN",
            "Gerencianet Pagamentos do Brasil",
            "22/04/2002"
        ],
        [
            "00000000",
            "JP MORGAN",
            "376",
            "Sim",
            "RSFN",
            "JP Morgan",
            "22/04/2002"
        ],
        [
            "00000000",
            "PICPAY",
            "380",
            "Sim",
            "RSFN",
            "Picpay",
            "22/04/2002"
        ],
        [
            "00000000",
            "JUNO",
            "383",
            "Sim",
            "RSFN",
            "Juno",
            "22/04/2002"
        ],
        [
            "00000000",
            "GLOBAL SCM",
            "384",
            "Sim",
            "RSFN",
            "Global SCM",
            "22/04/2002"
        ],
        [
            "00000000",
            "MERCANTIL DO BRASIL",
            "389",
            "Sim",
            "RSFN",
            "Mercantil do Brasil",
            "22/04/2002"
        ],
        [
            "00000000",
            "CORA",
            "403",
            "Sim",
            "RSFN",
            "Cora",
            "22/04/2002"
        ],
        [
            "00000000",
            "SUMUP SCD S.A.",
            "404",
            "Sim",
            "RSFN",
            "SUMUP SCD S.A.",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO BV S.A.",
            "413",
            "Sim",
            "RSFN",
            "BANCO BV S.A.",
            "22/04/2002"
        ],
        [
            "00000000",
            "SAFRA",
            "422",
            "Sim",
            "RSFN",
            "Safra",
            "22/04/2002"
        ],
        [
            "00000000",
            "FITBANK",
            "450",
            "Sim",
            "RSFN",
            "Fitbank",
            "22/04/2002"
        ],
        [
            "00000000",
            "J17 - SCD S/A",
            "451",
            "Sim",
            "RSFN",
            "J17 - SCD S/A",
            "22/04/2002"
        ],
        [
            "00000000",
            "UY3 SCD S/A",
            "457",
            "Sim",
            "RSFN",
            "UY3 SCD S/A",
            "22/04/2002"
        ],
        [
            "00000000",
            "ASAAS IP",
            "461",
            "Sim",
            "RSFN",
            "ASAAS IP",
            "22/04/2002"
        ],
        [
            "00000000",
            "CELCOIN INSTITUICAO DE PAGAMENTO S.A.",
            "509",
            "Sim",
            "RSFN",
            "CELCOIN INSTITUICAO DE PAGAMENTO S.A.",
            "22/04/2002"
        ],
        [
            "00000000",
            "HR DIGITAL SOCIEDADE DE CRÉDITO DIRETO S.A",
            "523",
            "Sim",
            "RSFN",
            "HR Digital Sociedade de Crédito Direto S.A",
            "22/04/2002"
        ],
        [
            "00000000",
            "PINBANK",
            "529",
            "Sim",
            "RSFN",
            "Pinbank",
            "22/04/2002"
        ],
        [
            "00000000",
            "NEON PAGAMENTOS S.A. IP",
            "536",
            "Sim",
            "RSFN",
            "Neon Pagamentos S.A. IP",
            "22/04/2002"
        ],
        [
            "00000000",
            "CLOUD WALK MEIOS DE PAGAMENTOS E SERVICOS LTDA",
            "542",
            "Sim",
            "RSFN",
            "CLOUD WALK MEIOS DE PAGAMENTOS E SERVICOS LTDA",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO OMNI",
            "613",
            "Sim",
            "RSFN",
            "Banco Omni",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO PAN",
            "623",
            "Sim",
            "RSFN",
            "Banco Pan",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO LETSBANK S.A",
            "630",
            "Sim",
            "RSFN",
            "Banco Letsbank S.A",
            "22/04/2002"
        ],
        [
            "00000000",
            "RENDIMENTO",
            "633",
            "Sim",
            "RSFN",
            "Rendimento",
            "22/04/2002"
        ],
        [
            "00000000",
            "BCO SOFISA S.A.",
            "637",
            "Sim",
            "RSFN",
            "BCO SOFISA S.A.",
            "22/04/2002"
        ],
        [
            "00000000",
            "NEON/VOTORANTIM",
            "655",
            "Sim",
            "RSFN",
            "Neon/Votorantim",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO DAYCOVAL",
            "707",
            "Sim",
            "RSFN",
            "Banco Daycoval",
            "22/04/2002"
        ],
        [
            "00000000",
            "NEON",
            "735",
            "Sim",
            "RSFN",
            "Neon",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO RIBEIRÃO PRETO",
            "741",
            "Sim",
            "RSFN",
            "Banco Ribeirão Preto",
            "22/04/2002"
        ],
        [
            "00000000",
            "CITIBANK",
            "745",
            "Sim",
            "RSFN",
            "Citibank",
            "22/04/2002"
        ],
        [
            "00000000",
            "MODAL",
            "746",
            "Sim",
            "RSFN",
            "Modal",
            "22/04/2002"
        ],
        [
            "00000000",
            "SICREDI",
            "748",
            "Sim",
            "RSFN",
            "Sicredi",
            "22/04/2002"
        ],
        [
            "00000000",
            "BNP PARIBAS BRASIL",
            "752",
            "Sim",
            "RSFN",
            "BNP Paribas Brasil",
            "22/04/2002"
        ],
        [
            "00000000",
            "SICOOB",
            "756",
            "Sim",
            "RSFN",
            "Sicoob",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO DO BRASIL",
            "001",
            "Sim",
            "RSFN",
            "Banco do Brasil",
            "22/04/2002"
        ],
        [
            "00000000",
            "SANTANDER",
            "033",
            "Sim",
            "RSFN",
            "Santander",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANRISUL",
            "041",
            "Sim",
            "RSFN",
            "Banrisul",
            "22/04/2002"
        ],
        [
            "00000000",
            "INTER",
            "077",
            "Sim",
            "RSFN",
            "Inter",
            "22/04/2002"
        ],
        [
            "00000000",
            "BRB",
            "070",
            "Sim",
            "RSFN",
            "BRB",
            "22/04/2002"
        ],
        [
            "00000000",
            "VIA CREDI/CIVIA COOPERATIVA",
            "085",
            "Sim",
            "RSFN",
            "Via Credi/Civia Cooperativa",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANESTES",
            "021",
            "Sim",
            "RSFN",
            "Banestes",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO TOPAZIO",
            "082",
            "Sim",
            "RSFN",
            "Banco Topazio",
            "22/04/2002"
        ],
        [
            "00000000",
            "UNIPRIME",
            "099",
            "Sim",
            "RSFN",
            "Uniprime",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO DO NORDESTE",
            "004",
            "Sim",
            "RSFN",
            "Banco do Nordeste",
            "22/04/2002"
        ],
        [
            "00000000",
            "COOPERATIVA CENTRAL DE CREDITO NOROESTE BRASILEIRO",
            "097",
            "Sim",
            "RSFN",
            "Cooperativa Central de Credito Noroeste Brasileiro",
            "22/04/2002"
        ],
        [
            "00000000",
            "UNIPRIME NORTE DO PARANÁ",
            "084",
            "Sim",
            "RSFN",
            "Uniprime Norte do Paraná",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO DA AMAZONIA",
            "003",
            "Sim",
            "RSFN",
            "Banco da Amazonia",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO BANESE",
            "047",
            "Sim",
            "RSFN",
            "Banco Banese",
            "22/04/2002"
        ],
        [
            "00000000",
            "POLOCRED",
            "093",
            "Sim",
            "RSFN",
            "Polocred",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANPARÁ",
            "037",
            "Sim",
            "RSFN",
            "Banpará",
            "22/04/2002"
        ],
        [
            "00000000",
            "BANCO CAPITAL S.A",
            "412-x",
            "Sim",
            "RSFN",
            "Banco Capital S.A",
            "22/04/2002"
        ],
        [
            "00000000",
            "CREDISAN",
            "089",
            "Sim",
            "RSFN",
            "Credisan",
            "22/04/2002"
        ],
        [
            "00000000",
            "CREFISA",
            "069",
            "Sim",
            "RSFN",
            "Crefisa",
            "22/04/2002"
        ]
    ];

      let preFilteredparsedDataB = parsedDataB
        .filter(
          (field) => field[2] !== "n/a" && field[2] !== "" && field[2] !== null
        )
        .sort((a, b) => {
          const valueA = a[1] || "";
          const valueB = b[1] || "";
          return valueA.localeCompare(valueB);
        });

      setDataselectb(preFilteredparsedDataB);

      if (user.banco_id) {
        setValue("banco_id", user.banco_id);
      }
    } catch (error) {
      // Handle error
    }
  }, []);

  const validateBancoId = () => {
    return !!formData.banco_id;
  };

  const validateAgencia = () => {
    return !!formData.agencia;
  };

  const validateConta = () => {
    return !!formData.conta;
  };

  const validatePix = () => {
    return !!formData.n_pix;
  };

  const validateCheckForm = () => {
    return formData.checkForm;
  };

  const allowToSubmit = () => {
    // console.log({
    //   bancoId: validateBancoId(),
    //   agencia: validateAgencia(),
    //   conta: validateConta(),
    //   pix: validatePix(),
    //   check: validateCheckForm()
    // })

    return (
      validateBancoId() &&
      validateAgencia() &&
      validateConta() &&
      validatePix() &&
      validateCheckForm()
    );
  };
  useEffect(() => {
    setFormIsValid(allowToSubmit());
    if (allowToSubmit()) {
      setFormInfo({
        banco_id: dataDict && formData.banco_id && dataDict[formData.banco_id] ? dataDict[formData.banco_id] : formData.banco_id,
        conta: `${formData.conta}-${formData.contaLast || 0}`,
        agencia: `${formData.agencia}-${formData.agenciaLast || 0}`,
        n_pix: formData.n_pix,
      });
    }
  }, [allowToSubmit()]);

  // useEffect(() => {
  //   const primeiroArray = {
  //     "001": "Banco do Brasil",
  //     "033": "Santander",
  //     "104": "Caixa Econômica",
  //     "237": "Bradesco/Next",
  //     "341": "Itaú",
  //     "041": "Banrisul",
  //     "748": "Sicredi",
  //     "756": "Sicoob",
  //     "077": "Inter",
  //     "070": "BRB",
  //     "085": "Via Credi/Civia Cooperativa",
  //     "655": "Neon/Votorantim",
  //     "260": "Nubank",
  //     "290": "Pagseguro",
  //     "212": "Banco Original",
  //     "422": "Safra",
  //     "746": "Modal",
  //     "021": "Banestes",
  //     "136": "Unicred",
  //     "274": "Money Plus",
  //     "389": "Mercantil do Brasil",
  //     "376": "JP Morgan",
  //     "364": "Gerencianet Pagamentos do Brasil",
  //     "336": "Banco C6",
  //     "218": "BS2",
  //     "082": "Banco Topazio",
  //     "099": "Uniprime",
  //     "197": "Stone",
  //     "707": "Banco Daycoval",
  //     "633": "Rendimento",
  //     "004": "Banco do Nordeste",
  //     "745": "Citibank",
  //     "301": "PJBank",
  //     "097": "Cooperativa Central de Credito Noroeste Brasileiro",
  //     "084": "Uniprime Norte do Paraná",
  //     "384": "Global SCM",
  //     "403": "Cora",
  //     "323": "Mercado Pago",
  //     "003": "Banco da Amazonia",
  //     "752": "BNP Paribas Brasil",
  //     "383": "Juno",
  //     "133": "Cresol",
  //     "173": "BRL Trust DTVM",
  //     "047": "Banco Banese",
  //     "208": "Banco BTG Pactual",
  //     "613": "Banco Omni",
  //     "332": "Acesso Soluções de Pagamento",
  //     "273": "CCR de São Miguel do Oeste",
  //     "093": "Polocred",
  //     "355": "Ótimo",
  //     "121": "Agibank",
  //     "037": "Banpará",
  //     "380": "Picpay",
  //     "125": "Banco Genial",
  //     "412-x": "Banco Capital S.A",
  //     "741": "Banco Ribeirão Preto",
  //     "461": "ASAAS IP",
  //     "623": "Banco Pan",
  //     "735": "Neon",
  //     "310": "VORTX DTVM LTDA",
  //     "318": "Banco BMG",
  //     "450": "Fitbank",
  //     "174": "Pefisa",
  //     "451": "J17 - SCD S/A",
  //     "089": "Credisan",
  //     "529": "Pinbank",
  //     "102": "XP Investimentos",
  //     "069": "Crefisa",
  //     "363": "Singulare",
  //     "404": "SUMUP SCD S.A.",
  //     "246": "Banco ABC Brasil",
  //     "630": "Banco Letsbank S.A",
  //     "523": "HR Digital Sociedade de Crédito Direto S.A",
  //     "348": "BANCO XP S.A.",
  //     "536": "Neon Pagamentos S.A. IP",
  //     "542": "CLOUD WALK MEIOS DE PAGAMENTOS E SERVICOS LTDA",
  //     "637": "BCO SOFISA S.A.",
  //     "413": "BANCO BV S.A.",
  //     "509": "CELCOIN INSTITUICAO DE PAGAMENTO S.A.",
  //     "224": "BCO FIBRA S.A",
  //     "457": "UY3 SCD S/A",
  //     "329": "QI Sociedade de Crédito"
  // };
  
  // const arrayCompleto = [];
  
  // for (const [codigo, nomeBanco] of Object.entries(primeiroArray)) {
  //     arrayCompleto.push([
  //         "00000000",                     // Código fictício, substitua conforme necessário.
  //         nomeBanco.toUpperCase(),        // Nome do banco em maiúsculas.
  //         codigo,                         // Código do banco.
  //         "Sim",                          // Sim ou Não (depende da lógica do negócio).
  //         "RSFN",                         // Exemplo: "RSFN" (pode ser ajustado).
  //         nomeBanco,                      // Nome do banco conforme aparece na lista original.
  //         "22/04/2002"                    // Data fictícia, substitua conforme necessário.
  //     ]);
  // }
  
  // console.log(JSON.stringify(arrayCompleto));
  // }, []);

  const handleInputCheck = (event) => {
    const { name, checked } = event.target;

    setValue(name, checked);
    // checkFormIsValid(event);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
    // checkFormIsValid(event);
  };

  const checkFormIsValid = (event) => {
    const { name, value, checked } = event.target;
    let newFormData = formData;
    if (value) {
      newFormData = { ...formData, [name]: value };
    }

    if (checked) {
      newFormData = { ...formData, [name]: checked };
    }

    const formIsValid = allowToSubmit();
    if (formIsValid) {
      setFormIsValid(true);
      setFormInfo(newFormData);
    } else {
      setFormIsValid(false);
    }
  };

  const { onboardingPage, hasCnpj, setHasCnpj, setOnBoardingPage } =
    usePerfilPage();

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  dataselectb.forEach((subList) => {
    dataDict[subList[1]] = subList[2];
  });

  return (
    <React.Fragment>
      <Row>
        <TextCenter>
          <CustomText type={"h1"}>Dados Bancários</CustomText>
        </TextCenter>
      </Row>
      <Row>
        <TextCenter>
          <CustomText type={"h6"}>
            Informe a conta que deseja receber seus pagamentos
          </CustomText>
        </TextCenter>
      </Row>

      <Container style={{ marginTop: "10px" }}>
        <FormRow>
          <CustomText type={"h7"}>Banco</CustomText>
          <SearchableSelect
            name="banco_id"
            options={dataselectb.map((field) => ({
              key: field[1],
              value: field[2],
            }))}
            value={formData.banco_id}
            onChange={handleInputChange}
          />
        </FormRow>

        <DisplayRow>
          <FormRow>
            <CustomText type={"h7"}>Número da Agência</CustomText>
            <CustomInput
              name="agencia"
              value={formData.agencia}
              onChange={handleInputChange}
              required
            />
          </FormRow>

          <FormRow>
            <CustomText type={"h7"}>Dígito (caso tenha)</CustomText>
            <CustomInput
              placeholder="0"
              name="agenciaLast"
              maxLength={1}
              value={formData.agenciaLast}
              onChange={handleInputChange}
            />
          </FormRow>
        </DisplayRow>

        <DisplayRow>
          <FormRow style={{ flexGrow: 2 }}>
            <CustomText type={"h7"}>
              Conta (Se o dígito for x substitua por 0)
            </CustomText>
            <CustomInput
              name="conta"
              value={formData.conta}
              onChange={handleInputChange}
              required
            />
          </FormRow>

          <FormRow style={{ flexGrow: 1 }}>
            <CustomText type={"h7"}>Dígito (caso tenha)</CustomText>
            <CustomInput
              placeholder="0"
              name="contaLast"
              maxLength={1}
              value={formData.contaLast}
              onChange={handleInputChange}
            />
          </FormRow>
        </DisplayRow>

        <FormRow>
          <CustomText type={"h7"}>PIX</CustomText>
          <CustomInput
            name="n_pix"
            value={formData.n_pix}
            onChange={handleInputChange}
          />
        </FormRow>
        <FormRow>
          <Checkbox
            name="checkForm"
            onChange={handleInputCheck}
            isChecked={formData.checkForm}
          >
            Afirmo que a conta fornecida pertence ao meu{" "}
            {hasCnpj ? "cnpj" : "cpf"}
          </Checkbox>
        </FormRow>
      </Container>
    </React.Fragment>
  );
};

export default PersonalBankingStep;
