import React from 'react';
import { Box, FormLabel, Input } from '@chakra-ui/react';
import ImageList from '../images/ImageList';
import { Control, Controller } from 'react-hook-form';
import { ImageFile } from 'models/File';
import { createImageFileList } from 'utils/file';

type ImageInputProps = {
  control: Control<any>
  id: string
  label: string
  multiple?: boolean
  required?: boolean
  images?: ImageFile[]
}

function ImageInputComponent ({ control, id, label, multiple=false, required = true, images = null }: ImageInputProps) {
  const renderImages = () => {
    if (images && images.length > 0) {
      return (
        <Box marginBottom="10px">
          <ImageList images={images.map((image: ImageFile, idx: number) => ({
            url: image.preview,
            alt: `Imagem ${idx}`
          }))} />
        </Box>
      );
    }
  }

  return (
    <Box marginBottom="14px">
      <FormLabel htmlFor={id}>{label}</FormLabel>

      {renderImages()}

      <Controller
        name={id}
        control={control}
        render={({ field }) => (
          <Input
            type="file"
            accept="image/png, image/jpeg"
            required={required}
            border="none"
            multiple={multiple}
            onChange={(e) => {
              if (e.target.files) {
                let files = createImageFileList(e.target.files);
                field.onChange(files);
              }
            }}
          />
        )}
      />
    </Box>
  );
}

export const ImageInput = ImageInputComponent;
