import axios from "./api";
import { GetBalance, GetTransactions, GetDebtsAndCredits } from "models/Payment";

export const getBalance = async (
  params: GetBalance.Params
): Promise<GetBalance.Result> => {
  const adminUrl = `/seller/balance`;
  try {
   
    const balance = await axios.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}/${params.contractId}`
    );
    
    return balance.data as GetBalance.Result;
  } catch (err) {
    console.log(err);
  }
};

export const getTransactions = async (
  params: GetTransactions.Params
): Promise<GetTransactions.Result> => {
  const adminUrl = `seller/transactions`;
  try {
    
    const transactions = await axios.get(
      `${process.env.REACT_APP_API_URL}/${adminUrl}/${params.contractId}`,
      {
        params: {
          fromDate: params.fromDate,
          toDate: params.toDate,
        },
      }
    );

    return transactions.data as GetTransactions.Result;
  } catch (err) {
    console.log(err);
  }
};


export const getDebtsAndCredits = async (
  params: GetDebtsAndCredits.Params
): Promise<GetDebtsAndCredits.Result> => {
  const adminUrl = `seller/debts-credits`;
  try {
    
    const transactions = await axios.get(
      `${process.env.REACT_APP_API_URL}/${adminUrl}/${params.contractId}`,
      {
        params: {
          fromDate: params.fromDate,
          toDate: params.toDate,
        },
      }
    );

    return transactions.data as GetDebtsAndCredits.Result;
  } catch (err) {
    console.log(err);
  }
};