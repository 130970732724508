import React from "react";
import { Box, FormLabel, Input } from "@chakra-ui/react";
import InputMask from "react-input-mask";

type FormInputProps = {
  register: Function;
  id: string;
  label: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  mask?: string; // Adiciona a propriedade 'mask' para a máscara
  maskChar?: string; // Adiciona a propriedade 'maskChar' para o caractere de máscara
};

export default function FormInput({
  register,
  id,
  label,
  type = "text",
  required = true,
  placeholder = "",
  mask, // Adiciona a máscara como um parâmetro opcional
  maskChar, // Adiciona o caractere de máscara como um parâmetro opcional
}: FormInputProps) {
  const inputProps = {
    id,
    type,
    placeholder,
    ...register(id, { required }),
  };

  return (
    <Box marginBottom="14px">
      <FormLabel htmlFor={id}>{label}</FormLabel>
      {mask ? (
        <InputMask mask={mask} maskChar={maskChar} {...inputProps}>
          {(inputProps: any) => <Input {...inputProps} />}
        </InputMask>
      ) : (
        <Input {...inputProps} />
      )}
    </Box>
  );
}
