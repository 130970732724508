import React from 'react';
import {
  Box,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';
import CheckTable from 'views/admin/configuracoes/components/Pixel/CheckTablePixel';
import PixelForm from 'views/admin/configuracoes/components/Pixel/PixelForm';
import { Pixel as PixelModel, PixelResponse } from 'models/Pixel';
import LoadingPage from 'components/Loading/LoadingPage';
import { useUserStore } from 'store/reducers/user';
import { deletePixel, getPixels } from 'controllers/Pixels';
import { DeleteModal } from 'components/modal/DeleteModal';

export default function Pixel() {
  const token = useUserStore((state) => state.auth.token);

  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [pixelData, setPixelData] = React.useState<PixelResponse>({ data: [] });
  const [selectedPixel, setSelectedPixel] = React.useState<PixelModel>(null);
  const [page, setPage] = React.useState<number>(1);
  const [confirmDeletion, setConfirmDeletion] = React.useState<number>(null);

  const switchShow = () => {
    setSelectedPixel(null);
    setShow(!show);
  }

  const handleGetPixels = async () => {
    try {
      setLoading(true);
      const response = await getPixels(page, token);
      setPixelData(response);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }

  const selectPixel = (pixel: PixelModel) => {
    setSelectedPixel(pixel);
    setShow(true);
  }

  const requestDeletePixel = (pixel: PixelModel) => {
    setConfirmDeletion(pixel.id);
  }

  const handleDeletePixel = async (pixelId: number) => {
    setLoading(true);
    try {
      await deletePixel(pixelId, token);
      const updatedPixelData = pixelData.data.filter(pixel => pixel.id != pixelId)

      setPixelData({
        ...pixelData,
        data: [
          ...updatedPixelData
        ]
      });
    } catch (error) {
      console.log(error);
    } finally {
      setConfirmDeletion(null);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    handleGetPixels()
  }, [page])

	return (
    <>
      <LoadingPage isOpen={loading} />
      <Button onClick={switchShow}>{show ? 'Voltar' : 'Novo Pixel'}</Button>

      {show ? (
        <PixelForm switchShow={switchShow} show={show} pixelData={pixelData} setPixelData={setPixelData} selectedPixel={selectedPixel} />
      ) : (
        <Box pt={{ base: '130px', md: '60px', xl: '40px' }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
            <CheckTable
              tableData={pixelData}
              tableName="Pixel"
              selectPixel={selectPixel}
              page={page}
              setPage={setPage}
              requestDeletePixel={requestDeletePixel}
            />
          </SimpleGrid>
        </Box>
      )}

      <DeleteModal
        title="Remover pixel?"
        isOpen={confirmDeletion != null}
        onClose={() => setConfirmDeletion(null)}
        handleDelete={() => handleDeletePixel(confirmDeletion)}
      />
    </>
	);
}
