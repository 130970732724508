import React from "react";
import { Box, FormLabel } from "@chakra-ui/react";
import { CustomInput } from "styleguide/Input";

type SelectInputOptions = {
  value: string;
  label: string;
};

type SearchableSelectInputProps = {
  name?: string;
  label?: string;
  options: any[];
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  value: string;
  onChange: ({ name, value }) => void; // antigo setValue
};

export default function SearchableSelect({
  name = "selectInput",
  label = "",
  options,
  required = true,
  placeholder = "Selecione uma opção",
  disabled = false,
  value,
  onChange,
}: SearchableSelectInputProps) {
  const handleChange = (event) => {
    try {
      onChange(event);
    } catch (error) {
      console.error("Erro ao processar mudança de input:", error);
      // Lidar com o erro de acordo com a sua lógica
    }
  };

  return (
    <Box marginBottom="10px" position="relative">
      <FormLabel>{label}</FormLabel>
      <CustomInput
        name={name}
        list="options"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
      />

      <datalist id="options">
        <option value="">{placeholder}</option>
        {options?.map(
          (option) =>
            option.label !== null && (
              <option key={option.value} value={option.key}>
                {option.key} - {option.value}
              </option>
            )
        )}
      </datalist>
    </Box>
  );
}
