import React from 'react';
import { Text, useColorModeValue } from '@chakra-ui/react';

type FormTitleProps = {
  title: string
}

export default function FormTitle ({ title }: FormTitleProps) {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Text
      color={textColor}
      fontSize="22px"
      fontWeight="700"
      lineHeight="100%"
      marginBottom="20px"
    >
      {title}
    </Text>
  );
};
