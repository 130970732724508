import * as React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Select,
  Input,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Checkbox,
  Flex,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { parse } from "papaparse";
import $ from "jquery";
import "jquery-mask-plugin";
import { useUserStore } from 'store/reducers/user';
import { User } from "@supabase/gotrue-js";
import "bootstrap";
import { reverse } from "dns";
import { FaFilePdf, FaArrowLeft, FaArrowRight, FaPaperclip, FaExclamationTriangle } from 'react-icons/fa';
import { MdHeight } from "react-icons/md";
import { BsJustify } from "react-icons/bs";
import { useHistory } from "react-router-dom";



export default function UserReports() {
    const auth = useUserStore((state) => state.auth);
    const [isLoading, setIsLoading] = React.useState(false);
    //send api
    const [email, setEmail] = React.useState('');
    const [code, setCode] = React.useState('');
    const emailAtual = auth.user.email;
    const [isEmailSent, setIsEmailSent] = React.useState(false);
    const [countdown, setCountdown] = React.useState(60);
    const [showCodeForm, setShowCodeForm] = React.useState(false);
    const logout = useUserStore((state) => state.logout);
    const { push } = useHistory();
    const [modalshowErro, setModalErro] = React.useState(false);
    const [erro, setErro] = React.useState("");
    const [tituloErro, setTituloErro] = React.useState("");
    const [controle, setControle] = React.useState(false);


    React.useEffect(() => {
      if (isEmailSent) {
        const timer = setTimeout(() => {
          setIsEmailSent(false);
          setCountdown(60); // Redefine o tempo restante para 60 segundos
        }, 60000);
    
        return () => clearTimeout(timer);
      }
    }, [isEmailSent]);

    React.useEffect(() => {
      let timer: NodeJS.Timeout | null = null;
    
      if (isEmailSent && countdown > 0) {
        timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
      }
    
      return () => {
        if (timer) {
          clearInterval(timer);
        }
      };
    }, [isEmailSent, countdown]);

    const handleSubmit = async (e: any) => {
      e.preventDefault();
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", auth.user.id+"");
      formData.append("oque", "pedido");
      formData.append("email", email);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/alterar`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth.token}`
            }
          }
        );

        if(response.data[0] === 'email'){
          setErro('Esse email já esta em uso, você deve verificar com o suporte sobre isso!');
          setTituloErro('Ops!');
          setModalErro(true);
        }else{
          setIsEmailSent(true);
          setShowCodeForm(true);

          
          setErro('Seu Email Foi enviado com sucesso, Agora você deve verificar que logo estará na sua caixa de entrada ou em span, só reenvie caso realmente não tenha chegado, confirme se escreveu corretamente seu email!');
          setTituloErro('Sucesso!');
          setModalErro(true);
        }
        


      } catch (error) {
        console.error(error);
        setErro('Houve um erro ao enviar seu email, tente mais tarde!');
        setTituloErro('Ops!');
        setModalErro(true);
      } finally {
        // Set isLoading to false to hide the preloader
        setIsLoading(false);
      }
    };
   

    const handleCodeSubmit = async (e: any) => {
      e.preventDefault();
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", auth.user.id+"");
      formData.append("oque", "code");
      formData.append("code", code);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/alterar`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth.token}`
            }
          }
        );

        if(response.data[0] === 'ok'){
          setErro('Seu email foi trocado com sucesso, agora você irá para tela de login para acessar novamente com seu email novo!');
          setTituloErro('Sucesso!');
          setModalErro(true);
          setControle(true);
        }

        
        
      } catch (error) {
          setErro('O códgio esta incompleto ou está errado, por favor caso persista o problema reenvie outra solicitação de código!');
          setTituloErro('Ops!');
          setModalErro(true);
      }finally {
        // Set isLoading to false to hide the preloader
        setIsLoading(false);
      }
    };
    const handleEmailChange = (e: any) => {
      setEmail(e.target.value);
    };

    const handleCodeChange = (e: any) => {
      setCode(e.target.value);
    };

    const handleform = () => {
      setShowCodeForm(true);
    };
    
    const handleCloseErro = () => {
      if(controle){
        handlelogout();
      }else{
        setModalErro(false);
      }
      
    };
    //load

    const handlelogout = () => {
      logout();
      push("/auth");
    }


    const renderPreloader = () => {
        return isLoading ? (
          <div className="preloader-overlay">
            <div className="preloader-content">
              <div className="preloader-spinner"></div>
            </div>
          </div>
        ) : null;
      };
    return(
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Modal  isOpen={modalshowErro} onClose={handleCloseErro}>
          <ModalContent>
            <ModalHeader>{tituloErro}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {erro}
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={handleCloseErro}>Fechar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {renderPreloader()}
        <Flex justify="center">
        <Box overflow={"auto"} maxHeight={"85vh"} minHeight={"85vh"} maxWidth={"300px"} style={{textAlign: 'center'}}>
          <form onSubmit={handleSubmit}>
            <FormLabel>Seu email atual é:</FormLabel>
            <span>{emailAtual}</span>
            <Box
              display="flex"
              alignItems="center"
              margin="10px"
              p="10px"
              border="1px solid red"
              borderRadius="md"
              backgroundColor="white"
            >
              <FaExclamationTriangle size={50} color="red" />
              <span style={{ marginLeft: "10px" }}>
                É a sua forma de acesso ao sistema, só será atualizado após confirmar
                com o código enviado para seu email novo!
              </span>
            </Box>
            <FormLabel htmlFor="email">Digite seu email novo:</FormLabel>
            <Input type="email" id="email" value={email} onChange={handleEmailChange} bg='white' placeholder="seuemailnovo@emial.com"/>
            <Button
              type="submit"
              className="btn black"
              style={{ backgroundColor: "green", color: "#fff" }}
              disabled={isEmailSent}
            >
              {isEmailSent ? `Aguarde para reenviar(${countdown})` : "Enviar código para esse email"}
            </Button>
          </form>
          <br></br>
          
            <br></br>
          {showCodeForm? (
          <form onSubmit={handleCodeSubmit}>
            <FormLabel htmlFor="code">Digite o código recebido no email:</FormLabel>
            <Input type="text" id="code" value={code} onChange={handleCodeChange} bg='white' placeholder="00000000"/>
            <Button
              type="submit"
              className="btn black"
              style={{ backgroundColor: "green", color: "#fff" }}
            >
              Confirmar o Código
            </Button>
          </form>
          ):(
            <Button
              onClick={handleform}
              type="button"
              className="btn black"
              style={{ backgroundColor: "green", color: "#fff" }}
            >
              Recebi o Código
            </Button>
          )}
        </Box>
        </Flex>
      </Box>
    );
}