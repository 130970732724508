import { useQuery } from '@tanstack/react-query';
import { notifyError } from 'utils/notification';
import { getAllMemberAreaProviders } from 'controllers/MemberArea';

const useMemberAreaProviders = () => {
  const { data: providers, isLoading: isLoadingMemberAreaProviders } = useQuery(
    ["getAllMemberAreaProviders"],
    () => getAllMemberAreaProviders(),
    {
      onError: (error) => {
        notifyError('Erro ao buscar provedores de área de membros. Recarregue a página')
      },
    }
  );

  return { providers, isLoadingMemberAreaProviders };
};

export default useMemberAreaProviders;
