import React from "react";
import {
    Box,
    FormControl,
    FormLabel,
    Button,
    Checkbox,
    Flex,
    Text,
  } from "@chakra-ui/react";
  
  import { useUserStore } from 'store/reducers/user';
  import axios from "axios";
import { H1 } from "components/typograph";

  const FinishStep = () => {
     
    return (
        <Box style={{
            backgroundColor: "#fff",
            flex: "1",
            alignItems: "center",
            padding: "10px"
          }} 
          
          >
             <H1>Você foi aprovado</H1>
             
        </Box>

    )
}

export default FinishStep;