import React from 'react';
import { Box, CloseButton, Flex } from '@chakra-ui/react';

export type DeletableImage = {
  url: string
  alt: string
  id?: number
  deletable: boolean
}

type DeletableImageListProps = {
  images: DeletableImage[]
  onDelete: Function
  width?: number
  height?: number
}

export default function DeletableImageList ({ images, onDelete, width = 200, height = 200 }: DeletableImageListProps) {
  return (
    <Flex direction="row" flexWrap="wrap" gap={4}>
      {images.map((image: DeletableImage) => (
        <Box
          key={image.url}
          width={width}
          height={height}
          borderRadius="lg"
          padding="4px"
          borderColor="gray.300"
          borderWidth="1px"
          borderStyle="dashed"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          {image.deletable && (
            <Box position='absolute' right={0} top={0}>
              <CloseButton onClick={() => {
                onDelete(image.id);
              }}/>
            </Box>
          )}
          <img src={image.url} alt={image.alt} />
        </Box>
      ))}
    </Flex>
  );
};
