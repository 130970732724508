import React from 'react'
import { Product } from 'models/Product';
import Card from 'components/card/Card';
import { Box, Divider, Text } from '@chakra-ui/react';
import DetailsText from 'components/details/DetailsText';
import { getProductType } from 'utils/products';
import SourceTag from 'components/tags/SourceTag';
import ProductDetailsVariations from './ProductDetailsVariations';
import ProductDetailsImages from './ProductDetailsImages';

type ProductDetailsProps = {
  product: Product,
}

export default function ProductDetails({ product }: ProductDetailsProps) {
  const padding = "10px 20px";

  return (
    <>
      <Card
        flexDirection="column"
        marginTop={"10px"}
        w="100%"
        px={padding}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Box padding={padding}>
          <SourceTag source={product.source} />
          <Text fontSize={{base: "26px", md: "32px"}}  fontWeight="bold" mt="10px">
            {product.name}
          </Text>
        </Box>
        <Box padding={padding} pt={0}>
          {product.description && (
              <DetailsText title={"Descrição"} value={product.description} />
          )}
          {product.type && (
            <DetailsText title={"Tipo"} value={getProductType(product.type)} />
          )}
          <DetailsText title={"Loja"} value={product.store.name} />
        </Box>
        {product.variations && product.variations.length > 0 && (
          <>
            <Divider />
            <ProductDetailsVariations
              variations={product.variations}
              images={product.images}
            />
          </>
        )}
        {product.images && product.images.length > 0 && (
          <>
            <Divider />
            <ProductDetailsImages images={product.images} />
          </>
        )}
      </Card>
    </>
  )
}
