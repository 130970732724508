import React from "react";
import {
  Box,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import CheckTable from "views/admin/cadastros/components/stores/CheckTableStore";
import { deleteStore, getStores } from "controllers/Stores";
import { useUserStore } from "store/reducers/user";
import LoadingPage from "components/Loading/LoadingPage";
import { Store as StoreModel } from "models/Store";
import StoreForm from 'views/admin/cadastros/components/stores/StoreForm';
import { DeleteModal } from 'components/modal/DeleteModal';
import StoreDetails from 'views/admin/cadastros/components/stores/StoreDetails';
import { useQuery } from '@tanstack/react-query';
import { notifyError, notifySuccess } from 'utils/notification';

export default function Store() {
  const token = useUserStore((state) => state.auth.token);

  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [selected, setSelected] = React.useState<StoreModel>(null);
  const [show, setShow] = React.useState(false);
  const [confirmDeletion, setConfirmDeletion] = React.useState<number>(null);
  const [details, setDetails] = React.useState<StoreModel>(null);

  const { data: storeData, isLoading, refetch } = useQuery(
    ["stores", page, token],
    () => getStores(token, page),
    {
      onError: (error) => {
        notifyError('Erro ao listar as lojas. Recarregue a página!');
      }
    }
  );

  const switchShow = () => {
    setDetails(null);
    setSelected(null);
    setShow(!show);
  }

  const showDetails = (store: StoreModel) => {
    setDetails(store);
    setShow(true);
  }

  const select = (store: StoreModel) => {
    setSelected(store);
    setShow(true);
  }

  const requestDelete = (store: StoreModel) => {
    setConfirmDeletion(store.id);
  }

  const handleDelete = async (storeId: number) => {
    setLoading(true);
    try {
      await deleteStore(storeId);
      notifySuccess('Loja deletada com sucesso');
      await refetch();
    } catch (error) {
      notifyError('Erro ao deletar loja.')
    } finally {
      setConfirmDeletion(null);
      setLoading(false);
    }
  }

  const renderCard = () => {
    if (!show) {
      return (
        <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <CheckTable
              tableData={storeData}
              tableName="Lojas"
              select={select}
              requestDelete={requestDelete}
              showDetails={showDetails}
              page={page}
              setPage={setPage}
            />
          </SimpleGrid>
        </Box>
      );
    }

    if (details) {
      return (
        <StoreDetails store={details} />
      );
    } else {
      return (
        <StoreForm
          switchShow={switchShow}
          show={show}
          refetch={refetch}
          selected={selected}
        />
      );
    }
  }

  return (
    <>
      <LoadingPage isOpen={loading} />
      <Button onClick={switchShow}>
        {show ? "Voltar" : "Nova Loja"}
      </Button>

      {renderCard()}

      <DeleteModal
        title="Remover loja?"
        isOpen={confirmDeletion != null}
        onClose={() => setConfirmDeletion(null)}
        handleDelete={() => handleDelete(confirmDeletion)}
      />
    </>
  );
}
