import React from 'react';
import { Box, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import Checkout from './Checkout';
import Pixel from 'views/admin/configuracoes/components/Pixel/Pixel';
import Integrations from './Integrations/';
import MemberArea from 'views/admin/configuracoes/components/MemberArea/MemberArea';

export default function MenuConfiguracoes() {
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <Tabs isLazy isFitted variant='enclosed'>
            <TabList>
                <Tab _selected={{ color: '#00CF79', fontWeight: 'bold' }}>Checkout</Tab>
                <Tab _selected={{ color: '#00CF79', fontWeight: 'bold' }}>Pixel</Tab>
                <Tab _selected={{ color: '#00CF79', fontWeight: 'bold' }}>Integrações</Tab>
                <Tab _selected={{ color: '#00CF79', fontWeight: 'bold' }}>Área de Membros</Tab>
                {/* <Tab _selected={{ color: '#00CF79', fontWeight: 'bold' }}>Produtos</Tab>
                <Tab _selected={{ color: '#00CF79', fontWeight: 'bold' }}>Domínios</Tab> */}
            </TabList>

            <TabPanels>
                <TabPanel>
                    <Checkout />
                </TabPanel>
                <TabPanel>
                    <Pixel />
                </TabPanel>
                <TabPanel>
                    <Integrations />
                </TabPanel>
                <TabPanel>
                  <MemberArea />
                </TabPanel>
                {/* <TabPanel>
                    <Users />
                </TabPanel>
                <TabPanel>
                    <Domains />
                </TabPanel> */}
            </TabPanels>
        </Tabs>
			</SimpleGrid>
		</Box>
	);
}
