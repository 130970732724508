import React, { FormEvent, useState } from "react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading, Icon,
  Input,
  InputGroup, InputRightElement,
  Text,
  useColorModeValue,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import { resetPassword } from 'controllers/Auth';

function ResetPassword() {
  const { push } = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [modal, setModal] = useState({ open: false, success: false, title: "", body: "" });

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  
  const handleReset = async (event: FormEvent) => {
    event.preventDefault();

    try {
      setLoading(true);
      await resetPassword(queryParams.get("email"), password, passwordConfirmation, queryParams.get("token"))
      setModal({
        open: true,
        success: true,
        title: "Senha Alterada com Sucesso!",
        body: "Volte para a tela de login e faça login na sua conta dubpay."
      });
    } catch (error) {
      setModal({
        open: true,
        success: false,
        title: "Ops. Tivemos um erro!",
        body: "Tente novamente mais tarde!"
      });
    } finally {
      setLoading(false);
    }
  }
  
  const isPasswordValid = () => {
    return password.length >= 8 && password == passwordConfirmation;
  }

  const handleClose = () => {
    if (modal.success) {
      push('/auth/sign-in');
    } else {
      setModal({ ...modal, open: false });
    }
  }
// 

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px" className="cadastro">
            Redefinir Senha
          </Heading>
          <Text
            className="cadastro"
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Defina uma nova senha para a sua conta dubpay
          </Text>
          <form onSubmit={(e) => handleReset(e)}>
            <FormControl>
              <FormLabel
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Senha
              </FormLabel>
              <InputGroup size="md">
                <Input
                  id="senhaa"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 8 digitos"
                  mb="14px"
                  size="lg"
                  type={showPassword ? "text" : "password"}
                  variant="auth"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputRightElement>
              </InputGroup>

              <FormLabel
                ms="1px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Confirmar senha
              </FormLabel>
              <InputGroup size="md">
                <Input
                  id="senhab"
                  className="input-group-text text-left"
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Confirmar senha"
                  mb="24px"
                  size="lg"
                  type={showPasswordConfirm ? "text" : "password"}
                  variant="auth"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={showPasswordConfirm ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                  />
                </InputRightElement>
              </InputGroup>
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                isDisabled={!isPasswordValid()}
                _hover={!isPasswordValid() ? { bg: "gray.400" } : { bg: "brand.600" }}
              >
                {loading ? "Carregando..." : "Redefinir"}
              </Button>
            </FormControl>
          </form>
        </Box>


        <Modal isOpen={modal.open} onClose={handleClose}>
          <ModalContent>
            <ModalHeader>{modal?.title}</ModalHeader>
            <ModalCloseButton />
          <ModalBody>{modal?.body}</ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={handleClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPassword;
