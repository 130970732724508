import axios from "./api";
import { RequestAnticipation, HasPendingPaymentRequests, PaymentAdvancedRequest, RequestWithdraw, ListPaymentRequestsByUserId } from "models/PaymentRequests";
import { useUserStore } from 'store/reducers/user';
import {SyncProducts} from "models/Product";

export const syncProduct = async (
    
): Promise<SyncProducts.Result> => {

    const token = useUserStore.getState().auth.token

    const adminUrl = `/products/sync`;
    try {

        const balance = await axios.post(
            `${process.env.REACT_APP_API_URL}${adminUrl}`,
            {}, {
            headers:
            {
                'Authorization': `Bearer ${token}`
            },

        }

        );

        return balance.data as SyncProducts.Result;
    } catch (err) {
        console.log(err);
    }
};