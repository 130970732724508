import React, {useState} from 'react';
import {InputStyles} from "./styles";



export const CustomInput = ({...rest }) => {

    const [inputType, setInputType] = useState(rest.type || 'text');

    const toggleInputType = () => {
        setInputType(inputType === 'password' ? 'text' : 'password');
      };

  return (<InputStyles type={inputType} {...rest}/> 
    );
};

