import React from 'react';
import styled from 'styled-components';

export const TextStyle = styled.p`
  /* Estilos de textStyleo padrão aqui */
`;

export const H1Style = styled(TextStyle)`
  font-size: 32px;
  font-weight: bold;
`;

export const H2Style = styled(TextStyle)`
  font-size: 28px;
  font-weight: bold;
`;

export const H3Style = styled(TextStyle)`
  font-size: 24px;
  font-weight: bold;
`;

export const H4Style = styled(TextStyle)`
  font-size: 20px;
  font-weight: bold;
`;

export const H5Style = styled(TextStyle)`
  font-size: 18px;
`;

export const H6Style = styled(TextStyle)`
  font-size: 16px;
`;

export const SubtitleStyle = styled(TextStyle)`
  font-size: 18px;
  font-weight: normal;
`;