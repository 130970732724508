export const formatCentToReal = (amount: number): number => {
    return amount / 100;
  };

export const formatCentToRealString = (amount: number): string => {
    if (amount == 0) {
        return "0,00"
    }
    const amountCent = formatCentToReal(amount);
    
    return amountCent.toFixed(2).padEnd(2, "0")

}

export const formatCurrency = (number) => {
    const numero = parseFloat(number);
    return numero.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
}