import { CreatePixelRequest, Pixel, PixelResponse } from 'models/Pixel';
import api from 'services/api';
import { notifyError } from 'utils/notification';

export async function getPixels(page: number, token: string): Promise<PixelResponse> {
  try {
    const response = await api.get<PixelResponse>(
      `${process.env.REACT_APP_API_URL}/pixel?page=${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer os pixels.");
  }
}

export async function createPixel(data: CreatePixelRequest, token: string): Promise<Pixel> {
  try {
    const response = await api.post<Pixel>(
      `${process.env.REACT_APP_API_URL}/pixel`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao criar seu pixel.");
  }
}

export async function updatePixel(id: number, data: CreatePixelRequest, token: string): Promise<Pixel> {
  try {
    const response = await api.put<Pixel>(
      `${process.env.REACT_APP_API_URL}/pixel/${id}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao atualizar seu pixel.");
  }
}

export async function deletePixel(id: number, token: string): Promise<Pixel> {
  try {
    const response = await api.delete<Pixel>(
      `${process.env.REACT_APP_API_URL}/pixel/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao deletar seu pixel.");
  }
}
