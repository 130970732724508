import { CreateShippingRequest, Shipping, ShippingResponse } from 'models/Shipping';
import api from 'services/api';
import { filtersToUrl } from 'utils/filters';
import { notifyError } from 'utils/notification';

export async function getShippings(page: number, token: string, filters = {}): Promise<ShippingResponse> {
  try {
    const filtersUrl = filtersToUrl(filters);
    const response = await api.get<ShippingResponse>(
      `${process.env.REACT_APP_API_URL}/shippings?page=${page}${filtersUrl}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao trazer os fretes.");
  }
}

export async function createShipping(data: CreateShippingRequest, token: string): Promise<Shipping> {
  try {
    const response = await api.post<Shipping>(
      `${process.env.REACT_APP_API_URL}/shippings`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao criar seu frete.");
  }
}

export async function updateShipping(id: number, data: CreateShippingRequest, token: string): Promise<Shipping> {
  try {
    const response = await api.put<Shipping>(
      `${process.env.REACT_APP_API_URL}/shippings/${id}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao atualizar seu frete.");
  }
}

export async function deleteShipping(id: number, token: string): Promise<Shipping> {
  try {
    const response = await api.delete<Shipping>(
      `${process.env.REACT_APP_API_URL}/shippings/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    notifyError("Ops! Tivemos um problema ao deletar seu frete.");
  }
}
