import React from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  MdAddTask,
  MdApproval,
  MdAttachMoney,
  MdBarChart,
  MdCancel,
  MdFileCopy,
  MdFreeCancellation,
  MdRequestPage,
} from "react-icons/md";
// import CheckTable from './components/CheckTable';
import tableDataDomain from "../variables/tableDataDomain";
import CheckTable from "./CheckTableCupom";
import { useHistory } from "react-router-dom";
import Card from "components/card/Card";
import tableDataCupom from "../variables/tableDataCupom";
import { deleteCoupon, getCoupons } from "controllers/Coupons";
import { useUserStore } from "store/reducers/user";
import { CouponModel } from "models/Coupon";
import { useQuery } from '@tanstack/react-query';
import { AlertModal, AlertModel } from 'components/modal/AlertModal';
import LoadingPage from 'components/Loading/LoadingPage';
import CouponForm from './CouponForm';
import { DeleteModal } from 'components/modal/DeleteModal';

export default function Cupons() {
  const token = useUserStore((state) => state.auth.token);

  const [page, setPage] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<CouponModel>(null);
  const [confirmDeletion, setConfirmDeletion] = React.useState<number>(null);
  const [alert, setAlert] = React.useState<AlertModel>(null);

  const { data: couponsData, isLoading, refetch } = useQuery(
    ["coupons", page, token],
    () => getCoupons(page, token),
    {
      onError: (error) => {
        setAlert({
          text: 'Erro ao listar os cupons. Recarregue a página!',
          type: 'error',
        })
      }
    }
  );

  const switchShow = () => {
    setSelected(null);
    setShow(!show);
  }

  const select = (coupon: CouponModel) => {
    setSelected(coupon);
    setShow(true);
  }

  const requestDelete = (coupon: CouponModel) => {
    setConfirmDeletion(coupon.id);
  }

  const handleDelete = async (couponId: number) => {
    setLoading(true);
    try {
      await deleteCoupon(couponId, token);
      refetch();
    } catch (error) {
      setAlert({
        text: 'Erro ao deletar o seu cupom. Tente Novamente!',
        type: 'error',
      })
    } finally {
      setConfirmDeletion(null);
      setLoading(false);
    }
  }

  return (
    <>
      <LoadingPage isOpen={isLoading || loading} />
      <Button onClick={switchShow}>{show ? "Voltar" : "Novo Cupom"}</Button>

      {alert && (
        <Box mt="30px">
          <AlertModal alert={alert} setAlert={setAlert}/>
        </Box>
      )}

      {show ? (
        <CouponForm switchShow={switchShow} show={show} refetch={refetch} selected={selected} />
      ) : (
        <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <CheckTable
              tableData={couponsData}
              tableName="Cupom"
              select={select}
              requestDelete={requestDelete}
              page={page}
              setPage={setPage}
            />
          </SimpleGrid>
        </Box>
      )}

      <DeleteModal
        title="Remover Cupom?"
        isOpen={confirmDeletion != null}
        onClose={() => setConfirmDeletion(null)}
        handleDelete={() => handleDelete(confirmDeletion)}
      />
    </>
  );
}
