import axios from 'axios';
import { axiosConfig, getHeader } from '../config';
import {
  CreateMemberAreaClassroomRequest,
  MemberAreaClassroom,
  MemberAreaClassroomResponse,
  MemberAreaProviderResponse
} from 'models/MemberAreaClassroom';

const memberAreaApi = axios.create({
  ...axiosConfig,
  baseURL: axiosConfig.baseURL + 'member-area'
})

const memberAreaProviderApi = axios.create({
  ...axiosConfig,
  baseURL: axiosConfig.baseURL + 'member-area-providers'
})

export async function getMemberAreaClassrooms(page: number) {
  let url = `?page=${page}`;

  const response = await memberAreaApi.get<MemberAreaClassroomResponse>(url, {
    headers: getHeader()
  });

  return response.data;
}

export async function createMemberAreaClassroom(data: CreateMemberAreaClassroomRequest): Promise<MemberAreaClassroom> {
  const response = await memberAreaApi.post<MemberAreaClassroom>(
    "",
    data,
    {
      headers: getHeader()
    }
  );
  return response.data;
}

export async function updateMemberAreaClassroom(id: number, data: CreateMemberAreaClassroomRequest): Promise<MemberAreaClassroom> {
  const response = await memberAreaApi.put<MemberAreaClassroom>(
    `${id}`,
    data,
    {
      headers: getHeader()
    }
  );
  return response.data;
}

export async function deleteMemberAreaClassroom(id: number): Promise<void> {
  await memberAreaApi.delete(`${id}`, {
    headers: getHeader()
  });
}

export async function getAllMemberAreaProviders() {
  const response = await memberAreaProviderApi.get<MemberAreaProviderResponse>('', {
    headers: getHeader()
  });

  return response.data;
}
