import { create } from "zustand";

interface IEmail {
  email: string;
  updateEmail: (newEmail: string) => void;
}

export const useEmailStore = create<IEmail>((set) => ({
  email: "",
  updateEmail: (newEmail: string) => set({ email: newEmail }),
}));
