import { PixelType } from 'models/Pixel';
import { FcGoogle } from 'react-icons/fc';
import { BsFacebook } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';

export function getPixelTypeIcon(type: string) {
  switch (type) {
    case PixelType.GOOGLE:
      return FcGoogle;
    case PixelType.META:
      return BsFacebook;
    case PixelType.TIKTOK:
      return FaTiktok;
    default:
      return null;
  }
}

export function getPixelTypeColor(type: string) {
  switch (type) {
    case PixelType.META:
      return 'blue.600';
    default:
      return null;
  }
}
