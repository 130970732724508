import styled from "styled-components";

export const SelectTypeUserContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center; 
    align-items: center; 
    gap: 15px;
`
export const SelectTypeModal = styled.div`
    border: 1px solid #D1D1D1;
    background-color: white;
    border-radius: 5px;
    padding: 20px 40px;
`
export const SelectTypeUserBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 180px;
    width: 180px;
`

export const BodyIcon = styled.div`
    bacgkround-color: #f0f0f0;
    border-radius: 50px;
`
export const ImageContainer = styled.div`
    width: 320px;
    
`;


export const WelcomeTitle = styled.text`
    font-weight: bold;
    
    
`;
export const ContainerCollapse = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;    
    gap: 10px;
    width: 100%;
`
export const Container = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;    
    background-color: white;
    padding: 30px 40px;
    border-radius: 10px;
    gap: 10px;
    border: 0.5px solid #D1D1D1;
`