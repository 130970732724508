// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Box,
  useColorModeValue,
  Text,
  Center,
  Spacer,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React from "react";
import {formatCurrency} from "utils/currency"
export default function Default(props: {
  textColor: string;
  endContent?: JSX.Element;
  name: string;
  growth?: string | number;
  value: number
}) {
  const { textColor, endContent, name, growth, value } = props;
  // const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    // <Card py='15px'>
    
    <Flex
      w="100%"
      marginTop={'4'}
      marginBottom={'4'}
      flexDirection={'column'}
      align={{ base: "center", xl: "center" }}
      justify={{ base: "center", xl: "center" }}
    >
      <Box paddingBottom={'4'}>
        <Text fontSize={'md'} color={textColor} >{name}</Text>
      </Box>
      <Spacer/>
      <Box>
        <Text fontSize={{base: "md", md: '3xl'}} as='b'>{formatCurrency(value)}</Text>
      </Box>
     
    </Flex>
    
    
  );
}
