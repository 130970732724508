import React from 'react'
import Card from 'components/card/Card';
import { Box, Text } from '@chakra-ui/react';
import DetailsText from 'components/details/DetailsText';
import { MemberAreaClassroom } from 'models/MemberAreaClassroom';

type MemberAreaDetailsProps = {
  memberAreaClassroom: MemberAreaClassroom,
}

export default function MemberAreaDetails({ memberAreaClassroom }: MemberAreaDetailsProps) {
  const padding = "10px 20px";

  return (
    <>
      <Card
        flexDirection="column"
        marginTop={"10px"}
        w="100%"
        px={padding}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Box padding={padding}>
          <Text fontSize={{base: "26px", md: "32px"}}  fontWeight="bold" mt="10px">
            {memberAreaClassroom.name}
          </Text>
        </Box>
        <Box padding={padding} pt={0}>
          <DetailsText title={"Produto"} value={memberAreaClassroom.product.name} />
          <DetailsText title={"Área de Membros"} value={memberAreaClassroom.provider.name} />
          <DetailsText title={"Id da Turma"} value={memberAreaClassroom.classroom_id} />
        </Box>
      </Card>
    </>
  )
}
