import React from 'react';
import { Tag } from '@chakra-ui/react';
import { getProductSource, getProductSourceColor } from 'utils/products';

type ProductSourceProps = {
  source: string
}

export default function SourceTag ({ source }: ProductSourceProps) {
  return (
    <Tag
      backgroundColor={getProductSourceColor(source)}
      color="white"
      fontWeight="bold"
    >
      {getProductSource(source)}
    </Tag>
  );
};
