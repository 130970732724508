import React from "react";
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { Item, Order } from 'models/Order';
import { formatDate, formatTime } from 'utils/time';
import DetailsText from 'components/details/DetailsText';

type OrderDetailsProps = {
  order: Order
}

export default function AbandonedCartDetails({ order }: OrderDetailsProps) {
  const orderDate = formatDate(order.created_at);
  const orderTime = formatTime(order.created_at);
  const customer = order.customer;

  const padding = "20px";
  const selectedTab = { color: '#9966ff', fontWeight: 'bold' };

  const getColumnCount = () => {
    const columnCount = useBreakpointValue({ base: 1, md: 2 });
    return columnCount || 1;
  };

  const getAddress = () => {
    let address = customer.address1;
    if (customer.address2) {
      address += `, ${customer.address2}`
    }
    return address;
  }

  const getFullName = () => {
    let fullName = customer.first_name;
    if (customer.last_name) {
      fullName += ` ${customer.last_name}`
    }
    return fullName;
  }

  return (
    <Card
      flexDirection="column"
      marginTop={"10px"}
      w="100%"
      px="40px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Box py={padding}>
        <Text fontSize="32px" fontWeight="bold">
          Carrinho Abandonado
        </Text>
        <Flex alignItems={{ base: "left", md: "center" }} direction={{ base: "column", md: "row" }}>
          <Box>
            <Text>
              {orderDate} - {orderTime}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Tabs isLazy variant='enclosed'>
        <TabList>
          <Tab _selected={selectedTab}>Resumo</Tab>
          {customer && (
            <Tab _selected={selectedTab}>Cliente</Tab>
          )}
          {customer?.zip && (
            <Tab _selected={selectedTab}>Entrega</Tab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box>
              <DetailsText title={"Valor Total"} value={"R$ " + order.total_price} />
              <DetailsText title={"Valor dos Produtos"} value={"R$ " + order.total_line_items_price} />
              <DetailsText title={"Descontos"} value={"R$ " + order.total_discounts} />
            </Box>
          </TabPanel>
          {customer && (
            <TabPanel>
              <Box>
                <DetailsText title={"Nome Completo"} value={getFullName()} />
                <DetailsText title={"E-mail"} value={customer.email} />
                <DetailsText title={"Telefone"} value={customer.phone} />
                {customer.document && (
                  <DetailsText title={"CPF"} value={customer.document} />
                )}
              </Box>
            </TabPanel>
          )}
          {customer?.zip && (
            <TabPanel>
              <Box>
                <DetailsText title={"Nome Completo"} value={getFullName()} />
                <DetailsText title={"Endereço"} value={getAddress()} />
                <DetailsText title={"Cidade"} value={customer.city} />
                <DetailsText title={"Estado"} value={customer.province_code} />
                <DetailsText title={"CEP"} value={customer.zip} />
              </Box>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <Divider />
      <Box>
        <Text fontSize="28px" fontWeight="bold" pt={padding}>
          Produtos
        </Text>
        <Grid templateColumns={`repeat(${getColumnCount()}, 1fr)`} gap={6} py={padding}>
          {order.items.map((item: Item, index: number) => (
            <GridItem key={index}>
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  {item.name}
                </Text>
                <DetailsText title={"Quantidade"} value={`${item.quantity}`} />
                <DetailsText title={"Preço"} value={`R$ ${item.price}`} />
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Card>
  );
}
