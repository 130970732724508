import React from "react";
import { Box, Container, Heading, Text, useColorMode } from '@chakra-ui/react';
import Brand from 'components/sidebar/components/Brand';
import { NavLink } from 'react-router-dom';

function PrivacyPolicy() {
  const { colorMode } = useColorMode()

  return (
    <Box py="10" bg={colorMode == "light" && '#e2e8f0'}>
      <Container maxW="container.md" >
        <Box mb="8">
          <NavLink to='/auth/sign-in'>
            <Brand />
          </NavLink>
        </Box>
        <Heading as="h1" size="xl" mb="4" textAlign="center">
          Pólitica de Privacidade da DubPay
        </Heading>
        <Text mb="4">
          Bem-vindo(a)! Obrigado por utilizar a DUBPAY!
          <br /><br />
          Quando você utiliza a DUBPAY, você nos confia seus dados e informações. Nos comprometemos a manter essa confiança.
          <br /><br />
          Nesse sentido, a presente Política de Privacidade (“Política”) explica de maneira clara e acessível como as suas informações e dados serão coletados, usados, compartilhados e armazenados por meio dos nossos sistemas.
          <br /><br />
          A aceitação da nossa Política será feita quando você acessar ou usar o site, aplicativo ou serviços da DUBPAY. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e seus dados.
          <br /><br />
          A presente Política está dividida da seguinte forma para facilitar a sua compreensão:
          <br /><br />
          1. Quais informações a DUBPAY coleta <br />
          2. Como a DUBPAY usa as informações coletadas <br />
          3. Como, quando e com quem a DUBPAY compartilha suas informações <br />
          4. Quais direitos você tem sobre as informações que coletamos <br />
          5. Como a DUBPAY protege suas informações <br />
          6. Atualizações dessa política de privacidade <br />
          7. Lei aplicável
          <br /><br />
          Este documento deve ser lido em conjunto com o nosso Termos de Uso , que contém uma visão geral da nossa plataforma. Caso tenha dúvidas ou precise tratar de qualquer assunto relacionado a esta Política, entre em contato conosco através do e-mail contato@dubpay.com.br.
        </Text>
        <Heading as="h3" size="md" mb="4">
          1. INFORMAÇÕES QUE COLETAMOS
        </Heading>
        <Text mb="4">
          Nós coletamos os seguintes tipos de informações:
          <br /><br />
          • Dados de cadastro. Quando você se cadastra na DUBPAY, você nos fornece informações como login, senha, nome, endereço de e-mail, CPF/CNPJ, data de nascimento/fundação, número de telefone, dados do cartão de crédito caso efetue pagamentos.
          <br /><br />
          • Outras informações fornecidas. Ao utilizar certos recursos da DUBPAY, você pode nos fornecer informações como produtos mais vendidos, ticket médio de vendas, gastos relativos a campanhas e etc.
          <br /><br />
          1.1. Informações geradas quando você usa nossos serviços.
          <br /><br />
          Nós coletamos as seguintes informações geradas:
          <br /><br />
          • Registros de acesso. A DUBPAY coleta automaticamente registros de acesso a aplicação, que incluem o endereço IP, com data e hora, utilizado para acessar a DUBPAY. Esses dados são de coleta obrigatória, de acordo com a Lei 12.965/2014, mas somente serão fornecidos para terceiros com a sua autorização expressa ou por meio de demanda judicial.
          <br /><br />
          • Dados de uso. Nós coletamos informações sobre suas interações na DUBPAY, como sua navegação, as páginas ou outro conteúdo que você acessa ou cria, suas buscas, participações em pesquisas ou fóruns e outras ações.
          <br /><br />
          • Dados de pagamento. Quando você realiza pagamentos na DUBPAY, poderão ser armazenados dados do pagamento, como a data e hora, o valor e outros detalhes da transação, que poderão ser utilizados inclusive para fins de prevenção à fraude. Desta forma, poderemos lhe proporcionar um ambiente seguro e adequado para você realizar as suas transações.
          <br /><br />
          • Características do equipamento. Como a maioria das aplicações, para poder funcionar a DUBPAY coleta automaticamente dados sobre as características do seu aparelho, dentre as quais o seu sistema operacional, a versão deste, informações de hardware, o idioma, sinal de internet e bateria.
          <br /><br />
          • Comunicações com a DUBPAY. Quando você se comunica com a DUBPAY, coletamos informações sobre sua comunicação, incluindo metadados como data, IP e hora das comunicações e todo o seu conteúdo, assim como qualquer informação que você escolha fornecer.
          <br /><br />
          • Comunicações com outros usuários. Quando você se comunica com outros usuários da DUBPAY, coletamos informações sobre sua comunicação, incluindo metadados como data, IP e hora das comunicações e todo o seu conteúdo, assim como qualquer informação que você escolha fornecer.
          <br /><br />
          • Cookies e tecnologias semelhantes. Nós utilizamos cookies, que são arquivos de texto gerados e armazenados no seu navegador ou aparelho por sites, aplicativos e anúncios online. Os cookies poderão ser utilizados para as seguintes finalidades: autenticar usuários, lembrar preferências e configurações do usuário, entender os comportamentos e interesses do usuário.
          <br /><br />
          • Outras informações geradas. Ao utilizar certos recursos, a DUBPAY pode coletar informações geradas por você como: Assim como a maioria dos sites, nosso site pode conter “pixel tags”, cookies ou outras tecnologias de rastreamento similares, que nos permitem olhar as ações dos usuários no nosso site. Pixel tags e cookies são utilizados para coletas informações não-pessoalmente identificáveis, como o nome do seu provedor de internet, o tipo de navegador que você está utilizando, o sistema operacional, o tipo de dispositivo no qual você está acessando nosso site e a data e a hora do acesso. Podemos agregar as suas informações com outras similares para nos ajudar a fazer melhorias nos nossos produtos, serviços, aplicativos, conteúdo e funcionalidades oferecidas no nosso site. Não utilizamos informações não-pessoalmente identificáveis para criar ou manter um perfil de você ou coletar novas informações..
          <br /><br />
          1.2. Informações de outras fontes.
          <br /><br />
          Isso pode incluir:
          <br /><br />
          • Informações provenientes de outros usuários. Outros usuários da DUBPAY podem produzir informações sobre você, como referências, avaliações ou comentários.
          <br /><br />
          • Dados coletados de outras plataformas. A DUBPAY poderá interagir com outras plataformas e outros serviços, como Redes sociais e Meios de Pagamentos. Alguns desses serviços podem nos fornecer informações sobre você, aos quais coletaremos para lhe proporcionar uma melhor experiência e melhorar cada vez mais os nossos serviços e lhe oferecer novas funcionalidades, bem como Nós utilizamos essas informações apenas para motivos financeiros e para completar os pedidos. Caso ocorra algum problema no processamento do seu pedido, utilizamos essas informações para entrar em contato com você..
        </Text>
        <Heading as="h3" size="md" mb="4">
          2. COMO USAMOS SUAS INFORMAÇÕES
        </Heading>
        <Text mb="4">
          Não custa lembrar, prezamos muito pela sua privacidade. Por isso, todos os dados e informações sobre você são tratadas como confidenciais, e somente as usaremos para os fins aqui descritos e autorizados por você, principalmente para que você possa utilizar a DUBPAY de forma plena, visando sempre melhorar a sua experiência como usuário.
          <br /><br />
          2.1. Usos autorizados
          <br /><br />
          Desta forma, poderemos utilizar seus dados para:
          <br /><br />
          • Permitir que você acesse e utilize todas as funcionalidades da DUBPAY;
          <br /><br />
          • Enviar a você mensagens a respeito de suporte ou serviço, como alertas, notificações e atualizações;
          <br /><br />
          • Nos comunicar com você sobre produtos, serviços, promoções, notícias, atualizações, eventos e outros assuntos que você possa ter interesse;
          <br /><br />
          • Analisar o tráfego dos usuários em nossas aplicações;
          <br /><br />
          • Realizar publicidade direcionada conforme seus gostos, interesses e outras informações coletadas;
          <br /><br />
          • Personalizar o serviço para este adequar cada vez mais aos seus gostos e interesses;
          <br /><br />
          • Criarmos novos serviços, produtos e funcionalidades;
          <br /><br />
          • Detecção e prevenção de fraudes, spam e incidentes de segurança;
          <br /><br />
          • Verificar ou autenticar as informações fornecidas por você, inclusive comparando a dados coletados de outras fontes;
          <br /><br />
          • Entender melhor o comportamento do usuário e construir perfis comportamentais;
          <br /><br />
          • Alimentar a Inteligência Artificial, afim de oferecer uma melhor experiência de uso da plataforma aos nossos clientes e parceiros;
          <br /><br />
          • Para qualquer fim que você autorizar no momento da coleta de dados;
          <br /><br />
          • Cumprir obrigações legais.
          <br /><br />
          Eventualmente, poderemos utilizar dados para finalidades não previstas nesta política de privacidade, mas estas estarão dentro das suas legítimas expectativas. O eventual uso dos seus dados para finalidades que não cumpram com essa prerrogativa será feito mediante sua autorização prévia.
          <br /><br />
          2.2. Exclusão dos dados
          <br /><br />
          Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar, por procedimento gratuito e facilitado, ou quando estes não forem mais necessários ou relevantes para lhe oferecermos os nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos da DUBPAY.
          <br /><br />
          Nos casos em que você solicite a exclusão dos seus dados, manteremos os seus dados por 24 (vinte e quatro) meses contados a partir da sua solicitação de exclusão
          <br /><br />
          2.3. Monitoramento
          <br /><br />
          A DUBPAY se reserva no direito de monitorar toda a plataforma, principalmente para assegurar que as regras descritas em nosso Termos de Uso estão sendo observadas, ou ainda se não há violação ou abuso das leis aplicáveis.
          <br /><br />
          2.4. Exclusão de usuário
          <br /><br />
          A DUBPAY se reserva no direito de excluir determinado usuário, independente do tipo que for, caso a presente Política ou os Termos de Uso não sejam respeitados. Como prezamos pelo bom relacionamento com os usuários, reconhecemos que têm o direito de buscar entender os motivos e até contestá-los, o que pode ser feito pelo seguinte e-mail: contato@dubpay.com.br.
        </Text>
        <Heading as="h3" size="md" mb="4">
          3. COMPARTILHAMENTO DAS INFORMAÇÕES
        </Heading>
        <Text mb="4">
          A DUBPAY pode compartilhar as informações que coleta com Parceiros Comerciais. O compartilhamento das informações ocorrerá de forma anônima, sempre que for possível, visando preservar a sua privacidade. Por meio deste documento, você autoriza expressamente tais compartilhamentos. A DUBPAY permite que outras empresas e redes de anúncio anunciem em nossa plataforma por meio de diferentes tecnologias. Portanto, você poderá eventualmente receber diretamente em seu aplicativo anúncios, conteúdo e links exibidos de forma personalizada, de acordo com seus interesses e comportamentos em nossa plataforma ou em outros serviços com os quais você interaja. Para tanto, dados podem ser compartilhados entre a DUBPAY e essas outras empresas, principalmente identificadores únicos, endereços IP, cookies e scripts java, que podem ser utilizados para medir a eficiência da publicidade online. Por meio deste documento, você autoriza expressamente tais compartilhamentos. Todavia, a DUBPAY não se responsabiliza pelos atos, anúncios e conteúdo gerados por nossos parceiros comerciais e redes de anúncio, e esta Política não se aplica a eles, uma vez que a DUBPAY não as controla. Sugerimos ter acesso e ler as políticas de privacidade das empresas com as quais você eventualmente interaja.
          <br /><br />
          Todos os dados, informações e conteúdos sobre você podem ser considerados ativos no caso de negociações em que a DUBPAY fizer parte. Portanto, nos reservamos no direito de, por exemplo, incluir seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com outra. Por meio desta Política você concorda e está ciente desta possibilidade.
          <br /><br />
          A DUBPAY se reserva no direito de fornecer seus dados e informações sobre você, incluindo interações suas, caso seja requisitado judicialmente para tanto, ato necessário para que a empresa esteja em conformidade com as leis nacionais, ou caso você autorize expressamente.
        </Text>
        <Heading as="h3" size="md" mb="4">
          4. DIREITOS DOS TITULARES
        </Heading>
        <Text mb="4">
          Você sempre poderá optar em não divulgar seus dados para nós, mas tenha em mente que alguns desses dados podem ser necessários para utilizar as funcionalidades de nossas aplicações. Independente disso, você sempre possuirá direitos relativos à privacidade e à proteção dos seus dados pessoais. Dessa forma, abaixo resumimos todos os direitos que você tem sob as leis setoriais brasileiras relativas a proteção de dados e a Lei Geral de Proteção de Dados (“LGPD”), quais sejam:
          <br /><br />
          4.1. Direito de acesso
          <br /><br />
          Este direito permite que você possa requisitar e receber uma cópia dos dados pessoais que possuímos sobre Você.
          <br /><br />
          4.2. Direito de retificação.
          <br /><br />
          Este direito permite que você, a qualquer momento, possa solicitar a correção e/ou retificação dos seus dados pessoais, caso identifique que alguns deles estão incorretos. Contudo, para ser efetivada essa correção, teremos que checar a validade dos dados que você nos fornece. Você pode retificar diretamente alguns dos seus dados pessoais através do e-mail contato@dubpay.com.br.
          <br /><br />
          4.3. Direito de exclusão
          <br /><br />
          Este direito permite que você possa nos solicitar a exclusão dos dados pessoais que possuímos sobre você. Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar ou quando estes não forem mais necessários ou relevantes para lhe oferecermos nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos da DUBPAY. Para alterar suas informações pessoais ou excluí-las do nosso banco de dados, basta enviar um e-mail para contato@dubpay.com.br.
          <br /><br />
          4.4. Direito de oposição ao processamento
          <br /><br />
          Você também tem o direito de contestar onde e em que contexto estamos tratando seus dados pessoais para diferentes finalidades. Em determinadas situações, podemos demonstrar que temos motivos legítimos para tratar seus dados, os quais se sobrepõem aos seus direitos, caso, por exemplo, sejam essenciais para o fornecimento de nossas aplicações.
          <br /><br />
          4.5. Direito de solicitar anonimização, bloqueio ou eliminação
          <br /><br />
          Este direito permite que você nos peça para suspender o processamento de seus dados pessoais nos seguintes cenários: (a) se você quiser que nós estabeleçamos a precisão dos dados; (b) quando você precisar que sejam mantidos os dados mesmo se não precisarmos mais deles, conforme necessário, para estabelecer, exercer ou defender reivindicações legais; ou (c) se você se opôs ao uso de seus dados, mas nesta hipótese precisamos verificar se temos motivos legítimos para usá-los.
          <br /><br />
          4.6. Direito à portabilidade
          <br /><br />
          Forneceremos a você, ou a terceiros que você escolheu, seus dados pessoais em formato estruturado e interoperável.
          <br /><br />
          4.7. Direito de retirar o seu consentimento
          <br /><br />
          Você tem o direito de retirar o seu consentimento em relação aos termos desta Política de Privacidade. No entanto, isso não afetará a legalidade de qualquer processamento realizado anteriormente. Se você retirar o seu consentimento, talvez não possamos fornecer determinados serviços.
          <br /><br />
          4.8. Direito a revisão de decisões automatizadas
          <br /><br />
          Você também tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de seus dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definição de perfis pessoais, profissionais, de consumo e de crédito e/ou os aspectos de sua personalidade. Talvez seja necessário solicitar informações específicas suas para nos ajudar a confirmar sua identidade e garantir seu direito de acessar seus dados pessoais (ou de exercer seus outros direitos). Esta é uma medida de segurança para garantir que os dados pessoais não sejam divulgados a qualquer pessoa que não tenha direito de recebê-los. Podemos também contatá-lo para obter mais informações em relação à sua solicitação, a fim de acelerar nossa resposta. Tentamos responder a todas as solicitações legítimas dentro de 5 dias úteis. Ocasionalmente, pode levar mais de 5 dias se sua solicitação for particularmente complexa ou se você tiver feito várias solicitações. Neste caso, iremos comunicá-lo e mantê-lo atualizado sobre o andamento da sua solicitação. Caso Você tenha alguma dúvida sobre essas questões e sobre como você pode exercer esses direitos, fique à vontade para entrar em contato conosco no e-mail contato@dubpay.com.br.
        </Text>
        <Heading as="h3" size="md" mb="4">
          5. SEGURANÇA DAS INFORMAÇÕES
        </Heading>
        <Text mb="4">
          Todos os seus dados são confidenciais e somente as pessoas com as devidas autorizações terão acesso a eles. Qualquer uso destes estará de acordo com a presente Política. A DUBPAY empreenderá todos os esforços razoáveis de mercado para garantir a segurança dos nossos sistemas e dos seus dados. Nossos servidores estão localizados em diferentes locais para garantir estabilidade e segurança, e somente podem ser acessados por meio de canais de comunicação previamente autorizados.
          <br /><br />
          Todas as suas informações serão, sempre que possível, criptografadas, caso não inviabilizem o seu uso pela plataforma. A qualquer momento você poderá requisitar cópia dos seus dados armazenados em nossos sistemas. Manteremos os dados e informações somente até quando estas forem necessárias ou relevantes para as finalidades descritas nesta Política, ou em caso de períodos pré-determinados por lei, ou até quando estas forem necessárias para a manutenção de interesses legítimos da DUBPAY.
          <br /><br />
          A DUBPAY considera a sua privacidade algo extremamente importante e fará tudo que estiver ao alcance para protegê-la. Todavia, não temos como garantir que todos os dados e informações sobre você em nossa plataforma estarão livres de acessos não autorizados, principalmente caso haja compartilhamento indevido das credenciais necessárias para acessar o nosso aplicativo. Portanto, você é o único responsável por manter sua senha de acesso em local seguro e é vedado o compartilhamento desta com terceiros. Você se compromete a notificar a DUBPAY imediatamente, através de meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado por terceiros a esta.
        </Text>
        <Heading as="h3" size="md" mb="4">
          6. ATUALIZAÇÕES DA POLÍTICA DE PRIVACIDADE
        </Heading>
        <Text mb="4">
          A DUBPAY se reserva no direito de alterar essa Política quantas vezes forem necessárias, visando fornecer a você mais segurança, conveniência, e melhorar cada vez mais a sua experiência. É por isso que é muito importante acessar nossa Política periodicamente. Para facilitar, indicamos no início do documento a data da última atualização. Caso sejam feitas alterações relevantes que ensejem novas autorizações suas, publicaremos uma nova política de privacidade, sujeita novamente ao seu consentimento.
        </Text>
        <Heading as="h3" size="md" mb="4">
          7. LEI APLICÁVEL
        </Heading>
        <Text mb="4">
          Este documento é regido e deve ser interpretado de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Comarca de Brasília, Distrito Federal, como o competente para dirimir quaisquer questões porventura oriundas do presente documento, com expressa renúncia a qualquer outro, por mais privilegiado que seja.
        </Text>
      </Container>
    </Box>
  );
}

export default PrivacyPolicy;
