import React, { FormEvent, useState } from "react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Text, useColorModeValue,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton, } from '@chakra-ui/react';
import { forgotPassword } from 'controllers/Auth';

function ForgotPassword() {
  const [modal, setModal] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = React.useState(false);

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const handleRecover = async (event: FormEvent) => {
    event.preventDefault();

    try {
      setLoading(true);
      await forgotPassword(email);
      setModal({
        "title": "Verifique seu email!",
        "body": "Enviamos um link de recuperação para o seu email"
      });
    } catch (error: any) {
      setModal({
        "title": "Ops. Tivemos um erro!",
        "body": "Tente novamente mais tarde!"
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px" className="cadastro">
            Recuperar Senha
          </Heading>
          <Text
            className="cadastro"
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Digite seu email abaixo
          </Text>
          <form onSubmit={(e) => handleRecover(e)}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email
              </FormLabel>
              <Input
                id="email"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@simmmple.com"
                mb="36px"
                fontWeight="500"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                fontSize="sm"
                // variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                bg={'#00CF79'}
              >
                {loading ? "Carregando..." : "Recuperar"}
              </Button>
            </FormControl>
          </form>
        </Box>


        <Modal isOpen={modal != null} onClose={() => setModal(null)}>
          <ModalContent>
            <ModalHeader>{modal?.title}</ModalHeader>
            <ModalCloseButton />
          <ModalBody>{modal?.body}</ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={() => setModal(null)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </Flex>
    </DefaultAuth>
  );
}

export default ForgotPassword;
