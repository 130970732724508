import React from 'react';
import { Box, Button, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, Switch } from '@chakra-ui/react';
import Card from 'components/card/Card';
import LoadingPage from 'components/Loading/LoadingPage';
import { useUserStore } from 'store/reducers/user';
import { useForm } from 'react-hook-form';
import { CreatePixelRequest, Pixel, PixelResponse, PixelType } from 'models/Pixel';
import { createPixel, updatePixel } from 'controllers/Pixels';
import useStores from 'hooks/stores';
import useStoreProducts from 'hooks/products';

type PixelFormProps = {
  switchShow: Function
  show: boolean
  pixelData: PixelResponse
  setPixelData: Function
  selectedPixel?: Pixel
}

export default function PixelForm({ switchShow, show, pixelData, setPixelData, selectedPixel = null }: PixelFormProps) {
  const token = useUserStore((state) => state.auth.token);
  const [loading, setLoading] = React.useState(false);
  const [useApiToken, setUseApiToken] = React.useState(false);

  const defaultValues = selectedPixel ? {
    pixel_name: selectedPixel.pixel_name,
    bill_check: selectedPixel.bill_check,
    pix_check: selectedPixel.pix_check,
    product_id: selectedPixel.product?.id,
    store_id: selectedPixel.store.id,
    code: selectedPixel.code,
    convert_label: selectedPixel.convert_label,
    type: selectedPixel.type,
    api_token: selectedPixel.api_token,
    paid_pix_check: selectedPixel.paid_pix_check,
    paid_bill_check: selectedPixel.paid_bill_check
  } : {
    type: PixelType.META,
    bill_check: false,
    pix_check: false
  }

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue
  } = useForm<CreatePixelRequest>({ defaultValues });
  const typeValue = watch('type');
  const billCheckValue = watch('bill_check');
  const pixCheckValue = watch('pix_check');
  const paidPixCheckValue = watch('paid_pix_check');
  const paidBillCheckValue = watch('paid_bill_check');
  const storeIdValue = watch('store_id');

  const { stores: storeData } = useStores();
  const { products: productData, isLoadingStoreProducts } = useStoreProducts(storeIdValue);

  const handleSavePixel = async (data: CreatePixelRequest) => {
    if (selectedPixel) {
      await handleUpdatePixel(data);
    } else {
      await handleCreatePixel(data);
    }
  }

  const handleCreatePixel = async (data: CreatePixelRequest) => {
    setLoading(true);
    try {
      const response: Pixel = await createPixel(data, token);
      if (response) {
        setPixelData({
          ...pixelData,
          data: [
            response,
            ...pixelData.data
          ]
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      switchShow();
    }
  }

  const handleUpdatePixel = async (data: CreatePixelRequest) => {
    setLoading(true);
    try {
      const response: Pixel = await updatePixel(selectedPixel.id, data, token);
      const updatedPixelData = pixelData.data.map(pixel => {
        if (pixel.id == selectedPixel.id) {
          return response;
        }
        return pixel;
      })

      setPixelData({
        ...pixelData,
        data: [
          ...updatedPixelData
        ]
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      switchShow();
    }
  }

  React.useEffect(() => {
    if (selectedPixel && storeData) {
      setValue('store_id', selectedPixel.store.id);
    }
    if (selectedPixel && productData) {
      setValue('product_id', selectedPixel.product?.id);
    }
    if (selectedPixel && selectedPixel.api_token) {
      setUseApiToken(true);
    }
  }, [storeData, productData])

  React.useEffect(() => {
    reset();
  }, [show]);

  return (
    <>
      <LoadingPage isOpen={loading} />

      <Card flexDirection='column' marginTop={'10px'} w='100%' px='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Box pt={{ base: '130px', md: '60px', xl: '40px' }}>
          <form onSubmit={handleSubmit(handleSavePixel)}>
            <FormControl rowGap='20px'>
              <RadioGroup id="type" value={typeValue} onChange={(nextValue: PixelType) => {setValue("type", nextValue)}}>
                <Stack direction='row'>
                  <Box>
                    <Radio value={PixelType.META}>Facebook</Radio>
                  </Box>
                  <Box>
                    <Radio value={PixelType.GOOGLE}>Google Ads</Radio>
                  </Box>
                  <Box>
                    <Radio value={PixelType.TIKTOK}>TikTok</Radio>
                  </Box>
                </Stack>
              </RadioGroup>

              <br/>
              <FormLabel htmlFor="pixel_name">Nome do pixel *</FormLabel>
              <Input id="pixel_name" type="text" {...register("pixel_name", { required: true })}/>

              <br/>
              <br/>
              <FormLabel htmlFor="store_id">Loja *</FormLabel>
              <Select id="store_id" {...register("store_id", { required: true })}>
                <option value="">Selecione uma loja</option>
                {
                  storeData?.data.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </Select>

              {
                storeIdValue && (
                  <>
                    <br/>
                    <FormLabel htmlFor="product_id">Produto</FormLabel>
                    <Select disabled={isLoadingStoreProducts} id="product_id" {...register("product_id")}>
                      <option value="">{isLoadingStoreProducts ? "Buscando Produtos..." : "Todos"}</option>
                      {
                        productData?.data.map(item => {
                          return (
                            <option key={item.id} value={item.id}>{item.name}</option>
                          );
                        })
                      }
                    </Select>
                  </>
                )
              }

              <br/>
              <FormLabel htmlFor="code">Código *</FormLabel>
              <Input id="code" type="text" {...register("code", { required: true })}/>

              {
                typeValue === PixelType.META && (
                  <>
                    <br/>
                    <br/>
                    <FormLabel>Api de Conversão</FormLabel>
                    <Switch
                      isChecked={useApiToken}
                      onChange={() => setUseApiToken(!useApiToken)}
                    />
                    {useApiToken && (
                    <>
                      <br/>
                      <br/>
                      <FormLabel htmlFor="api_token">Api Token *</FormLabel>
                      <Input id="api_token" type="text" {...register("api_token", { required: true })}/>
                    </>
                    )}
                  </>
                )
              }

              <br/>
              <br/>
              <FormLabel htmlFor="convert_label">Conversão Personalizada</FormLabel>
              <Input id="convert_label" type="text" {...register("convert_label")}/>

              <br/>
              <br/>
              <Stack direction={{base: 'column', sm:'row'}} spacing={6}>
                <Box>
                  <FormLabel htmlFor='bill_check'>Marcar Boleto Pendente</FormLabel>
                  <Switch
                    id='bill_check'
                    isChecked={billCheckValue}
                    onChange={() => {
                      setValue("bill_check", !billCheckValue)
                    }}
                  />
                </Box>
                <Box>
                  <FormLabel htmlFor='pix_check'>Marcar Pix Pendente</FormLabel>
                  <Switch
                    id='pix_check'
                    isChecked={pixCheckValue}
                    onChange={() => {
                      setValue("pix_check", !pixCheckValue)
                    }}
                  />
                </Box>
                {typeValue === PixelType.META && useApiToken && (
                  <>
                    <Box>
                      <FormLabel htmlFor='paid_pix_check'>Marcar Pix Pago</FormLabel>
                      <Switch
                        id='paid_pix_check'
                        isChecked={paidPixCheckValue}
                        onChange={() => {
                          setValue("paid_pix_check", !paidPixCheckValue)
                        }}
                      />
                    </Box>
                    <Box>
                      <FormLabel htmlFor='paid_bill_check'>Marcar Boleto Pago</FormLabel>
                      <Switch
                        id='paid_bill_check'
                        isChecked={paidBillCheckValue}
                        onChange={() => {
                          setValue("paid_bill_check", !paidBillCheckValue)
                        }}
                      />
                    </Box>
                  </>
                )}
              </Stack>

              <br/>
              <br/>
              <Button type='submit'>Salvar</Button>
              <Button onClick={() => switchShow()}>Voltar</Button>
            </FormControl>
          </form>
        </Box>
      </Card>
    </>
  )
}
