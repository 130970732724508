import React from "react";
import { Box, Button, SimpleGrid, } from "@chakra-ui/react";
import CheckTable from "views/admin/cadastros/components/products/CheckTableProduct";
import { useUserStore } from "store/reducers/user";
import { Product } from "models/Product";
import { deleteProduct, getProducts } from "controllers/Products";
import ProductDetails from 'views/admin/cadastros/components/products/ProductDetails';
import { useQuery } from '@tanstack/react-query';
import LoadingPage from 'components/Loading/LoadingPage';
import ProductForm from 'views/admin/cadastros/components/products/ProductForm';
import { DeleteModal } from 'components/modal/DeleteModal';
import { notifyError } from 'utils/notification';

export default function Products() {
  const token = useUserStore((state) => state.auth.token);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [selected, setSelected] = React.useState<Product>(null);
  const [details, setDetails] = React.useState<Product>(null);
  const [show, setShow] = React.useState(false);
  const [confirmDeletion, setConfirmDeletion] = React.useState<number>(null);
  const [filters, setFilters] = React.useState({})

  const { data: productsData, isLoading, refetch } = useQuery(
    ["products", page, token, filters],
    () => getProducts(page, filters),
    {
      onError: (error) => {
        notifyError('Erro ao listar os produtos. Recarregue a página!');
      }
    }
  );

  const execRefetch = async () => {
    await refetch()
  }
  const switchShow = () => {
    setDetails(null);
    setSelected(null);
    setShow(!show);
  }

  const select = (product: Product) => {
    setSelected(product);
    setShow(true);
  }

  const showDetails = (product: Product) => {
    setDetails(product);
    setShow(true);
  }

  const requestDelete = (product: Product) => {
    setConfirmDeletion(product.id);
  }

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteProduct(id);
      await refetch();
    } catch (error) {
      notifyError('Erro ao deletar produto.')
    } finally {
      setConfirmDeletion(null);
      setLoading(false);
    }
  }

  const render = () => {
    if (!show) {
      return (
        <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <CheckTable
              tableData={productsData}
              tableName="Produtos"
              select={select}
              page={page}
              setPage={setPage}
              showDetails={showDetails}
              requestDelete={requestDelete}
              refetch={execRefetch}
              setFilters={setFilters}
            />
          </SimpleGrid>
        </Box>
      );
    }

    if (details) {
      return (
        <ProductDetails product={details} />
      )
    } else {
      return (
        <ProductForm
          switchShow={switchShow}
          show={show}
          refetch={refetch}
          selected={selected}
        />
      )
    }
  }

  return (
    <>
      <LoadingPage isOpen={isLoading || loading} />
      <Button onClick={switchShow}>{show ? "Voltar" : "Novo Produto"}</Button>

      {render()}

      <DeleteModal
        title="Remover Produto?"
        isOpen={confirmDeletion != null}
        onClose={() => setConfirmDeletion(null)}
        handleDelete={() => handleDelete(confirmDeletion)}
      />
    </>
  );
}
