export function addTrailingSlash(url: string) {
  if (!url) return "";

  if (url.slice(-1) !== '/') {
    url += '/';
  }

  return url;
}

export function filterImages(images: any[]) {
  if (!images) return images;

  return images[0].file;
}
